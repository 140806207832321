// import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import classNames from "classnames";
import ArrowDown from "../../assets/icons/ArrowDown";
import ArrowUp from "../../assets/icons/ArrowUp";
import { ProfileIcon, SquareIcon } from "../../assets/icons/ManageUsers";
import { addThousandSeparator } from "../../utils";
import Dropdown from "./dropdown";

export function Products({ data, dropdown }) {
  const [products, setProducts] = useState(data);
  const pathname = window.location.pathname.replace("/", "");

  const productsData = useMemo(
    () =>
      products
        ? products?.map((product) => {
            if (product.Last_seen) {
              let newDate = new Date(product.Last_seen).toDateString();
              product.Last_seen = newDate;
            }
            if (product.dob) {
              let newDate = new Date(product.dob).toDateString();
              product.dob = newDate;
            }
            if (product.requestdate) {
              let newDate = new Date(product.requestdate).toDateString();
              product.requestdate = newDate;
            }
            if (product.created_date) {
              let newDate = new Date(product.created_date).toDateString();
              product.created_date = newDate;
            }
            if (product.endDate) {
              let newDate = new Date(product.endDate).toDateString();
              product.endDate = newDate;
            }
            if (product.startDate) {
              let newDate = new Date(product.startDate).toDateString();
              product.startDate = newDate;
            }
            if (product.createdDate) {
              let newDate = new Date(product.createdDate).toDateString();
              product.createdDate = newDate;
            }
            if (product.hire_date) {
              let newDate = new Date(product.hire_date).toDateString();
              product.hire_date = newDate;
            }
            if (product.dateCreated) {
              let newDate = new Date(product.dateCreated).toDateString();
              product.dateCreated = newDate;
            }
            if (product.date_created) {
              let newDate = new Date(product.date_created).toDateString();
              product.date_created = newDate;
            }
            if (product.dateClosed) {
              let newDate = new Date(product.dateClosed).toDateString();
              product.dateClosed = newDate;
            }
            if (product.phone) {
              product.phone = product.phone.toString().replace("234", "0");
            }
            if (product.balance) {
              product.balance = addThousandSeparator(
                parseFloat(
                  product.balance.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.amount) {
              product.amount = addThousandSeparator(
                parseFloat(product.amount.toString().replace(/,/g, "")).toFixed(
                  2
                ) ?? 0
              );
            }
            if (product.contribution) {
              product.contribution = addThousandSeparator(
                parseFloat(
                  product.contribution.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.currentBalance) {
              product.currentBalance = addThousandSeparator(
                parseFloat(
                  product.currentBalance.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.withholdingTax) {
              product.withholdingTax = addThousandSeparator(
                parseFloat(
                  product.withholdingTax.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.principalAmount) {
              product.principalAmount = addThousandSeparator(
                parseFloat(
                  product.principalAmount.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.price) {
              product.price = addThousandSeparator(
                parseFloat(product.price.toString().replace(/,/g, "")).toFixed(
                  2
                ) ?? 0
              );
            }
            if (product.monthlyIncome) {
              product.monthlyIncome = addThousandSeparator(
                parseFloat(
                  product.monthlyIncome.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.maturityAmount) {
              product.maturityAmount = addThousandSeparator(
                parseFloat(
                  product.maturityAmount.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.targetAmount) {
              product.targetAmount = addThousandSeparator(
                parseFloat(
                  product.targetAmount.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.loan_amt) {
              product.loan_amt = addThousandSeparator(
                parseFloat(
                  product.loan_amt.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.net_pay) {
              product.net_pay = addThousandSeparator(
                parseFloat(
                  product.net_pay.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.monthly_install) {
              product.monthly_install = addThousandSeparator(
                parseFloat(
                  product.monthly_install.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            if (product.accruedInterest) {
              product.accruedInterest = addThousandSeparator(
                parseFloat(
                  product.accruedInterest.toString().replace(/,/g, "")
                ).toFixed(2) ?? 0
              );
            }
            // if (product.targetAmount) {
            //   product.targetAmount = addThousandSeparator(
            //     parseFloat(
            //       product.targetAmount.toString().replace(/,/g, "")
            //     ).toFixed(2) ?? 0
            //   );
            // }
            if (product?.loan_status?.toLowerCase() === "pen") {
              product.loan_status =
                product?.loan_status?.toLowerCase() === "pen" && "pending";
            }
            if (product?.loan_status?.toLowerCase() === "apr") {
              product.loan_status =
                product?.loan_status?.toLowerCase() === "apr" && "approved";
            }
            if (product?.loan_status?.toLowerCase() === "rej") {
              product.loan_status =
                product?.loan_status?.toLowerCase() === "rej" && "rejected";
            }

            if (product.investmentType === 1) {
              product.investmentType =
                product.investmentType === 1 && "Target Savings ";
            }
            if (product.investmentType === 2) {
              product.investmentType =
                product.investmentType === 2 && "Kiddies Swag";
            }
            if (product.investmentType === 3) {
              product.investmentType =
                product.investmentType === 3 && "Fast Credit Investment Note";
            }
            return product;
          })
        : [],
    [products]
  );

  const productsColumns = useMemo(
    () =>
      products[0]
        ? Object.keys(products[0])
            .filter((key) => {
              return (
                key !== "bvn" &&
                key !== "channel" &&
                key !== "investment_code" &&
                key !== "payment_mode" &&
                key !== "reference_id" &&
                key !== "item_id" &&
                key !== "val_lgaofresidence" &&
                key !== "tranType1" &&
                key !== "tran_Status" &&
                key !== "val_stateofresidence" &&
                key !== "id" &&
                key !== "periodic_savings" &&
                key !== "isChecked" &&
                key !== "Modified_Date" &&
                key !== "Disabled_date" &&
                key !== "Created_Date" &&
                key !== "userid" &&
                key !== "modified_date" &&
                key !== "password_modified_date" &&
                key !== "password" &&
                key !== "nok_relationship" &&
                key !== "nok_employer" &&
                key !== "work_period" &&
                key !== "legacy_id" &&
                // key !== "sendresponse" &&
                key !== "nibssresponse" &&
                key !== "tran_status" &&
                key !== "paymentreference" &&
                key !== "sessionid" &&
                key !== "bankcode" &&
                key !== "tran_type_1" &&
                key !== "isDeleted" &&
                key !== "dateModified" &&
                key !== "userId" &&
                key !== "billId" &&
                key !== "paymentRef" &&
                key !== "user_t24_current_account" &&
                key !== "token" &&
                key !== "user_t24_savings_account" &&
                key !== "isLoggedIn" &&
                key !== "expiryyear" &&
                key !== "cardnumber" &&
                key !== "deviceName" &&
                key !== "csvnumber" &&
                key !== "cardpin" &&
                key !== "bank_sortcode" &&
                key !== "activationCode" &&
                key !== "token" &&
                key !== "tin_number" &&
                key !== "token" &&
                key !== "value" &&
                key !== "verified" &&
                key !== "work_period" &&
                key !== "expirymonth" &&
                key !== "expiryyear" &&
                key !== "user_code" &&
                key !== "paymentReference" &&
                key !== "stages_Completed" &&
                key !== "pin" &&
                key !== "nok_employer" &&
                key !== "stage" &&
                key !== "verified" &&
                key !== "nok_title" &&
                key !== "verified" &&
                key !== "imageUrl" &&
                // key !== "title" &&
                key !== "bank_name" &&
                key !== "hire_date" &&
                key !== "salary" &&
                key !== "nok_fullname" &&
                key !== "bank_account" &&
                key !== "nok_email" &&
                key !== "nok_phone" &&
                key !== "imageUrl" &&
                key !== "middlename" &&
                key !== "industry" &&
                key !== "account_opening_date" &&
                key !== "state_of_origin" &&
                key !== "lga_of_origin" &&
                key !== "employee_status" &&
                key !== "employer_code" &&
                key !== "imageUrl" &&
                key !== "nationality" &&
                key !== "employer_name" &&
                key !== "imageUrl" &&
                key !== "state_of_residence" &&
                key !== "staff_id" &&
                key !== "marital_status" &&
                key !== "ipAddress" &&
                key !== "passwordHash" &&
                key !== "passwordModifiedDate" &&
                key !== "phoneNumberConfirmed" &&
                key !== "securityStamp" &&
                key !== "twoFactorEnabled" &&
                key !== "lockoutEnd" &&
                key !== "otpExpires" &&
                key !== "lockoutEnabled" &&
                key !== "accessFailedCount" &&
                key !== "emailConfirmed" &&
                key !== "modiefiedDate" &&
                key !== "isDisabled" &&
                key !== "otp" &&
                key !== "otpVerified" &&
                key !== "userName" &&
                key !== "concurrencyStamp" &&
                key !== "narration_1" &&
                key !== "normalizedUserName" &&
                key !== "normalizedEmail" &&
                key !== "serviceId" &&
                key !== "productId" &&
                key !== "t24LoanId" &&
                key !== "loanId" &&
                key !== "item_Code" &&
                key !== "loanPurpose" &&
                key !== "id_expiry_date" &&
                key !== "id_issue_date" &&
                key !== "relationship_nok" &&
                key !== "percentageCompletion" &&
                key !== "name_nok" &&
                key !== "id_number" &&
                key !== "means_identification" &&
                key !== "phone_nok" &&
                key !== "religion" &&
                key !== "staffId" &&
                key !== "biller_Code" &&
                key !== "deliveryState" &&
                key !== "vendorId" &&
                key !== "validId" &&
                key !== "deliveryMethod" &&
                key !== "workId" &&
                key !== "approvedBy" &&
                key !== "deliveryAddress" &&
                key !== "approvedDate" &&
                key !== "storeLocationId" &&
                key !== "user_id"
              );
            })
            .map((key) => {
              if (key === "investment_name") {
                return {
                  Header: "investment type",
                  accessor: key,
                };
              }
              return { Header: key, accessor: key };
            })
        : [],
    [products]
  );

  const VasproductsColumns = useMemo(
    () =>
      products[0]
        ? Object.keys(products[0])
            .filter((key) => {
              return (
                key !== "bvn" &&
                key !== "channel" &&
                key !== "investment_code" &&
                key !== "payment_mode" &&
                key !== "reference_id" &&
                key !== "item_id" &&
                key !== "val_lgaofresidence" &&
                key !== "val_stateofresidence" &&
                key !== "id" &&
                key !== "periodic_savings" &&
                key !== "isChecked" &&
                key !== "Modified_Date" &&
                key !== "Disabled_date" &&
                key !== "Created_Date" &&
                key !== "userid" &&
                key !== "modified_date" &&
                key !== "password_modified_date" &&
                key !== "password" &&
                key !== "nok_relationship" &&
                key !== "nok_employer" &&
                key !== "work_period" &&
                key !== "legacy_id" &&
                // key !== "sendresponse" &&
                key !== "nibssresponse" &&
                key !== "tran_status" &&
                key !== "paymentreference" &&
                key !== "sessionid" &&
                key !== "bankcode" &&
                key !== "tran_type_1" &&
                key !== "isDeleted" &&
                key !== "dateModified" &&
                key !== "userId" &&
                key !== "billId" &&
                key !== "paymentRef" &&
                key !== "user_t24_current_account" &&
                key !== "token" &&
                key !== "user_t24_savings_account" &&
                key !== "isLoggedIn" &&
                key !== "expiryyear" &&
                key !== "cardnumber" &&
                key !== "deviceName" &&
                key !== "csvnumber" &&
                key !== "cardpin" &&
                key !== "bank_sortcode" &&
                key !== "activationCode" &&
                key !== "token" &&
                key !== "tin_number" &&
                key !== "token" &&
                key !== "value" &&
                key !== "tranType1" &&
                key !== "verified" &&
                key !== "work_period" &&
                key !== "expirymonth" &&
                key !== "expiryyear" &&
                key !== "user_code" &&
                key !== "paymentReference" &&
                key !== "stages_Completed" &&
                key !== "pin" &&
                key !== "nok_employer" &&
                key !== "stage" &&
                key !== "verified" &&
                key !== "nok_title" &&
                key !== "verified" &&
                key !== "imageUrl" &&
                // key !== "title" &&
                key !== "bank_name" &&
                key !== "hire_date" &&
                key !== "salary" &&
                key !== "nok_fullname" &&
                key !== "bank_account" &&
                key !== "nok_email" &&
                key !== "nok_phone" &&
                key !== "imageUrl" &&
                key !== "middlename" &&
                key !== "industry" &&
                key !== "account_opening_date" &&
                key !== "state_of_origin" &&
                key !== "lga_of_origin" &&
                key !== "employee_status" &&
                key !== "employer_code" &&
                key !== "imageUrl" &&
                key !== "nationality" &&
                key !== "employer_name" &&
                key !== "imageUrl" &&
                key !== "state_of_residence" &&
                key !== "staff_id" &&
                key !== "marital_status" &&
                key !== "ipAddress" &&
                key !== "passwordHash" &&
                key !== "passwordModifiedDate" &&
                key !== "phoneNumberConfirmed" &&
                key !== "securityStamp" &&
                key !== "twoFactorEnabled" &&
                key !== "lockoutEnd" &&
                key !== "otpExpires" &&
                key !== "lockoutEnabled" &&
                key !== "accessFailedCount" &&
                key !== "emailConfirmed" &&
                key !== "modiefiedDate" &&
                key !== "isDisabled" &&
                key !== "otp" &&
                key !== "otpVerified" &&
                key !== "userName" &&
                key !== "concurrencyStamp" &&
                key !== "t24LoanId" &&
                key !== "normalizedUserName" &&
                key !== "normalizedEmail" &&
                key !== "targetAmount" &&
                key !== "bankName" &&
                key !== "crAccount" &&
                key !== "crAccountName" &&
                key !== "deliveryAddress" &&
                key !== "deliveryPrice" &&
                key !== "deliveryType" &&
                key !== "description" &&
                key !== "duration" &&
                key !== "frequency" &&
                key !== "installmentAmount" &&
                key !== "interestAmount" &&
                key !== "investmentDuration" &&
                key !== "interestRate" &&
                key !== "investmentId" &&
                key !== "investmentType" &&
                key !== "isDelivered" &&
                key !== "item_Code" &&
                key !== "loanId" &&
                key !== "loanPurpose" &&
                key !== "loanType" &&
                key !== "maturityAmount" &&
                key !== "monthlyIncome" &&
                key !== "narration_1" &&
                key !== "originatorAccountNumber" &&
                key !== "originatorName" &&
                key !== "outstandingAmount" &&
                key !== "paymentMethod" &&
                key !== "paymentSTartDate" &&
                key !== "productId" &&
                key !== "rate" &&
                key !== "repaymentPeriod" &&
                key !== "tenor" &&
                key !== "toBankName" &&
                key !== "billType" &&
                key !== "transactionId" &&
                key !== "tran_Status" &&
                key !== "biller_Code" &&
                key !== "billerLogo" &&
                key !== "sessionId" &&
                key !== "serviceId" &&
                key !== "transactionUrl" &&
                key !== "user_id"
              );
            })
            .map((key) => {
              if (key === "investment_name") {
                return {
                  Header: "investment type",
                  accessor: key,
                };
              }
              return { Header: key, accessor: key };
            })
        : [],
    [products]
  );

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [...columns]);
  };

  const tableInstance = useTable(
    {
      columns: pathname === "Vas" ? VasproductsColumns : productsColumns,
      data: productsData,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    setProducts(data);
  }, [data]);

  const isEven = (idx) => idx % 2 === 0;

  return (
    <>
      <div>
        <table
          className="w-full overflow-scroll scrollbar-hide"
          {...getTableProps()}
        >
          <thead className="text-xs lg:text-base capitalize border-spacing-3">
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                <th></th>

                {headerGroup.headers.map((column, index) => (
                  <>
                    <th
                      key={index}
                      className=" text-xs lg:text-sm py-1 px-3 lg:py-2 lg:px-5 capitalize"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <p className="flex items-center space-x-2">
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDown />
                          ) : (
                            <ArrowUp />
                          )
                        ) : (
                          ""
                        )}
                      </p>
                    </th>
                  </>
                ))}
                <th className=" text-xs lg:text-sm ">action</th>
              </tr>
            ))}
          </thead>
          <tbody className=" text-xs lg:text-base" {...getTableBodyProps()}>
            {rows.map((row, idx) => {
              prepareRow(row);

              return (
                <tr
                  key={idx}
                  {...row.getRowProps()}
                  className={`${
                    isEven(idx)
                      ? "bg-gray-100  text-xs lg:text-sm capitalize"
                      : " text-xs lg:text-sm capitalize"
                  }`}
                >
                  <td className="p-2">
                    {pathname !== "BNPL" ? <ProfileIcon /> : null}
                  </td>
                  {row.cells.map((cell, idx) => (
                    <>
                      <td
                        key={idx}
                        className={classNames(
                          "text-xs lg:text-sm py-1 px-3 lg:py-2 lg:px-5"
                        )}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    </>
                  ))}
                  <td className="cursor-pointer">
                    <Dropdown
                      title={<SquareIcon />}
                      subtitle={dropdown}
                      data={row.original}
                      // user={products}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
