import { memo } from "react";
import { InvestmentPortfolioIcon } from "../assets/icons/Investment";

type Props = {
  title: string;
  data: string;
};
const InvestmentCard = ({ data, title }: Props) => {
  return (
    <div className="bg-white w-[300px] h-[154px] flex flex-col shadow rounded-xl p-6 m-3 cursor-pointer">
      <h3 className=" font-semibold capitalize text-[#1F2024]">{title}</h3>
      <h1 className="text-2xl font-semibold flex items-center">
        <p className="mr-1">&#8358;</p> {data}
      </h1>
      <div className="hidden md:flex -ml-6">
        <InvestmentPortfolioIcon />
      </div>
    </div>
  );
};

export default memo(InvestmentCard);
