import { memo } from "react";
import { CardIcon, CardIcon2 } from "../assets/icons/Card";
import BigCard from "../shared/BigCard";

type Props = {};

const CardTransactions = (props: Props) => {
  return (
    <BigCard>
      <h3 className="text-[#1C1D21] font-bold text-xl ">Fast Credit Cards</h3>
      <div className="flex items-center space-x-2 my-4">
        <CardIcon />
        <CardIcon2 />
      </div>
      <h3 className="text-[#4C4D50]">Transactions Summary</h3>
      <section className="my-3 flex flex-col space-y-4 divide-y-2">
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">ATM</h2>
          <p className="text-[#1C1D21]">222 </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">POS</h2>
          <p className="text-[#1C1D21]">300 </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">WEB</h2>
          <p className="text-[#1C1D21]">300 </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">TOTAL</h2>
          <p className="text-[#1C1D21]">300,000 </p>
        </div>
      </section>
    </BigCard>
  );
};

export default memo(CardTransactions);
