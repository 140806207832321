import { memo } from "react";
import BigCard from "../shared/BigCard";
import { LoanDetails2 } from "../typings";
import LoanDet from "./LoanDet";

type Props = {
  data: LoanDetails2 | null;
};

const LoanDetail = ({ data }: Props) => {
  // const { handleClick } = useStateContext();

  return (
    <BigCard>
      <h4 className="text-xl text-[#1C1D21] font-semibold">
        Loan Application Details
      </h4>
      <LoanDet data={data} />
    </BigCard>
  );
};

export default memo(LoanDetail);
