import { useEffect, useState } from "react";
import axios from "axios";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";

type Props = {};

const Notification = (props: Props) => {
  const { handleClick, newMessage, newTicketId } = useStateContext();
  const [username, setUsername] = useState("");
  useEffect(() => {
    axios
      .get(
        `https://apitest.fastcredit-ng.com/api/Ticket/GetById?id=${newTicketId}`
      )
      .then((res) => {
        // toast.success(`Message Received From ${res.data.responseData.userName}`);
        setUsername(res.data.responseData.userName);
      })
      .catch((err) => console.log("err", err));
  }, [newMessage, newTicketId]);
  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Notification Center
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        {!username ? (
          <p className="text-[#4C4D50] text-center">No new message</p>
        ) : (
          <p className="text-[#4C4D50] text-center">
            A new message from {username}
          </p>
        )}
        {/* <div className="flex items-center justify-center">
          <button
            onClick={handleClick}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
        </div> */}
      </div>
    </ModalCard>
  );
};

export default Notification;
