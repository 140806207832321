import { memo } from "react";
import { BackIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import VerifiedCard from "../shared/VerifiedCard";
import { BNPLDetail as detail } from "../typings";
import BNPLDetails from "./BNPLDetails";

type Props = {
  data: detail;
};

const BNPLDetail = ({ data }: Props) => {
  const { setEdit, setEditData, openMenu } = useStateContext();
  const back = () => {
    setEdit(false);
    setEditData(null);
  };

  return (
    <>
      <div
        className="flex items-center space-x-4 font-semibold cursor-pointer my-10"
        onClick={back}
      >
        <BackIcon />
        <p className="text-[#1C1D21]">Back to BNPL</p>
      </div>
      <div className="flex w-full  flex-col space-y-10">
        {data?.userId && (
          <section className="flex">
            <div className="">
              <VerifiedCard userid={data?.userId} />
            </div>
          </section>
        )}
        <section
          className={`flex ${
            openMenu
              ? " flex-col lg:flex-row space-x-10"
              : "flex-col xl:flex-row"
          }`}
        >
          <div>
            <BNPLDetails data={data} />
          </div>
        </section>
      </div>
    </>
  );
};

export default memo(BNPLDetail);
