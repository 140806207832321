import { useState, memo } from "react";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { selectStatusData } from "../assets/constants";
import DateForm from "../shared/DateForm";
import InputForm from "../shared/InputForm";
import SelectForm from "../shared/SelectForm";

type InputFile = {
  duration?: string;
  module?: string;
  status?: string;
  type?: string;
  endDate?: string;
  startDate?: string;
  tenor?: string;
};

type Props = {
  inputFile: InputFile;
  setInputFile: (e: any) => void;
};

const LoanReport = ({ inputFile, setInputFile }: Props) => {
  const [file, setFile] = useState("");
  const [url, setUrl] = useState("");
  const [generate, setGenerate] = useState(false);

  const handleChange = (e: any) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };
  const handleDownload = (e: any) => {
    setGenerate(true);
    e.preventDefault();
    const payload = {
      startDate: inputFile.startDate,
      endDate: inputFile.endDate,
      status: inputFile.status,
      tenor: inputFile.tenor,
    };

    let data;
    if (typeof window !== "undefined") {
      data = secureLocalStorage.getItem("secure-auth");
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_ARY}/Loan/GenerateReport`,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${data}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: payload,
    })
      .then((res) => {
        setFile(res.data);
        setGenerate(false);
        const blob = new Blob([res.data]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        setUrl(fileDownloadUrl);
      })
      .catch((err) => {
        console.log("error msg: ", err.message);
        setGenerate(false);
      });

    // reportPostApi(
    //     `${process.env.REACT_APP_BASE_URL_ARY}/Loan/GenerateReport`,
    //     payload,
    //   )
    //   .then((res: any) => {
    //     console.log("res", res);
    //     setFile(res.data);
    //     setGenerate(false);
    //     const blob = new Blob([res.data]);
    //     const fileDownloadUrl = URL.createObjectURL(blob);
    //     setUrl(fileDownloadUrl);
    //     console.log("fileDownloadUrl", fileDownloadUrl);
    //   })
    //   .catch((err: any) => {
    //     toast.error("Please fill all fields");
    //     setGenerate(false);
    //     console.log("err", err);
    //   });
  };
  return (
    <div className="my-6 w-full h-[300px] lg:h-full overflow-y-scroll ">
      <SelectForm
        name="status"
        handleChange={handleChange}
        data={selectStatusData}
        label="Choose status"
      />{" "}
      <InputForm
        handleChange={handleChange}
        type="text"
        label="tenor"
        name="tenor"
        value={inputFile.tenor || ""}
        placeholder="tenor"
      />
      {/* <SelectForm
        name="tenor"
        handleChange={handleChange}
        data={selectTenorData}
        label="Choose Tenor"
      />{" "} */}
      <DateForm
        name="startDate"
        label="start date"
        handleChange={handleChange}
      />
      <DateForm name="endDate" label="end date" handleChange={handleChange} />
      <div className="flex  justify-center my-5">
        {file ? (
          <a download="Loan-Report.xlsx" href={url} className="btn">
            download now
          </a>
        ) : (
          <button
            disabled={
              inputFile.startDate === "" &&
              inputFile.endDate === "" &&
              inputFile.tenor === ""
            }
            className="btn"
            onClick={handleDownload}
          >
            {generate ? "generating..." : "Click to Download"}
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(LoanReport);
