import { useState, memo } from "react";
import BigCard from "../shared/BigCard";
import { IssueDetails } from "../typings";
import ChatMessage from "./ChatMessage";

type Props = {
  data: IssueDetails;
  back: () => void;
};

const ChatBox = ({ data, back }: Props) => {
  const [showChat, setShowChat] = useState(false);
  const handleChat = () => setShowChat((prev) => !prev);
  return (
    <>
      {showChat ? (
        <ChatMessage />
      ) : (
        <BigCard>
          <h3 className="text-[#98989A]"> Complaint Title:</h3>
          <span className="flex space-x-3 items-center my-4">
            <h1 className="text-[#1C1D21] font-bold text-xl">{data.title}</h1>

            <small className="text-[#1F2024]">
              {new Date(data.dateCreated).toDateString()}
            </small>
          </span>
          <div className="w-[280px] md:w-[400px] my-4">
            <p className="text-[#696A6C] break-words">{data.description}</p>
          </div>
          <div className="w-full flex items-center md:space-x-10">
            <button onClick={handleChat} type="submit" className="btn">
              Respond
            </button>
            <button
              onClick={back}
              type="submit"
              className="btn text-[#FF3B30] bg-transparent border-[#FF3B30] border-2 font-semibold"
            >
              cancel
            </button>
          </div>
        </BigCard>
      )}
    </>
  );
};

export default memo(ChatBox);
