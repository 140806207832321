import { memo, useState } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { CustomerInvestmentDetail } from "../typings";
import { putApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {
  data: CustomerInvestmentDetail | null;
};

const InvestmentApproval = ({ data }: any) => {
  const { handleClick } = useStateContext();
  const [loading, setLoading] = useState(false);

  const handleApprove = () => {
    setLoading(true);
    const payload = {
      investmentId: data?.id,
      status: 0,
    };
    putApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/Admin/UpdateInvestmentStatus`,
      payload
    )
      .then((res) => {
        Toast({ title: res.responseData, error: false });
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-20 text-xl text-[#4C4D50] font-semibold">
            Approve Investment
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          Are you sure you want to approve the Investment ?
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={handleClick}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button onClick={handleApprove} type="submit" className="btn">
            {loading ? "processing..." : "Approve"}
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default memo(InvestmentApproval);
