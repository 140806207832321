import { lazy } from "react";

const Dashboard = lazy(() => import("./Dashboard"));
const Users = lazy(() => import("./Users"));
const Wallet = lazy(() => import("./Wallet"));
const Loans = lazy(() => import("./Loans"));
const Investment = lazy(() => import("./Investment"));
const Vas = lazy(() => import("./Vas"));
const BNPL = lazy(() => import("./BNPL"));
const Cards = lazy(() => import("./Cards"));
const Support = lazy(() => import("./Support"));
const Report = lazy(() => import("./Report"));
const Login = lazy(() => import("./Login"));
const NotFound = lazy(() => import("./NotFound"));
const Customers = lazy(() => import("./Customers"));
const HealthLoans = lazy(() => import("./HealthLoans"));
const FAQ = lazy(() => import("./FAQ"));
const Notification = lazy(() => import("./Notification"));
const KYC = lazy(() => import("./KYC"));

export {
  Dashboard,
  Users,
  Notification,
  Wallet,
  KYC,
  Loans,
  Investment,
  Vas,
  FAQ,
  BNPL,
  Cards,
  Support,
  Report,
  Login,
  NotFound,
  Customers,
  HealthLoans,
};
