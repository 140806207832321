import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import SkeletonCard from "../components/skeleton/Card";
import { useStateContext } from "../context/context";

type Props = {
  children: any;
};

export default function ModalCard({ children }: Props) {
  const { handleClick, open } = useStateContext();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    children ? setLoading(false) : setLoading(true);
  }, [children]);
  return (
    <>
      {loading ? (
        <SkeletonCard />
      ) : (
        <div>
          {/* <Button onClick={handleOpen}>Open modal</Button> */}
          <Modal
            open={open}
            onClose={handleClick}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <main className="relative flex items-center justify-center w-full h-screen">
              {children}
            </main>
          </Modal>
        </div>
      )}
    </>
  );
}
