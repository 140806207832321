export const InvestmentPortfolioIcon = () => {
  return (
    <svg
      width="428"
      height="77"
      viewBox="0 0 428 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-34.8422 16.9064C-50.6692 26.1578 -57 33.3174 -57 33.3174V93H565V43.4647C549.964 44.8566 504.066 42.2694 466.081 43.4647C428.097 44.66 421.766 31.8264 413.852 24.3664C405.939 16.9064 397.234 -0.39879 377.45 1.09036C357.667 2.57951 326.012 52.4132 299.107 36.8982C272.201 21.3832 261.122 36.8982 247.669 47.6406C234.216 58.3829 210.284 50.8249 207.31 47.6406C187.527 26.4543 180.404 63.7541 158.247 36.8982C148.837 25.494 121.845 4.96955 98.1043 54.8021C94.9389 59.5765 89.3994 68.2301 75.9465 68.2301C52.8749 68.2301 65.659 54.8021 40.3358 54.8021C28.6517 54.8021 27.3392 30.1453 14.2213 18.3975C1.55977 7.05833 -19.0153 7.65513 -34.8422 16.9064Z"
        fill="url(#paint0_linear_546_41015)"
      />
      <path
        d="M-57 33.3175C-57 33.3175 -50.6692 26.1578 -34.8422 16.9065C-19.0153 7.65517 1.55977 7.05838 14.2213 18.3975C27.3392 30.1453 28.6517 54.8022 40.3358 54.8022C65.659 54.8022 52.8749 68.2301 75.9465 68.2301C89.3994 68.2301 94.9389 59.5765 98.1043 54.8022C121.845 4.96959 148.837 25.494 158.247 36.8982C180.404 63.7541 187.527 26.4543 207.31 47.6406C210.284 50.825 234.216 58.3829 247.669 47.6406C261.122 36.8983 272.201 21.3833 299.107 36.8982C326.012 52.4132 357.667 2.57956 377.45 1.09041C397.234 -0.398744 405.939 16.9065 413.852 24.3665C421.766 31.8264 428.097 44.66 466.081 43.4647C504.066 42.2694 549.964 44.8567 565 43.4647"
        stroke="#56B684"
      />
      <defs>
        <linearGradient
          id="paint0_linear_546_41015"
          x1="254"
          y1="1"
          x2="254"
          y2="93"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#029247" stopOpacity="0.44" />
          <stop offset="0.765213" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
