import { useState, useEffect, memo, useLayoutEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { motion } from "framer-motion";
import { data } from "../assets/constants";
import ArrowDown from "../assets/icons/ArrowDown";
import ArrowUp from "../assets/icons/ArrowUp";
import { DeleteUserIcon } from "../assets/icons/ManageUsers";
import { FastCreditLogo } from "../assets/images";
import { useStateContext } from "../context/context";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { logout } from "../redux/userSlice";
import { User } from "../typings";
import { postApi } from "../utils/services";
import { Toast } from "./Toast";

type Category = {
  title: string;
  icon: JSX.Element;
  id: number;
  link: string;
};

// type Props = {
//   id: number;
//   title: string;
//   catgory: Category[];
// };

export const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [subMenu, setSubMenu] = useState("");
  const pathname = window.location.pathname.replace("/", "");
  const userid: User = useAppSelector((state) => state.user.user);
  const { setEdit, openMenu, setOpenMenu, edit } = useStateContext();
  const [ID, setID] = useState<number | null | undefined>(null);
  // const role = 'admin'

  const routing = (category: Category) => {
    setSubMenu(category.title);
    navigate(category.link);
    handleArrow(category.id);
  };

  const handleArrow = (id: number) => {
    const newid = data.map((item) =>
      item.category.find((item) => item.id === id)
    );
    const myId = newid.find((item) => item?.id === id);
    setID(myId?.id);
  };

  const logoutUser = () => {
    dispatch(logout());
    postApi(`${process.env.REACT_APP_BASE_URL}/coreapi/logout/${userid}`)
      .then((res: any) => {
        navigate("/");
        // localStorage.removeItem("token");
        localStorage.removeItem("@secure.s.secure-auth");
        localStorage.removeItem("@secure.s.secure-auth22");
        Toast({
          title: `Logged out successfully 😢`,
          error: true,
        });
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => setEdit(false), [setEdit]);

  useEffect(() => {
    if (window.screen.width <= 768) {
      setOpenMenu(true);
    }
  }, [setOpenMenu]);

  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, edit]);

  return (
    <motion.div
      initial={{
        x: -200,
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
      }}
      transition={{
        duration: 1.2,
      }}
      exit={{ opacity: 0, x: -200 }}
      className={classNames("", {
        "w-0 hidden": openMenu,
        "w-[250px] bg-white h-screen p-5 overflow-auto shadow": !openMenu,
      })}
    >
      <Link to="/dashboard">
        <FastCreditLogo />
      </Link>
      <section>
        <SidebarMenu
        
          ID={ID}
          navigate={navigate}
          data={data}
          pathname={pathname}
          routing={routing}
          subMenu={subMenu}
          openMenu={openMenu}
        />
        <span
          onClick={logoutUser}
          className="flex space-x-4 items-center ml-2 my-4 hover:bg-[#BD0A00]/10 p-3 rounded"
        >
          <DeleteUserIcon />
          <h2 className="cursor-pointer font-bold text-red-700 uppercase ">
            logout
          </h2>
        </span>
      </section>
    </motion.div>
  );
};

export default memo(Sidebar);

export const SidebarMenu = ({
  data,
  pathname,
  routing,
  subMenu,
  ID,
  navigate,
}: any) => (
  <>
    {data.map((item: any) => (
      <div key={item.id} className="flex flex-col space-y-6 mt-10">
        <h2 className="text-[#98989A] font-semibold">{item.title}</h2>

        {item.category.map((category: any) => (
          <>
            {/* {role === category?.role?.toLowerCase() && */}
            <>
              <div
                key={category?.title}
                onClick={() => routing(category)}
                className={`flex items-center space-x-5 ml-2 hover:bg-[#D0F6DF] p-3 rounded cursor-pointer hover:text-[#029247] ${
                  pathname.toLowerCase() === category.title.toLowerCase()
                    ? "font-bold text-[#029247] bg-[#D0F6DF] p-3 rounded"
                    : ""
                } 
                  
                    ${
                      pathname.toLowerCase() === "admin" &&
                      category.title.toLowerCase() === "manage"
                        ? " font-bold text-[#029247] bg-[#D0F6DF] p-3 rounded"
                        : null
                    }
                    ${
                      pathname.toLowerCase() === "customers" &&
                      category.title.toLowerCase() === "manage"
                        ? " font-bold text-[#029247] bg-[#D0F6DF] p-3 rounded"
                        : null
                    }
                    ${
                      pathname.toLowerCase() === "kyc" &&
                      category.title.toLowerCase() === "manage"
                        ? " font-bold text-[#029247] bg-[#D0F6DF] p-3 rounded"
                        : null
                    }
                    ${
                      pathname.toLowerCase() === "loans/health" &&
                      category.title.toLowerCase() === "loans"
                        ? " font-bold text-[#029247] bg-[#D0F6DF] p-3 rounded"
                        : null
                    }
                 `}
              >
                {pathname.toLowerCase() === category.title.toLowerCase()
                  ? category.icon2
                  : category.icon}

                <p
                  className={` ${
                    pathname.toLowerCase() === category.title.toLowerCase()
                      ? " font-bold text-[#029247]"
                      : ""
                  } 

                    ${
                      pathname.toLowerCase() === "admin" &&
                      category.title.toLowerCase() === "manage"
                        ? " font-bold text-[#029247]"
                        : null
                    }
                    ${
                      pathname.toLowerCase() === "customers" &&
                      category.title.toLowerCase() === "manage"
                        ? " font-bold text-[#029247]"
                        : null
                    }
                        ${
                          pathname.toLowerCase() === "kyc" &&
                          category.title.toLowerCase() === "manage"
                            ? " font-bold text-[#029247] bg-[#D0F6DF] p-3 rounded"
                            : null
                        }
                          ${
                            pathname.toLowerCase() === "loans/health" &&
                            category.title.toLowerCase() === "loans"
                              ? " font-bold text-[#029247] bg-[#D0F6DF] p-3 rounded"
                              : null
                          }
                 
                    `}
                >
                  {category.title}
                </p>
                <p key={category?.title} className="cursor-pointer">
                  {category?.category &&
                  subMenu.toLowerCase() === category.title.toLowerCase() &&
                  ID === category.id ? (
                    <ArrowUp />
                  ) : category?.category ? (
                    <ArrowDown />
                  ) : null}
                </p>
              </div>
              {category?.category &&
              subMenu.toLowerCase() === category.title.toLowerCase() &&
              ID === category.id ? (
                <div className="ml-10" key={item.id}>
                  {item?.category?.map((subitem: any) => (
                    <div
                      key={subitem.id}
                      className=" flex space-y-4 flex-col cursor-pointer"
                    >
                      {subitem?.category?.map((item: any) => (
                        <div
                          key={item.link}
                          onClick={() => navigate(item.link)}
                        >
                          <p
                            className={` ${
                              pathname.toLowerCase() ===
                              item.title.toLowerCase()
                                ? " font-bold text-[#029247]"
                                : "text-[#1C1D21]"
                            }
                                 ${
                                   item.title.toLowerCase() ===
                                     "health loans" &&
                                   pathname.toLowerCase() === "loans/health"
                                     ? "font-bold text-[#029247]"
                                     : ""
                                 }`}
                          >
                            {item.title}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ) : null}
            </>
            {/* }  */}
          </>
        ))}
      </div>
    ))}
  </>
);
