import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  BNPL,
  Cards,
  Dashboard,
  Investment,
  Loans,
  Login,
  NotFound,
  Report,
  Customers,
  HealthLoans,
  Support,
  Users,
  Vas,
  Wallet,
  FAQ,
  KYC,
  Notification,
} from "./Pages";
import { Loading } from "./components";
import { ErrorBoundary } from "./error/errorBoundary";

function App() {
  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Login />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Dashboard />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Loans />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/kyc"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <KYC />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans/health"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <HealthLoans />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/investments"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Investment />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/notification"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Notification />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/Vas"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Vas />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/support"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Support />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/wallet"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Wallet />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/faq"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <FAQ />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/cards"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Cards />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/admin"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Users />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/BNPL"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <BNPL />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/report"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Report />
                </Suspense>
              </ErrorBoundary>
            }
          />

          <Route
            path="/customers"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Customers />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="*"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <NotFound />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
