import { memo } from "react";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import { ViewKYC, RejectKYC } from "./";

type Props = {
  data: any;
};

const ApproveKYC = ({ data }: Props) => {
  const { handleClick, open, title } = useStateContext();
  return (
    <>
      {open && title === "approveKYC" ? <ViewKYC data={data} /> : null}
      {open && title === "deleteKYC" ? <RejectKYC data={data} /> : null}
      <BigCard>
        <h3 className="text-[#1C1D21] font-bold text-xl my-5">
          Know your customer (KYC)
        </h3>

        <div className="flex items-center justify-between flex-row-reverse ">
          <button
            onClick={() => handleClick("deleteKYC")}
            type="button"
            className="btn text-[#BD0A00] text-xs md:py-3 md:px-6 bg-transparent border-[#BD0A00] border-2 font-semibold"
          >
            Delete Request
          </button>
          <button
            type="button"
            onClick={() => handleClick("approveKYC")}
            className="btn text-xs md:py-3 md:px-6  "
          >
            Approve Request
          </button>
        </div>
      </BigCard>
    </>
  );
};

export default memo(ApproveKYC);
