import { useRef, useState, memo, useMemo } from "react";
import { GalleryIcon, SendIcon } from "../../assets/icons/Message";
import { useStateContext } from "../../context/context";
import { useAppSelector } from "../../redux/hook";
// import { IssueDetails, User } from "../../typings";
import { getApi, postApi } from "../../utils/services";
import { Toast } from "../Toast";
import Message from "./message";

// import {
//   HubConnectionBuilder,
//   LogLevel,
//   HubConnection,
//   HttpTransportType,
// } from "@microsoft/signalr";

const TicketChat = () => {
  const [message, setMessage] = useState("");
  const messageRef = useRef(null);
  const { ticketId, closedTicket, newMessage } = useStateContext();
  const [messageThread, setMessageThread] = useState([]);
  const userid = useAppSelector((state) => state.user.user);

  const sendMessage = (e) => {
    e.preventDefault();
    const payload = {
      ticketId,
      message,
      userId: userid.userid,
      isAdmin: true,
    };
    if (message) {
      postApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Ticket/RespondToTicket`,
        payload
      )
        .then((res) => {
          getApi(
            `${process.env.REACT_APP_BASE_URL_ARY}/Ticket/GetMessagesByTicketId?id=${ticketId}`
          )
            .then((res) => {
              setMessageThread(
                res.responseData.sort(
                  (objA, objB) =>
                    Number(new Date(objA.dateCreated)) -
                    Number(new Date(objB.dateCreated))
                )
              );
              messageRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start",
              });
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          console.log("error", err);
        });
    } else {
      Toast({
        title: "enter a message",
        error: true,
      });
    }
    setMessage("");
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      sendMessage(event);
    }
  };

  // am calling the signalr here and joining rule
  // const messageMemo = useMemo(() => {
  //   try {
  //     const connection: HubConnection = new HubConnectionBuilder()
  //       .withUrl("https://apitest.fastcredit-ng.com/hub/ticket", {
  //         transport: HttpTransportType.LongPolling,
  //       })
  //       .withAutomaticReconnect()
  //       .configureLogging(LogLevel.Information)
  //       .build();

  //     connection.on("getMessages", (message) => {
  //       console.log("mapping", messageThread);
  //       setMessageThread(
  //         message.responseData.sort(
  //           (objA, objB) =>
  //             Number(new Date(objA.createdAt)) -
  //             Number(new Date(objB.createdAt))
  //         )
  //       );
  //       messageRef.current.scrollIntoView({
  //         behavior: "smooth",
  //         // block: "start",
  //         inline: "start",
  //       });
  //     });

  //     connection.onreconnected(() => {
  //       console.log("reconnected");
  //       connection
  //         .invoke("JoinGroup", ticketId)
  //         .then((res) => {
  //           console.log("room created", res);
  //         })
  //         .catch((err:) => console.log("error", err));
  //     });

  //     connection
  //       .start()
  //       .then((res) =>
  //         connection
  //           .invoke("JoinGroup", ticketId)
  //           .then((res) => {
  //             console.log("room created", res);
  //           })
  //           .catch((err:) => console.log("error", err))
  //       )
  //       .catch((err:) => console.log("error", err));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [messageThread]);

  useMemo(() => {
    getApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/Ticket/GetMessagesByTicketId?id=${ticketId}`
    )
      .then((res) => {
        setMessageThread(
          res.responseData.sort(
            (objA, objB) =>
              Number(new Date(objA.dateCreated)) -
              Number(new Date(objB.dateCreated))
          )
        );
        //  messageRef.current.scrollIntoView({
        //           behavior: "smooth",
        //           block: "start",
        //           inline: "start",
        //         });
      })
      .catch((err) => console.log(err));
    console.log("newMessage", newMessage);
  }, [ticketId, newMessage]);

  return (
    <div className="relative">
      <div className="my-4 relative ">
        <div className="flex flex-col h-[200px] w-full scrollbar-hide overflow-scroll">
          {messageThread.length > 0
            ? messageThread.map((item, index) => (
                <Message message={messageRef} key={index} item={item} />
              ))
            : "start a message thread..."}

          {/* <div  /> */}
        </div>
        {!closedTicket ? (
          <div className="flex items-center">
            <GalleryIcon />
            <div className="w-full flex flex-1 items-center border-2 mx-4 border-[#4C4D50] rounded-full px-2">
              <input
                type="text"
                value={message || ""}
                placeholder="Reply with a text ......"
                className="bg-transparent outline-none border-none flex-1 p-4 w-full "
                onKeyPress={handleKeyPress}
                onChange={(e) => setMessage(e.target.value)}
              />
              <span onClick={sendMessage} className="cursor-pointer">
                <SendIcon />
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(TicketChat);
