import { memo } from "react";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import { VasDetail } from "../typings";
import { DeleteVas, ApproveVas } from "./";

type Props = {
  data: VasDetail;
};

const VasManagement = ({ data }: Props) => {
  const { handleClick, open, title } = useStateContext();
  return (
    <>
      {open && title === "approveVas" ? <ApproveVas data={data} /> : null}
      {open && title === "deleteVas" ? <DeleteVas data={data} /> : null}
      {data?.tranStatus && data?.tranStatus.toLowerCase() !== "successful" ? (
        <BigCard>
          <h3 className="text-[#1C1D21] font-bold text-xl my-5">
            Manage VAS Transaction{" "}
          </h3>

          <div className="flex items-center justify-between flex-row-reverse ">
            <button
              onClick={() => handleClick("deleteVas")}
              type="button"
              className="btn text-[#BD0A00] text-xs md:py-3 md:px-6 bg-transparent border-[#BD0A00] border-2 font-semibold"
            >
              Delete Request
            </button>
            <button
              type="button"
              onClick={() => handleClick("approveVas")}
              className="btn text-xs md:py-3 md:px-6  "
            >
              Approve Request
            </button>
          </div>
        </BigCard>
      ) : null}
    </>
  );
};

export default memo(VasManagement);
