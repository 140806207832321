import { memo } from "react";
import BigCard from "../shared/BigCard";
import { CustomerInvestmentDetail } from "../typings";
import { addThousandSeparator } from "../utils";

type Props = {
  data: CustomerInvestmentDetail | null;
};

const InvestmentDetail = ({ data }: Props) => {
  return (
    <BigCard>
      <h4 className="text-xl text-[#1C1D21] font-semibold">
        Investment Details
      </h4>
      <section className="my-3 flex flex-col space-y-4 divide-y-2">
        <div className="flex items-center space-x-20 justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Current Investment amount</h2>
          <p className="text-[#1F2024] flex items-center font-semibold">
            <span className="mr-1">&#8358;</span>{" "}
            {addThousandSeparator((data?.principalAmount as string) ?? 0)}{" "}
          </p>
        </div>
        <div className="flex items-center space-x-20 justify-between py-2 ">
          <h2 className="text-[#4C4D50]">
            Total Yield from Investment till Date
          </h2>
          <p className="text-[#1F2024] flex items-center font-semibold">
            <span className=" mr-1">&#8358;</span>{" "}
            {addThousandSeparator((data?.currentBalance as string) ?? 0)}{" "}
          </p>
        </div>
        <div className="flex items-center space-x-20 justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Tenure (days)</h2>
          <p className="text-[#1F2024] font-semibold">
            {data?.investmentDuration}{" "}
          </p>
        </div>
        <div className="flex items-center space-x-20 justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Type</h2>
          <p className="text-[#1F2024] font-semibold">{data?.investmentType}</p>
        </div>
        <div className="flex items-center space-x-20 justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Status</h2>
          <p className="text-[#1F2024] font-semibold">{data?.status} </p>
        </div>
        <div className="flex items-center space-x-20 justify-between py-2 ">
          <h2 className="text-[#4C4D50]">interest rate(%)</h2>
          <p className="text-[#1F2024] font-semibold">{data?.rate} </p>
        </div>
        <div className="flex items-center space-x-20 justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Request Date</h2>
          <p className="text-[#1F2024] font-semibold">
            {new Date(data?.dateCreated ?? "").toDateString()}{" "}
          </p>
        </div>
        <div className="flex items-center space-x-20 justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Reference Number</h2>
          <p className="text-[#1F2024] font-semibold text-sm">
            {data?.referenceId}{" "}
          </p>
        </div>
      </section>{" "}
    </BigCard>
  );
};

export default memo(InvestmentDetail);
