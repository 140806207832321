import { LoadingLogo } from "../assets/images";

const Loading = () => {
  return (
    <div className="h-screen w-full fixed top-0 left-0 z-50 bg-gray-400 opacity-90 flex flex-col  items-center justify-center font-black">
      <LoadingLogo />
      <h3 className="capitalize text-lg ml-5">
        <span className="text-red-600">fast</span>{" "}
        <span className="text-green-600">credit</span>
      </h3>
      <p className="ml-5 capitalize ">get it fast..</p>
    </div>
  );
};

export default Loading;
