import { useState, memo } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import InputForm from "../shared/InputForm";
import ModalCard from "../shared/Modal";

type Props = {};

const ResetPassword = (props: Props) => {
  const { handleClick } = useStateContext();
  const [inputFile, setInputFile] = useState({
    username: "",
    password: "",
  });
  const handleChange = (e: any) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <ModalCard>
      <form className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Reset Password
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>

        <InputForm
          handleChange={handleChange}
          type="password"
          value={inputFile.username}
          label="new password"
          name="newpassword"
          placeholder="Enter your password"
        />
        <InputForm
          handleChange={handleChange}
          type="password"
          value={inputFile.username}
          label="confirm password"
          name="confirmpassword"
          placeholder="Enter your password"
        />

        <div className="flex  justify-center">
          <button type="submit" className="btn">
            Confirm
          </button>
        </div>
      </form>
    </ModalCard>
  );
};

export default memo(ResetPassword);
