import { useState } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { AccesForUsers } from "../typings";
import { postApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {
  data: AccesForUsers;
};

const DisableUser = ({ data }: Props) => {
  const [loading, setLoading] = useState(false);
  const { handleClick, setTitle, setEdit } = useStateContext();
  const disable = (e: any) => {
    e.preventDefault();
    // const payload = { Username: data.Username };
    const payload = {
      userId: data.userid,
      isEnabled: false,
    };
    if (data.Active === "N") {
      Toast({
        title: "User Already Disabled",
        error: true,
      });
    } else {
      setLoading(true);
      // postApi(
      //   `${process.env.REACT_APP_BASE_URL}/coreapi/disableUser/${data?.userid}`,
      //   payload
      // )
      postApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/DisableEnableUser`,
        payload
      )
        .then((res) => {
          if (res === "Request failed with status code 400") {
            Toast({
              title: "Error Ocurred",
              error: true,
            });
          } else {
            setLoading(false);
            setEdit((prev: boolean) => !prev);
            res === "Request failed with status code 422"
              ? Toast({
                  title: "Error Ocurred",
                  error: true,
                })
              : Toast({
                  title: res.data,
                  error: false,
                });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    }
  };

  const cancel = () => {
    setTitle("");
    handleClick();
  };
  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Disable User
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          Are you sure you want to disable this user ?
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={cancel}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button onClick={disable} type="submit" className="btn bg-[#FF3B30]">
            {loading ? "processing..." : "Disable User"}
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default DisableUser;
