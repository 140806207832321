import { memo, useRef, useEffect } from "react";
import { useAppSelector } from "../../redux/hook";
// import { useAppSelector } from "../redux/hook";
// import moment from "moment";

const style = {
  message: `flex flex-col font-semibold shadow-xl m-4 py-2 px-3 rounded-xl `,
  name: ` text-[#4C4D50] text-[10px] font-light`,
  sent: `bg-[#029247] flex-row-reverse !text-white text-end float-right rounded-xl`,
  received: `bg-[#E9E9E9]/10 float-left !text-[#4C4D50] rounded-xl`,
};

const Message = ({ item }) => {
  const userid = useAppSelector((state) => state.user.user);

  const messageClass =
    item?.isAdmin !== true ? `${style.received}` : `${style.sent}`;
  const messageRef = useRef(null);

  useEffect(() => {
    messageRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="" ref={messageRef}>
      <div className={` ${style.message} ${messageClass} mb-3`}>
        <p className={style.name}>{item?.senderName || userid?.username}</p>
        <div className="flex flex-col">
          <p>{item.message}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(Message);
