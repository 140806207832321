// import { useState } from "react";
import { useState } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { postApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {
  data: any;
};

const RejectBNPL = ({ data }: Props) => {
  const { handleClick, setTitle } = useStateContext();
  const [loading, setLoading] = useState(false);

  const cancel = () => {
    setTitle("");
    handleClick();
  };
  const reject = () => {
    setLoading(true);
    const payload = {
      id: data.id,
      approved: false,
    };
    postApi(
      "https://mobileapp.fastcredit-ng.com/api/WebsiteLoanRequests/status",
      payload
    )
      .then((res) => {
        console.log("res", res);
        setLoading(false);
        Toast({ title: res?.responseMessage, error: false });
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };
  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Reject Transaction
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          Are you sure you want to reject?
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={cancel}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button onClick={reject} type="submit" className="btn bg-[#FF170A]">
            {loading ? "processing..." : "Reject"}
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default RejectBNPL;
