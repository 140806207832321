import { memo, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { BackIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import VerifiedCard from "../shared/VerifiedCard";
import { Customer } from "../typings";
import { addThousandSeparator } from "../utils";
// import { CustomerInvestmentDetail } from "../typings";
import { getApi } from "../utils/services";
import {
  InvestmentDetail,
  InvestmentHistory,
  InvestmentManagement,
  WalletDetail,
} from "./";
import Detail from "./Detail";
import InvestmentCard from "./InvestmentCard";

type Props = {
  data: any;
};

const InvestmentDetails = ({ data }: Props) => {
  const { setEdit, setEditData, openMenu } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<Customer>();
  const back = () => {
    setEdit(false);
    setEditData(null);
  };

  useEffect(() => {
    setLoading(true);
    getApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/Investment/GetInvestmentByUserId/${data.userId}`
    )
      .then((res: any) => {
        setLoading(false);
        // setInvestmentData(res.responseData.investments[0]);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log("err", err);
      });
  }, [data.userId]);
  useEffect(() => {
    const fetchPosts = async () => {
      getApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/customers/${data.userId}`
      )
        .then((res: any) => {
          setCustomer(res?.responseData);
        })

        .catch((err: any) => {
          console.log("err", err);
        });
    };

    fetchPosts();
  }, [data?.userId]);

  useEffect(() => {
    setLoading(true);
    getApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/Investment/GetInvestmentSumForUser/${data.userId}`
    )
      .then((res: AxiosResponse) => {
        setLoading(false);
        // setPortfolio(res.data.responseData);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log("err", err);
      });
  }, [data.userId]);

  return (
    <>
      <div
        className="flex items-center space-x-4 font-semibold cursor-pointer my-10"
        onClick={back}
      >
        <BackIcon />
        <p className="text-[#1C1D21]">Back to investments</p>
      </div>
      <div className="flex w-full flex-col space-y-5">
        <section
          className={`flex xl:items-center ${
            openMenu
              ? " flex-col lg:flex-row space-x-6"
              : "flex-col xl:flex-row xl:space-x-6"
          }`}
        >
          <div className="">
            <VerifiedCard userid={data?.userId} />
          </div>
          <div>
            <section className="md:items-center flex-col md:flex-row md:space-x-5 hidden md:flex  md:-ml-10 lg:ml-0">
              <InvestmentCard
                title="Investment Portfolio"
                data={` ${addThousandSeparator(
                  (data.principalAmount as string) ?? 0
                )}`}
              />
            </section>
          </div>
        </section>
        <section
          className={`flex flex-col ${
            openMenu
              ? "lg:flex-row lg:!space-x-20"
              : "flex-col xl:flex-row xl:!space-x-20"
          }`}
        >
          <div>{loading ? "loading..." : <InvestmentDetail data={data} />}</div>
          <div className="flex flex-col space-y-5">
            <InvestmentManagement data={data} />
            {/* <InvestmentHistory userid={data.userId} /> */}
            <Detail data={customer} />
          </div>
        </section>
      </div>
    </>
  );
};

export default memo(InvestmentDetails);
