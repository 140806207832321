import { memo } from "react";
import { CloseIcon, FingerPrintIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";

type Props = {};

const AuthSetting = (props: Props) => {
  const { handleClick } = useStateContext();

  return (
    <ModalCard>
      <form className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Authentication Method
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <section className="flex flex-col space-y-5">
          <div className="w-[327px] h-[65px] rounded shadow-md flex justify-between px-6 ">
            <div className="flex items-center space-x-3">
              <FingerPrintIcon />
              <p className="text-[#1C1D21] font-semibold">Biometrics</p>
            </div>
            <input
              type="checkbox"
              className="accent-green-700 ml-auto "
              name="biometrics"
            />
          </div>
          <div className="w-[327px] h-[65px] rounded shadow-md flex justify-between px-6 ">
            <div className="flex items-center space-x-3">
              <FingerPrintIcon />
              <p className="text-[#1C1D21] font-semibold">Face ID</p>
            </div>
            <input
              type="checkbox"
              className="accent-green-700 ml-auto "
              name="FaceID"
            />
          </div>
          <div className="w-[327px] h-[65px] rounded shadow-md flex justify-between px-6 ">
            <div className="flex items-center space-x-3">
              <FingerPrintIcon />
              <p className="text-[#1C1D21] font-semibold">Password</p>
            </div>
            <input
              type="checkbox"
              className="accent-green-700 ml-auto "
              name="biometrics"
            />
          </div>
        </section>

        <div className="flex  justify-center">
          <button type="submit" className="btn">
            Confirm
          </button>
        </div>
      </form>
    </ModalCard>
  );
};

export default memo(AuthSetting);
