import { memo } from "react";

type Props = {
  handleChange: (e: any) => void;
  label: string;
  name: string;
};

const DateForm = ({ label, name, handleChange }: Props) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={label} className="font-semibold capitalize ">
        {label}
      </label>
      <input
        type="date"
        onChange={handleChange}
        className="p-3 bg-[#F6F6F6] w-full outline-none border-none"
        name={name}
      />
    </div>
  );
};

export default memo(DateForm);
