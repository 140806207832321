import { useState, memo, useEffect } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { AxiosError } from "axios";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import InputForm from "../shared/InputForm";
import ModalCard from "../shared/Modal";
import SelectForm from "../shared/SelectForm";
import { getApi, postApi } from "../utils/services";
import { Toast } from "./Toast";

// type Props = {};

const UserFormCard = () => {
  const { handleClick } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const [inputFile, setInputFile] = useState({
    firstname: "",
    lastname: "",
    password: "",
    role: "",
    email: "",
    phone: "",
  });
  const handleChange = (e: any) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };
  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const grantAccess = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      firstName: inputFile.firstname.toLowerCase().trim(),
      lastName: inputFile.lastname.toLowerCase().trim(),
      middleName: "",
      staffId: inputFile.email.toLowerCase().trim(),
      emailAddress: inputFile.email.toLowerCase().trim(),
      password: inputFile.password.trim(),
      dateOfEmployment: "2023-03-03T08:57:22.146Z",
      roleId: inputFile.role.toLowerCase().trim(),
    };
    if (!inputFile.firstname || !inputFile.lastname || !inputFile.email) {
      Toast({
        title: `Fill all fields`,
        error: true,
      });
      setLoading(false);
    } else {
      // postApi(
      //   `${process.env.REACT_APP_BASE_URL}/coreapi/GrantUserAccess`,
      //   payload
      // )
      postApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/CreateAdmin`,
        payload
      )
        .then((res) => {
          console.log("res", res);
          if (res === "Request failed with status code 422") {
            setLoading(false);

            Toast({
              title: `error occured`,
              error: true,
            });
          } else {
            setLoading(false);
            Toast({
              title: res.responseMessage,
              error: false,
            });
          }
        })
        .catch((err: any) => {
          Toast({
            title: `error occured`,
            error: true,
          });
          setLoading(false);
          console.log("ff", err);
        });
    }
  };
  // roles
  useEffect(() => {
    getApi(`${process.env.REACT_APP_BASE_URL_ARY}/Admin/role/GetRoles`)
      .then((res) => {
        // console.log("roles", res);
        setRole(res.responseData);
      })
      .catch((err: AxiosError) => console.log(err));
  }, []);

  return (
    <ModalCard>
      <form
        className="flex justify-center  flex-col space-y-7 w-[500px] p-5 md:p-16 bg-white rounded-xl "
        onSubmit={grantAccess}
      >
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Add New User
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <div className="h-[300px] lg:h-[400px] overflow-y-scroll w-full flex flex-col space-y-2">
          <InputForm
            handleChange={handleChange}
            type="text"
            label="first name"
            value={inputFile.firstname}
            name="firstname"
            placeholder="Type here"
          />
          <InputForm
            handleChange={handleChange}
            type="text"
            label="last name"
            value={inputFile.lastname}
            name="lastname"
            placeholder="Type here"
          />
          <InputForm
            handleChange={handleChange}
            type="email"
            label="email address"
            value={inputFile.email}
            name="email"
            placeholder="Type here"
          />
          <SelectForm
            data={role}
            label="select role"
            name="role"
            handleChange={handleChange}
          />
          <InputForm
            handleChange={handleChange}
            value={inputFile.phone}
            type="number"
            label="phone number"
            name="phone"
            placeholder="Type here"
          />
          <div
            className="relative top-[4.2rem] cursor-pointer w-10 left-[80%]"
            onClick={handleShowPassword}
          >
            {showPassword ? <BsEye /> : <BsEyeSlash />}
          </div>
          <InputForm
            handleChange={handleChange}
            value={inputFile.password}
            type={showPassword ? "text" : "password"}
            label="password"
            name="password"
            placeholder="Type here"
          />
        </div>
        <div className="flex  justify-center">
          <button type="submit" className="btn">
            {loading ? "processing..." : "click to save"}
          </button>
        </div>
      </form>
    </ModalCard>
  );
};

export default memo(UserFormCard);
