import { memo, useState } from "react";
import axios from "axios";
import { BackIcon } from "../assets/icons/ManageUsers";
import { MessageIcon } from "../assets/icons/Message";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import VerifiedCard from "../shared/VerifiedCard";
import { IssueDetails } from "../typings";
import ChatBox from "./ChatBox";
import { Toast } from "./Toast";

type Props = {
  data: IssueDetails;
};

const SupportDetails = ({ data }: Props) => {
  const { setEdit, setEditData, openMenu } = useStateContext();
  const [loading, setLoading] = useState(false);
  const back = () => {
    setEdit(false);
    setEditData(null);
  };

  const closeTicket = (ticketId: string) => {
    setLoading(true);
    const payload = {
      ticketId,
      userId: data?.userId,
    };
    axios
      .put(`${process.env.REACT_APP_BASE_URL_ARY}/Ticket/CloseTicket`, payload)
      .then((res) => {
        if (res.data.responseData) {
          setLoading(false);
          // setClose(!close);
          back();
          Toast({
            title: `Ticket has been closed Successfully`,
            error: false,
          });
        }
      })
      .catch((err: any) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className="flex items-center space-x-4 font-semibold cursor-pointer my-10"
        onClick={back}
      >
        <BackIcon />
        <p className="text-[#1C1D21]">Back to support</p>
      </div>
      <div className="flex flex-col space-y-10">
        <section>
          <VerifiedCard userid={data.userId} />
        </section>
        <section
          className={`flex flex-wrap ${
            openMenu
              ? "flex-row xl:grid xl:grid-cols-3"
              : "flex-col  md:flex-row xl:grid xl:grid-cols-3"
          }`}
        >
          <div className="w-[271px] h-max bg-white rounded-xl p-5">
            <h3 className="text-[#1C1D21] font-bold text-xl my-5">
              Complain Log
            </h3>
            <section className="my-3 flex flex-col space-y-4 divide-y-2">
              <div className="flex items-center justify-between py-2 ">
                <div className="bg-[#F6F6F6] w-[40px] h-[40px] rounded-full p-2 flex items-center justify-center">
                  <MessageIcon />
                </div>
                <p className="text-[#1C1D21]">{data.title}</p>
              </div>
            </section>
          </div>
          <div className="-ml-3 md:ml-0 xl:-ml-24">
            <ChatBox data={data} back={back} />
          </div>
          <div className="flex flex-col space-y-10 -ml-3 md:ml-0 xl:-ml-10">
            <div className="w-[200px]">
              <BigCard>
                <h3 className="text-[#1C1D21] font-bold text-xl my-5">
                  Ticket History
                </h3>
                <div className="flex flex-col space-y-6">
                  <div>
                    <h2>Complaint Log : {data.title} ...</h2>
                    {new Date(data.dateCreated).toDateString()}
                  </div>{" "}
                </div>
              </BigCard>
            </div>
            <div>
              <BigCard>
                <h3 className="text-[#1C1D21] font-bold text-xl my-5">
                  Close Ticket
                </h3>
                <button
                  onClick={() => closeTicket(data.id)}
                  type="submit"
                  className="btn my-6 w-full py-4"
                >
                  {loading ? "closing..." : "Close Ticket"}
                </button>
              </BigCard>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default memo(SupportDetails);
