import { memo, useEffect, useState } from "react";
import NairaIcon from "../assets/icons/Naira";
import BigCard from "../shared/BigCard";
import { LoanDetails } from "../typings";
import { addThousandSeparator } from "../utils";
import { getApi } from "../utils/services";

type Props = {
  userid: string;
};

const LoanHistory = ({ userid }: Props) => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      getApi()
        // `https://apitest.fastcredit-ng.com/api/Loan/GetLoanByUserId/${userid}`
        .then((res: any) => {
          setLoading(false);
          setPosts(
            res.responseData.loans.sort(
              (objA: any, objB: any) =>
                Number(new Date(objB.dateCreated)) -
                Number(new Date(objA.dateCreated))
            )
          );
        })
        .catch((err: any) => {
          console.log("err", err);
          setLoading(false);
        });
      // console.log('res', res.data.filter(item => item.title.includes(inputText)))
    };

    fetchPosts();
  }, [userid]);

  return (
    <BigCard>
      <h3 className="text-[#1C1D21] font-bold text-xl my-5">
        Repayment History
      </h3>
      <section className="w-full h-[200px] overflow-scroll flex flex-col ">
        {loading ? (
          "Loading..."
        ) : posts?.length > 0 ? (
          posts.map((res: LoanDetails) => (
            <div key={res?.id} className="flex items-center justify-between">
              <div className="flex flex-col m-4">
                <h3 className="text-[#1F2024] font-semibold capitalize">
                  {res?.loanType}
                </h3>
                <small className="text-[#98989A] text-xs">
                  {new Date(res?.dateCreated).toDateString()}
                </small>
              </div>
              <h3 className="text-[#34C759] font-semibold flex items-center">
                <NairaIcon />
                {addThousandSeparator((res?.amount as string) ?? 0)}
              </h3>
            </div>
          ))
        ) : (
          <p className="text-[#98989A] text-xl">no result</p>
        )}
      </section>
    </BigCard>
  );
};

export default memo(LoanHistory);
