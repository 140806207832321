import { useState, useMemo, memo } from "react";
import { CloseIcon, UploadIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import InputForm from "../shared/InputForm";
import ModalCard from "../shared/Modal";
import { Customer } from "../typings";
import { postApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {
  data: Customer;
};

const BankStateMent = ({ data }: Props) => {
  const { handleClick } = useStateContext();
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [base64, setBase64] = useState("");

  const [inputFile, setInputFile] = useState({
    username: "",
    password: "",
  });
  const handleChange = (e: any) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };
  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: string = "";
      // Make new FileReader
      let reader: any = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e: any) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result: any) => {
        file["base64"] = result;
        setBase64(result);
        setType(file.type);
        setFile(file);
      })
      .catch((err: any) => {
        console.log(err);
      });
    setName(file.name);
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (data.firstName === "" || file === "") {
      Toast({
        title: `invalid input`,
        error: true,
      });
      setLoading(false);
    } else {
      const payload = {
        fileUrl: url,
        fileCat: "BANK-SMT1",
        userId: data?.userId,
      };

      if (type.slice(-3) !== "pdf") {
        Toast({
          title: `File must be a PDF format.`,
          error: true,
        });
        setLoading(false);
      } else {
        postApi(
          `${process.env.REACT_APP_BASE_URL}/coreapi/UploadUserFiles`,
          payload
        )
          .then((res: any) => {
            if (res === "Request failed with status code 404") {
              setLoading(false);
              Toast({
                title: `Error Ocurred`,
                error: true,
              });
            } else {
              setLoading(false);
              Toast({
                title: `File Uploaded Successfully`,
                error: false,
              });
            }
          })
          .catch((err: any) => {
            setLoading(false);
            console.log(err);
          });
      }
    }
  };

  useMemo(() => {
    if (base64 && type) {
      const payload = {
        file_base64: base64.slice(28),
        file_extention: type.slice(-3),
        fileCat: "MOI1-FRONT",
        phone: "2348062181571",
        userid: data?.userId,
      };
      postApi(`${process.env.REACT_APP_BASE_URL}/coreapi/UploadFileProcessor`, [
        payload,
      ])
        .then((res) => {
          setUrl(res.data[0].fileUrl);
        })
        .catch((err: any) => console.log(err));
    }
  }, [base64, type, data?.userId]);

  return (
    <ModalCard>
      <form
        onSubmit={handleSubmit}
        className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl "
      >
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Upload Bank Statement
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <section className="my-6 w-full h-[300px] lg:h-full overflow-y-scroll ">
          <div className=" flex flex-col  space-y-4">
            <InputForm
              handleChange={handleChange}
              type="text"
              label="first name"
              name="firstname"
              value={data?.firstName}
              placeholder="Type here"
            />
            <InputForm
              handleChange={handleChange}
              value={data?.lastName}
              type="text"
              label="last name"
              name="lastname"
              placeholder="Type here"
            />
            <InputForm
              handleChange={handleChange}
              value={data?.email}
              type="email"
              label="email address"
              name="email"
              placeholder="Type here"
            />
            <div className="w-[327px] h-[120px] bg-[#F6F6F6] border border-dashed rounded-xl border-[#029247] ml-6 flex items-center justify-center relative">
              {file ? (
                <p className="ml-32">{name}</p>
              ) : (
                <div className="flex flex-col items-center justify-center absolute">
                  <UploadIcon />
                  <p className="text-[#029247]">Upload Photo</p>
                </div>
              )}
              <input
                type="file"
                onChange={(e) => handleFileInputChange(e)}
                name="pdf"
                id="pdf"
                className="opacity-0"
              />
            </div>

            <div className="flex  justify-center">
              <button type="submit" className="btn">
                {loading ? "uploading..." : "Send Uploaded Statement"}
              </button>
            </div>
          </div>
        </section>
      </form>
    </ModalCard>
  );
};

export default memo(BankStateMent);
