import { memo } from "react";
import {
  AccountIcon,
  AuthIcon,
  PasswordCheckIcon,
} from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import { CustomerInvestmentDetail } from "../typings";
import { InvestmentStatus, LiquidateInvestment, InvestmentApproval } from "./";

type Props = {
  data: CustomerInvestmentDetail | null;
};

const InvestmentManagement = ({ data }: Props) => {
  const { handleClick, open, title } = useStateContext();
  return (
    <>
      {open && title === "TrackInvestmentStatus" ? (
        <InvestmentStatus data={data} />
      ) : null}
      {open && title === "LiquidateInvestment" ? <LiquidateInvestment /> : null}
      {open && title === "InvestmentApproval" ? (
        <InvestmentApproval data={data} />
      ) : null}
      <BigCard>
        <section className="flex items-center justify-between">
          <h3 className="text-[#1C1D21] font-bold text-xl my-5">
            Manage Investment
          </h3>
        </section>
        <section className=" flex flex-col space-y-7 md:flex-row md:divide-x-4 md:space-x-4 p-3  cursor-pointer">
          <article className="flex flex-col space-y-8">
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("TrackInvestmentStatus")}
            >
              <AccountIcon />
              <p className="text-[#4C4D50]">Track Investment Status</p>
            </div>

            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("LiquidateInvestment")}
            >
              <PasswordCheckIcon />
              <p className="text-[#4C4D50]">Liquidate Investment </p>
            </div>
          </article>
          <article className="flex flex-col space-y-8 md:pl-4">
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("InvestmentApproval")}
            >
              <AuthIcon />
              <p className="text-[#4C4D50]">Investment Approval</p>
            </div>
          </article>
        </section>
      </BigCard>
    </>
  );
};

export default memo(InvestmentManagement);
