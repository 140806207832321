import { useState, memo } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import InputForm from "../shared/InputForm";
import ModalCard from "../shared/Modal";
import { CustomerWalletDetails } from "../typings";
import { postApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {
  data: CustomerWalletDetails;
};

const ResetWalletPin = ({ data }: Props) => {
  const { handleClick } = useStateContext();
  const [inputFile, setInputFile] = useState({
    username: "",
    password: "",
  });
  const handleChange = (e: any) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };

  const submit = (e: any) => {
    e.preventDefault();
    postApi(
      `https://apicore.fastcredit-ng.com/v1/coreapi/Disable_User/${data.userId}`
    )
      .then((res) => {
        console.log("res.data", res);
        Toast({
          title: res.message,
          error: false,
        });
      })
      .catch((err: any) => {
        console.log(err);
        Toast({
          title: err.message,
          error: true,
        });
      });
  };
  return (
    <ModalCard>
      <form
        className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl "
        onSubmit={submit}
      >
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Reset Pin
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>

        <InputForm
          handleChange={handleChange}
          value={inputFile.username}
          type="password"
          label="new pin"
          name="newpin"
          placeholder="Enter your pin"
        />
        <InputForm
          handleChange={handleChange}
          type="password"
          label="confirm pin"
          value={inputFile.username}
          name="confirmpin"
          placeholder="Enter your pin"
        />

        <div className="flex  justify-center">
          <button type="submit" className="btn">
            Confirm
          </button>
        </div>
      </form>
    </ModalCard>
  );
};

export default memo(ResetWalletPin);
