import { useState, memo } from "react";
import ReactPaginate from "react-paginate";
// import { useStateContext } from "../../context/context";
import PageSize from "./PageSize";
import { Products } from "./index";

const Paginate = ({ data, dropdown }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(5);
  // const { open } = useStateContext();
  const pagesVisited = pageNumber * Number(pageCount);

  const displayitems = data?.slice(
    pagesVisited,
    pagesVisited + Number(pageCount)
  );

  const page = Math.ceil(data?.length / Number(pageCount));

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="">
      <PageSize setPageCount={setPageCount} />
      <div className="w-full sm:w-[90%]">
        <Products
          data={displayitems}
          pageCount={pageCount}
          pageNumber={pageNumber}
          dropdown={dropdown}
        />
      </div>
      <ReactPaginate
        previousLabel={
          <p className="bg-[#F6F6F6] p-3 rounded-lg font-semibold capitalize">
            prev
          </p>
        }
        nextLabel={
          <p className="bg-[#F6F6F6] p-3 rounded-lg font-semibold capitalize">
            next
          </p>
        }
        pageCount={page}
        onPageChange={changePage}
        containerClassName={
          "p-2 text-xs lg:text-base flex space-x-3 items-center"
        }
        previousLinkClassName={"rounded-sm p-2"}
        nextLinkClassName={"rounded-sm p-2"}
        disabledClassName={""}
        pageClassName={"text-[#1F2024]"}
        activeClassName={"rounded-lg bg-[#F6F6F6] p-2 text-black font-semibold"}
      />
    </div>
  );
};

export default memo(Paginate);
