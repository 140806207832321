import { memo, useEffect, useState } from "react";
import { AccountManagement, RecentTransaction } from ".";
import { BackIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import Card from "../shared/Card";
import VerifiedCard from "../shared/VerifiedCard";
import { Customer } from "../typings";
import { addThousandSeparator } from "../utils";
import { getApi } from "../utils/services";
import Detail from "./Detail";

type Props = {
  data: Customer;
};

const CustomerDetails = ({ data }: Props) => {
  const { setEdit, setEditData, openMenu } = useStateContext();
  const [balance, setBalance] = useState("0");
  const [record, setRecord] = useState([]);

  const back = () => {
    setEdit(false);
    setEditData(null);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      getApi(`${process.env.REACT_APP_BASE_URL_ARY}/Admin/customers/${data.id}`)
        .then((res: any) => {
          setBalance(res.responseData?.balance);
        })

        .catch((err: any) => {
          console.log("err", err);
        });
    };

    fetchPosts();
  }, [data?.id]);

  useEffect(() => {
    const fetchPosts = async () => {
      await getApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/transactions/${data.id}?Days=30&Type=1&Status=1`
      )
        .then((res) => {
          setRecord(res.responseData.items);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    };

    fetchPosts();
  }, [data.id]);

  return (
    <>
      <div
        className="flex items-center space-x-4 font-semibold cursor-pointer my-10"
        onClick={back}
      >
        <BackIcon />
        <p className="text-[#1C1D21]">Back to customers</p>
      </div>
      <div className="flex w-full flex-col">
        <section
          className={`flex flex-col lg:!ml-0 ${
            openMenu
              ? " md:!-ml-[15em] items-center lg:flex-row space-x-6 xl:space-x-10  "
              : "flex-col space-y-4 xl:flex-row xl:items-center"
          }`}
        >
          <div className="">
            <VerifiedCard userid={data.id} />
          </div>
          <div>
            <section className="flex -ml-24 md:ml-0 md:items-center flex-col md:flex-row md:space-x-5">
              <Card
                title="Balance"
                data={addThousandSeparator(
                  (parseFloat(balance.toString().replace(/,/g, "")).toFixed(
                    2
                  ) as string) ?? 0
                )}
                link={""}
              />
              <Card
                title="Transaction History"
                data={record?.length > 0 ? record?.length : "0"}
                link={""}
              />
            </section>
          </div>
        </section>
        <section
          className={`flex flex-col ${
            openMenu
              ? "lg:flex-row lg:!space-x-20"
              : "flex-col xl:flex-row xl:space-x-20"
          }`}
        >
          <div className="-ml-4 md:ml-0">
            <Detail data={data} />
          </div>
          <div className="flex flex-col space-y-5 -ml-4 md:ml-0">
            <div>
              <AccountManagement data={data} />
            </div>
            <div>
              <RecentTransaction userid={data?.id} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default memo(CustomerDetails);
