import { useState, memo } from "react";
import { selectModuleData } from "../assets/constants";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import SelectForm from "../shared/SelectForm";
import {
  BuyNowReport,
  InvestmentReport,
  LoanReport,
  VasReport,
  WalletReport,
} from "./";

type Props = {};

const GenerateReport = (props: Props) => {
  const { handleClick } = useStateContext();
  const [inputFile, setInputFile] = useState({
    duration: "",
    type: "",
    tenor: "",
    status: "",
    startDate: "",
    endDate: "",
    module: "",
  });

  const handleChange = (e: any) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <ModalCard>
      <form className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Generate New Report
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <SelectForm
          name="module"
          handleChange={handleChange}
          data={selectModuleData}
          label="Choose Module"
        />

        {inputFile.module === "BuyNow" ? (
          <BuyNowReport inputFile={inputFile} setInputFile={setInputFile} />
        ) : null}
        {inputFile.module === "Investment" ? (
          <InvestmentReport inputFile={inputFile} setInputFile={setInputFile} />
        ) : null}
        {inputFile.module === "Loan" ? (
          <LoanReport inputFile={inputFile} setInputFile={setInputFile} />
        ) : null}
        {inputFile.module === "Vas" ? (
          <VasReport inputFile={inputFile} setInputFile={setInputFile} />
        ) : null}
        {inputFile.module === "Wallet" ? (
          <WalletReport inputFile={inputFile} setInputFile={setInputFile} />
        ) : null}
      </form>
    </ModalCard>
  );
};

export default memo(GenerateReport);
