import { memo } from "react";
import BigCard from "../shared/BigCard";
import { CustomerWalletDetails } from "../typings";
import { addThousandSeparator } from "../utils";

type Props = {
  data: CustomerWalletDetails;
};

const WalletDetail = ({ data }: Props) => {
  return (
    <BigCard>
      <section className="flex items-center justify-between">
        <h3 className="text-[#1C1D21] font-bold text-xl">
          Customer Wallet Details
        </h3>
      </section>
      <section className="my-3 flex flex-col space-y-4 divide-y-2">
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">First Name</h2>
          <p className="text-[#1C1D21]">{data.firstName} </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Last Name</h2>
          <p className="text-[#1C1D21]">{data.lastName} </p>
        </div>
        {/* <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Email Address</h2>
          <p className="text-[#1C1D21]">{data} </p>
        </div> */}
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Account Number</h2>
          <p className="text-[#1C1D21]">{data.accountNumber} </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Wallet Access</h2>
          <p className="text-[#1C1D21]">{data.status} </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Bank Name</h2>
          <p className="text-[#1C1D21]">{data.bankName} </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Date Created</h2>
          <p className="text-[#1C1D21]">
            {new Date(data?.dateCreated).toDateString()}{" "}
          </p>
        </div>

        <div>
          <div className="bg-[#01612F] w-full h-0.5 mb-4" />

          <aside className="flex items-center justify-between">
            <h2 className="text-[#014924] font-semibold">Wallet Balance</h2>
            <p className="text-[#696A6C]">Naira Wallet</p>
          </aside>
          <h1 className="text-[#01612F] text-4xl font-semibold flex items-center">
            <p className="mr-1 text-[#01612F]">&#8358;</p>{" "}
            {addThousandSeparator(
              (parseFloat(data?.balance?.toString().replace(/,/g, "")).toFixed(
                2
              ) as string) ?? 0
            )}
            {/* N{addThousandSeparator(((data.balance) as string) ?? 0)} */}
          </h1>
        </div>
      </section>
    </BigCard>
  );
};

export default memo(WalletDetail);
