import { useDeferredValue, useEffect, memo, useState } from "react";
import classNames from "classnames";
import { SearchField } from "../components";
import SkeletonTable from "../components/skeleton/Table";
import { useStateContext } from "../context/context";
import { CustomerWalletDetails } from "../typings";
import { getApi } from "../utils/services";
import Paginate from "./table/table";

type Props = {
  data: CustomerWalletDetails;
};

const WalletEntries = ({ data }: Props) => {
  const { openMenu } = useStateContext();
  const dropdown = [""];
  const [posts, setPosts] = useState([]);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const deferedValue = useDeferredValue(inputText);
  const [type, setType] = useState("1");
  const [status, setStatus] = useState("1");

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      getApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/transactions/${data.userId}?Days=30&Type=${type}&Status=${status}`
      )
        .then((res: any) => {
          setLoading(false);
          setPosts(
            deferedValue
              ? res?.responseData.items.filter((item: any) =>
                  item?.originatorName
                    .toLowerCase()
                    .includes(deferedValue.toLowerCase())
                )
              : res.responseData.items
          );
        })
        .catch((err: any) => {
          console.log("err", err);
          setLoading(false);
        });
      // console.log('res', res.data.filter(item => item.title.includes(inputText)))
    };

    fetchPosts();
  }, [deferedValue, data.userId, type, status]);

  return (
    <div>
      <section className="bg-white rounded-xl p-3 w-full h-max">
        <div className="flex space-y-3  md:items-center flex-col md:flex-row  my-4">
          <section className="flex flex-col items-center md:flex-row md:space-x-4">
            <p className="text-2xl font-bold text-[#1C1D21] capitalize">
              Wallet Entries
            </p>
            <SearchField
              inputText={inputText}
              setInputText={setInputText}
              disabled={loading ? true : false}
            />
            <div
              className={classNames(
                "flex items-center py-1 px-3 bg-[#F6F6F6]  md:ml-auto outline-none border-none rounded-full",

                {
                  "w-max": openMenu,
                  " w-[180px]": !openMenu,
                }
              )}
            >
              <p>Type:</p>
              <select
                name=""
                onChange={(e: any) => setType(e.target.value)}
                className="p-2 bg-transparent outline-none border-none font-bold capitalize"
              >
                <option value="1">all</option>
                <option value="6">bnpl</option>
                <option value="4">loan</option>
                <option value="5">investment</option>
                <option value="7">vas</option>
              </select>
            </div>
            <div
              className={classNames(
                "flex items-center py-1 px-3 bg-[#F6F6F6]  md:ml-auto outline-none border-none rounded-full",

                {
                  "w-max": openMenu,
                  " w-[180px]": !openMenu,
                }
              )}
            >
              <p>view:</p>
              <select
                name=""
                onChange={(e: any) => setStatus(e.target.value)}
                className="p-2 bg-transparent outline-none border-none font-bold capitalize"
              >
                <option value="1">all</option>
                <option value="2">successful</option>
                <option value="3">pending</option>
              </select>
            </div>
          </section>
        </div>

        <div className="w-full overflow-scroll my-3 scrollbar-thin scrollbar-track-gray-400/20 scrollbar-thumb-[#98989A]">
          {loading ? (
            <SkeletonTable />
          ) : posts?.length > 0 ? (
            <Paginate dropdown={dropdown} data={posts} />
          ) : (
            <p className="text-[#98989A] text-xl">no result</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default memo(WalletEntries);
