import { memo } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { CustomerInvestmentDetail } from "../typings";

type Props = {
  data: CustomerInvestmentDetail | null;
};

const InvestmentStatus = ({ data }: Props) => {
  const { handleClick } = useStateContext();
  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-20 text-xl text-[#4C4D50] font-semibold">
            Investment Status
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          This account is currently{" "}
          <span className="text-[#029247] font-semibold text-xl">
            {data?.status}
          </span>{" "}
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={handleClick}
            type="button"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button onClick={handleClick} type="button" className="btn">
            Go back
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default memo(InvestmentStatus);
