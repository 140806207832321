import { memo } from "react";
import { CloseIcon, PersonIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";

type Props = {};

const IssueRequest = (props: Props) => {
  const { handleClick } = useStateContext();

  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[700px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="md:ml-40 text-xl text-[#4C4D50] font-semibold">
            Issuance Requests
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <section className="my-6 flex flex-col w-full h-[300px] lg:h-[500px] space-y-4 divide-y-2  overflow-y-scroll ">
          <div className="flex items-center flex-col space-y-3 md:flex-row md:space-x-32 justify-between">
            <div className="flex items-center space-x-3">
              <PersonIcon />
              <p className="text-[#1F2024]">Bolaji </p>
            </div>
            <div className="flex items-center justify-center space-x-6">
              <button type="submit" className="btn">
                Issue
              </button>
              <button
                type="submit"
                className="btn text-[#4C4D50] bg-transparent border-[#4C4D50] border-2"
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-3 md:flex-row md:space-x-32 justify-between">
            <div className="flex items-center space-x-3">
              <PersonIcon />
              <p className="text-[#1F2024]">Bolaji </p>
            </div>
            <div className="flex items-center justify-center space-x-6">
              <button type="submit" className="btn">
                Issue
              </button>
              <button
                type="submit"
                className="btn text-[#4C4D50] bg-transparent border-[#4C4D50] border-2"
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-3 md:flex-row md:space-x-32 justify-between ">
            <div className="flex items-center space-x-3">
              <PersonIcon />
              <p className="text-[#1F2024]">Bolaji </p>
            </div>
            <div className="flex items-center justify-center space-x-6">
              <button type="submit" className="btn">
                Issue
              </button>
              <button
                type="submit"
                className="btn text-[#4C4D50] bg-transparent border-[#4C4D50] border-2"
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-3 md:flex-row md:space-x-32 justify-between ">
            <div className="flex items-center space-x-3">
              <PersonIcon />
              <p className="text-[#1F2024]">Bolaji </p>
            </div>
            <div className="flex items-center justify-center space-x-6">
              <button type="submit" className="btn">
                Issue
              </button>
              <button
                type="submit"
                className="btn text-[#4C4D50] bg-transparent border-[#4C4D50] border-2"
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-3 md:flex-row md:space-x-32 justify-between ">
            <div className="flex items-center space-x-3">
              <PersonIcon />
              <p className="text-[#1F2024]">Bolaji </p>
            </div>
            <div className="flex items-center justify-center space-x-6">
              <button type="submit" className="btn">
                Issue
              </button>
              <button
                type="submit"
                className="btn text-[#4C4D50] bg-transparent border-[#4C4D50] border-2"
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-3 md:flex-row md:space-x-32 justify-between ">
            <div className="flex items-center space-x-3">
              <PersonIcon />
              <p className="text-[#1F2024]">Bolaji </p>
            </div>
            <div className="flex items-center justify-center space-x-6">
              <button type="submit" className="btn">
                Issue
              </button>
              <button
                type="submit"
                className="btn text-[#4C4D50] bg-transparent border-[#4C4D50] border-2"
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-3 md:flex-row md:space-x-32 justify-between ">
            <div className="flex items-center space-x-3">
              <PersonIcon />
              <p className="text-[#1F2024]">Bolaji </p>
            </div>
            <div className="flex items-center justify-center space-x-6">
              <button type="submit" className="btn">
                Issue
              </button>
              <button
                type="submit"
                className="btn text-[#4C4D50] bg-transparent border-[#4C4D50] border-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </div>
    </ModalCard>
  );
};

export default memo(IssueRequest);
