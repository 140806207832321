import { memo } from "react";
import { CloseIcon, PersonIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";

type Props = {};

const DisableCard = (props: Props) => {
  const { handleClick } = useStateContext();

  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[700px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="md:ml-56 text-xl text-[#4C4D50] font-semibold">
            Disable Card
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <section className="my-6 flex flex-col w-full h-[300px] lg:h-[500px] space-y-4 divide-y-2  overflow-y-scroll">
          <div className="flex items-center flex-col space-y-4 md:flex-row md:space-x-32 justify-between ">
            <section className="flex flex-col space-y-4">
              <div className="flex items-center space-x-3">
                <PersonIcon />
                <p className="text-[#1F2024]">Bolaji </p>
              </div>
              <div className="flex flex-col space-y-2">
                <h2 className="font-semibold text-[#1C1D21]">Reason:</h2>
                <p className="text-[#4C4D50]">
                  Misplaced my card yesterday, please disable it
                </p>
              </div>
            </section>

            <div className="flex items-center justify-center space-x-6">
              <button
                type="submit"
                className="btn text-[#FF170A] bg-transparent border-[#FF170A] border-2"
              >
                Disable Card
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-4 md:flex-row md:space-x-32 justify-between ">
            <section className="flex flex-col space-y-4">
              <div className="flex items-center space-x-3">
                <PersonIcon />
                <p className="text-[#1F2024]">Bolaji </p>
              </div>
              <div className="flex flex-col space-y-2">
                <h2 className="font-semibold text-[#1C1D21]">Reason:</h2>
                <p className="text-[#4C4D50]">
                  Misplaced my card yesterday, please disable it
                </p>
              </div>
            </section>
            <div className="flex items-center justify-center space-x-6">
              <button
                type="submit"
                className="btn text-[#FF170A] bg-transparent border-[#FF170A] border-2"
              >
                Disable Card
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-4 md:flex-row md:space-x-32 justify-between ">
            <section className="flex flex-col space-y-4">
              <div className="flex items-center space-x-3">
                <PersonIcon />
                <p className="text-[#1F2024]">Bolaji </p>
              </div>
              <div className="flex flex-col space-y-2">
                <h2 className="font-semibold text-[#1C1D21]">Reason:</h2>
                <p className="text-[#4C4D50]">
                  Misplaced my card yesterday, please disable it
                </p>
              </div>
            </section>
            <div className="flex items-center justify-center space-x-6">
              <button
                type="submit"
                className="btn text-[#FF170A] bg-transparent border-[#FF170A] border-2"
              >
                Disable Card
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-4 md:flex-row md:space-x-32 justify-between ">
            <section className="flex flex-col space-y-4">
              <div className="flex items-center space-x-3">
                <PersonIcon />
                <p className="text-[#1F2024]">Bolaji </p>
              </div>
              <div className="flex flex-col space-y-2">
                <h2 className="font-semibold text-[#1C1D21]">Reason:</h2>
                <p className="text-[#4C4D50]">
                  Misplaced my card yesterday, please disable it
                </p>
              </div>
            </section>
            <div className="flex items-center justify-center space-x-6">
              <button
                type="submit"
                className="btn text-[#FF170A] bg-transparent border-[#FF170A] border-2"
              >
                Disable Card
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-4 md:flex-row md:space-x-32 justify-between ">
            <section className="flex flex-col space-y-4">
              <div className="flex items-center space-x-3">
                <PersonIcon />
                <p className="text-[#1F2024]">Bolaji </p>
              </div>
              <div className="flex flex-col space-y-2">
                <h2 className="font-semibold text-[#1C1D21]">Reason:</h2>
                <p className="text-[#4C4D50]">
                  Misplaced my card yesterday, please disable it
                </p>
              </div>
            </section>
            <div className="flex items-center justify-center space-x-6">
              <button
                type="submit"
                className="btn text-[#FF170A] bg-transparent border-[#FF170A] border-2"
              >
                Disable Card
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-4 md:flex-row md:space-x-32 justify-between ">
            <section className="flex flex-col space-y-4">
              <div className="flex items-center space-x-3">
                <PersonIcon />
                <p className="text-[#1F2024]">Bolaji </p>
              </div>
              <div className="flex flex-col space-y-2">
                <h2 className="font-semibold text-[#1C1D21]">Reason:</h2>
                <p className="text-[#4C4D50]">
                  Misplaced my card yesterday, please disable it
                </p>
              </div>
            </section>
            <div className="flex items-center justify-center space-x-6">
              <button
                type="submit"
                className="btn text-[#FF170A] bg-transparent border-[#FF170A] border-2"
              >
                Disable Card
              </button>
            </div>
          </div>
          <div className="flex items-center flex-col space-y-4 md:flex-row md:space-x-32 justify-between ">
            <section className="flex flex-col space-y-4">
              <div className="flex items-center space-x-3">
                <PersonIcon />
                <p className="text-[#1F2024]">Bolaji </p>
              </div>
              <div className="flex flex-col space-y-2">
                <h2 className="font-semibold text-[#1C1D21]">Reason:</h2>
                <p className="text-[#4C4D50]">
                  Misplaced my card yesterday, please disable it
                </p>
              </div>
            </section>
            <div className="flex items-center justify-center space-x-6">
              <button
                type="submit"
                className="btn text-[#FF170A] bg-transparent border-[#FF170A] border-2"
              >
                Disable Card
              </button>
            </div>
          </div>
        </section>
      </div>
    </ModalCard>
  );
};

export default memo(DisableCard);
