import { memo } from "react";
import { LoanDetails2 } from "../typings";
import { addThousandSeparator } from "../utils";

type Props = {
  data: LoanDetails2 | null;
};

const LoanDet = ({ data }: Props) => {
  const handleChange = () => {};
  return (
    <section className="my-3 flex flex-col space-y-4 divide-y-2">
      <div className="flex items-center space-x-6 md:space-x-20 justify-between py-2 ">
        <h2 className="text-[#4C4D50]">Total Loan Repayment</h2>
        <input
          type="text"
          onChange={handleChange}
          className="p-3 bg-transparent w-[200px] outline-none border-none"
          placeholder=""
          value={data?.repaymentAmount}
          name=""
        />
      </div>
      <div className="flex items-center w-full justify-between py-2 ">
        <h2 className="text-[#4C4D50]">Amount</h2>
        <aside className="flex items-center ">
          <p className="ml-12">&#8358;</p>{" "}
          <input
            type="text"
            onChange={handleChange}
            className="p-3 bg-transparent w-[200px] outline-none border-none"
            placeholder=""
            value={addThousandSeparator((data?.loan_amt as string) ?? 0)}
            name=""
          />
        </aside>
      </div>
      <div className="flex items-center w-full justify-between py-2 ">
        <h2 className="text-[#4C4D50]">Interest (%)</h2>
        <input
          type="text"
          onChange={handleChange}
          className="p-3 bg-transparent w-[200px] outline-none border-none"
          placeholder=""
          value={data?.interest_rate}
          name=""
        />
      </div>
      <div className="flex items-center w-full justify-between py-2 ">
        <h2 className="text-[#4C4D50]">Monthly Repayment</h2>
        <aside className="flex items-center">
          <p>&#8358;</p>{" "}
          <input
            type="text"
            onChange={handleChange}
            className="p-3 bg-transparent w-[200px] outline-none border-none"
            placeholder=""
            value={addThousandSeparator((data?.monthly_install as string) ?? 0)}
            name=""
          />
        </aside>
      </div>
      <div className="flex items-center w-full justify-between py-2 ">
        <h2 className="text-[#4C4D50]">Next Repayment</h2>
        <input
          type="text"
          onChange={handleChange}
          className="p-3 bg-transparent w-[200px] outline-none border-none"
          placeholder=""
          value={
            data?.nextRepaymentAmount &&
            data?.nextRepaymentAmount.replace(/'/g, "-'")
          }
          // value={data?.paymentSTartDate.split('').join('-')}
          name=""
        />
      </div>
      <div className="flex items-center w-full justify-between py-2 ">
        <h2 className="text-[#4C4D50]">Tenure(months)</h2>
        <input
          type="text"
          onChange={handleChange}
          className="p-3 bg-transparent w-[200px] outline-none border-none"
          placeholder=""
          value={data?.loan_tenure}
          name=""
        />
      </div>
      <div className="flex items-center w-full justify-between py-2 ">
        <h2 className="text-[#4C4D50]">Sector</h2>
        <input
          type="text"
          onChange={handleChange}
          className="p-3 bg-transparent w-[200px] outline-none border-none"
          placeholder=""
          value={data?.sector}
          name=""
        />
      </div>

      <div className="flex items-center w-full justify-between py-2 ">
        <h2 className="text-[#4C4D50]">Monthly Income</h2>
        <aside className="flex items-center">
          <p>&#8358;</p>{" "}
          <input
            type="text"
            onChange={handleChange}
            className="p-3 bg-transparent w-[200px] outline-none border-none"
            placeholder=""
            value={addThousandSeparator((data?.net_pay as string) ?? 0)}
            name=""
          />
        </aside>
      </div>
      <div className="flex items-center w-full justify-between py-2 ">
        <h2 className="text-[#4C4D50]">Date Created</h2>
        <input
          type="text"
          onChange={handleChange}
          className="p-3 bg-transparent w-[200px] outline-none border-none"
          placeholder=""
          value={new Date(data?.date_created ?? "").toDateString()}
          name=""
        />
      </div>
    </section>
  );
};

export default memo(LoanDet);
