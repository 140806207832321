import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// import tokenService from "./tokenService";

export interface TokenState {
  token: string;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  message: string | any;
}

const initialState: TokenState = {
  token: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// token user
export const token = createAsyncThunk(
  "user/token",
  async (payload: string, thunkAPI) => {
    try {
      return await payload;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(token.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = action.payload;
      })
      .addCase(token.rejected, (state: TokenState, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.token = "";
      });
  },
});

export default tokenSlice.reducer;
