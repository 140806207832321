import { memo } from "react";

type Props = {
  handleChange: (e: any) => void;
  name: string;
  label: string;
  placeholder: string;
  type: string;
  value: string;
};

const InputForm = ({
  handleChange,
  placeholder,
  name,
  type,
  label,
  value,
}: Props) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={label} className="font-semibold capitalize ">
        {label}
      </label>
      <input
        id="input-field"
        type={type}
        value={value}
        onChange={handleChange}
        className="p-3 bg-[#F6F6F6] w-full outline-none border-none"
        placeholder={placeholder}
        name={name}
      />
    </div>
  );
};

export default memo(InputForm);
