import { memo } from "react";
import { IssueRequest, DisableCard } from ".";
import { AccountIcon, AuthIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";

type Props = {};

const CardManagement = (props: Props) => {
  const { handleClick, open, title } = useStateContext();

  return (
    <>
      {open && title === "issueRequest" ? <IssueRequest /> : null}
      {open && title === "disableCard" ? <DisableCard /> : null}
      <BigCard>
        <h3 className="text-[#1C1D21] font-bold text-xl ">Card Management</h3>
        <section className="flex flex-col space-y-5 mt-6">
          <div
            className="flex items-center space-x-4 cursor-pointer"
            onClick={() => handleClick("issueRequest")}
          >
            <AccountIcon />
            <p className="text-[#1F2024]">Issuance Request</p>
          </div>
          <div
            className="flex items-center space-x-4 cursor-pointer"
            onClick={() => handleClick("disableCard")}
          >
            <AuthIcon />
            <p className="text-[#1F2024]">Disable Card</p>
          </div>
        </section>
      </BigCard>
    </>
  );
};

export default memo(CardManagement);
