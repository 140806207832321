import { memo } from "react";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import { Customer } from "../typings";
import CustomerDetail from "./CustomerDetail";

type Props = {
  data?: Customer | any;
};

const Detail = ({ data }: Props) => {
  const { handleClick, open, title } = useStateContext();

  return (
    <>
      {open && title === "customerDetail" ? (
        <CustomerDetail data={data} />
      ) : null}
      <BigCard>
        <section className="flex items-center justify-between">
          <h3 className="text-[#1C1D21] font-bold text-xl">Customer Details</h3>
          <button
            onClick={() => handleClick("customerDetail")}
            className="btn bg-transparent border border-[#029247] text-[#029247] font-semibold text-xs py-2 px-4"
          >
            Show All
          </button>
        </section>
        <section className="my-3 flex flex-col space-y-4 divide-y-2">
          <div className="flex items-center justify-between py-2 ">
            <h2 className="text-[#4C4D50]">First Name</h2>
            <p className="text-[#1C1D21]">{data?.firstName} </p>
          </div>
          <div className="flex items-center justify-between py-2 ">
            <h2 className="text-[#4C4D50]">Last Name</h2>
            <p className="text-[#1C1D21]">{data?.lastName} </p>
          </div>
          <div className="hidden md:flex items-center justify-between py-2 ">
            <h2 className="text-[#4C4D50]">Email Address</h2>
            <p className="text-[#1C1D21]">{data?.email} </p>
          </div>
          <div className="flex items-center justify-between py-2 ">
            <h2 className="text-[#4C4D50]">Phone Number</h2>
            <p className="text-[#1C1D21]">
              {data?.phoneNumber ? data?.phoneNumber.replace("234", "0") : null}{" "}
            </p>
          </div>
        </section>
      </BigCard>
    </>
  );
};

export default memo(Detail);
