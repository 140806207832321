import { memo, useEffect, useState } from "react";
import { BackIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import { Customer } from "../typings";
import { getApi } from "../utils/services";
import { KYCCard, ApproveKYC } from "./";
import Detail from "./Detail";

type Props = {
  data: Customer;
};

const KYC = ({ data }: Props) => {
  const { setEdit, setEditData, openMenu } = useStateContext();
  const [uploads, setUploads] = useState([]);

  const back = () => {
    setEdit(false);
    setEditData(null);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      getApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/customers/${data.id}/uploads`
      )
        .then((res: any) => {
          setUploads(res.responseData);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    };

    fetchPosts();
  }, [data?.id]);

  return (
    <>
      <div
        className="flex items-center space-x-4 font-semibold cursor-pointer my-10"
        onClick={back}
      >
        <BackIcon />
        <p className="text-[#1C1D21]">Back to customers</p>
      </div>
      <div className="flex w-full flex-col items-center justify-center sm:flex-row sm:items-start  sm:space-x-10 flex-wrap">
        <section className="flex flex-col items-center space-y-6">
          {uploads.length > 0
            ? uploads.map((upload: any) => (
                <KYCCard>
                  <>
                    <h2 className="text-sm font-semibold">
                      {upload.meansIdentification
                        ? upload.meansIdentification
                        : null}
                    </h2>
                    <div
                      className={
                        upload.fileCat === "Avarta"
                          ? "w-[110px] h-[110px] border-4 flex  items-center justify-center rounded-full relative border-green-700"
                          : " w-[120px] h-[120px] object-contain"
                      }
                    >
                      <img
                        src={upload.fileUrl}
                        className={
                          upload.fileCat === "Avarta"
                            ? "h-24 w-24 rounded-full object-cover"
                            : ""
                        }
                        alt="user_upload"
                        loading="lazy"
                      />
                    </div>
                    <a
                      href={upload.fileUrl}
                      className="underline text-[#014924] font-bold capitalize cursor-pointer"
                      download
                    >
                      download image
                    </a>
                  </>
                </KYCCard>
              ))
            : "no upload found"}
        </section>
        <section
          className={`flex flex-col lg:!ml-0 ${
            openMenu ? "  " : "flex-col space-y-4"
          }`}
        >
          <div className="-ml-4 md:ml-0">
            <Detail data={data} />
          </div>
          <ApproveKYC data={data} />
        </section>
      </div>
    </>
  );
};

export default memo(KYC);
