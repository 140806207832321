import { memo, useState } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { CustomerWalletDetails } from "../typings";
import { postApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {
  data: CustomerWalletDetails;
};

const WalletAccess = ({ data }: Props) => {
  const { handleClick, setEdit } = useStateContext();
  const [loading, setLoading] = useState(false);

  const enableWallet = () => {
    if (data.status.toLowerCase() !== "active") {
      const payload = {
        userId: data.userId,
        isEnabled: true,
      };
      setLoading(true);
      // postApi(
      //   `${process.env.REACT_APP_BASE_URL}/coreapi/enable_wallet/${data.userId}`
      // )
      postApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/DisableEnableWallet`,
        payload
      )
        .then((res) => {
          setEdit(true);
          setLoading(false);
          //  setEditData(data);

          Toast({
            title: res.message,
            error: false,
          });
        })
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
          Toast({
            title: err.message,
            error: true,
          });
        });
    } else {
      Toast({
        title: "Wallet Already Enabled",
        error: true,
      });
    }
  };

  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Grant Wallet Access
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          Are you sure you want to grant access to this Wallet ?
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={handleClick}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button onClick={enableWallet} type="submit" className="btn">
            {loading ? "Processing..." : "Grant Access"}
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default memo(WalletAccess);
