import { useState } from "react";
import axios from "axios";
import { useStateContext } from "../context/context";
import { postApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {
  data: any;
};

const Faq = ({ data }: Props) => {
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState(data?.title || "");
  const [mobileapp, setMobileApp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState(data?.body || "");
  const { setEdit, setOpen } = useStateContext();

  const handleCheckbox = () => {
    setMobileApp(!mobileapp);
  };

  const handleSubmit = (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const payload = {
      title,
      category: category,
      body,
      mobileapp: mobileapp,
      web: !mobileapp,
    };
    if (title && body && category) {
      data
        ? axios
            .put(
              "https://apicore.fastcredit-ng.com/v1/fclapi/updateFAQu",
              payload
            )
            .then((res) => {
              Toast({
                title: "FAQ Updated Successfully",
                error: false,
              });
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            })
        : postApi(
            `${process.env.REACT_APP_BASE_URL}/fclapi/createfaqu`,
            payload
          )
            .then((res) => {
              Toast({
                title: res.message,
                error: false,
              });
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
    } else {
      Toast({
        title: "please fill all fields",
        error: true,
      });
    }
  };

  const back = () => {
    setEdit(false);
    setOpen(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full flex flex-col md:ml-10 sm:w-1/2 sm:mt-10"
    >
      <h3 onClick={back} className="btn bg-gray-500 absolute right-10 ">
        back
      </h3>

      <label className="label" htmlFor="question">
        Question
      </label>
      <input
        type="text"
        onChange={(e) => setTitle(e.target.value)}
        className="input p-4 mb-4"
        id="question"
        value={title}
      />
      <label className="label" htmlFor="question">
        Category
      </label>
      <select
        name=""
        id=""
        onChange={(e) => setCategory(e.target.value)}
        className="input p-4"
      >
        <option value="">choose category</option>
        <option value="loan">loan</option>
        <option value="investment">investment</option>
        <option value="buynow">buynow</option>
      </select>
      <label className="label mt-4" htmlFor="answer">
        Answer
      </label>
      <textarea
        className="input p-3"
        onChange={(e) => setBody(e.target.value)}
        id="answer"
        value={body}
      />
      <div className="flex items-center space-x-10">
        <aside className="flex items-center space-x-2">
          <label className="label mt-4" htmlFor="answer">
            Website
          </label>
          <input
            type="checkbox"
            checked={!mobileapp}
            id="web"
            onChange={handleCheckbox}
            className="btn mt-4 accent-green-300 "
            value="website"
          />
        </aside>
        <aside className="flex items-center space-x-2">
          <label className="label mt-4" htmlFor="answer">
            Mobile App
          </label>
          <input
            type="checkbox"
            checked={mobileapp}
            id="mobileapp"
            onChange={handleCheckbox}
            className="btn mt-4 accent-green-300"
            value="mobileapp"
          />
        </aside>
      </div>

      <input
        type="submit"
        className="btn mt-4"
        value={loading ? "submitting..." : "save"}
        id="save"
      />
    </form>
  );
};

export default Faq;
