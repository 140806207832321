import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { Toast } from "../components/Toast";

export const URL = "https://apicore.fastcredit-ng.com/v1";

export const api = async () => {
  const url = `${URL}/fclapi/login`;
  const payload = {
    Client_key: `${process.env.REACT_APP_CLIENT_KEY}`,
    Client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
  };
  const response = await axios
    .post(url, payload)
    .then((res) => {
      secureLocalStorage.setItem("secure-auth22", res.data.token);
      // localStorage.setItem("expire", JSON.stringify(res.data.expiration));
      return res.data;
    })
    .catch((err) => console.log(err));
  return response;
};

export const getApi2 = async (url) => {
  let data;
  if (typeof window !== "undefined") {
    data = secureLocalStorage.getItem("secure-auth22");
  }
  const options = {
    headers: {
      Authorization: `Bearer ${data}`,
      " X-Content-Type-Options": "nosniff",
      "Access-Control-Allow-Origin": "*",
    },
  };
  try {
    return await axios
      .get(url, options)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        // localStorage.removeItem("auth");
        console.log("err", err);
      });
  } catch (err) {
    return err.response;
  }
};
export const getApi = async (url) => {
  let data;
  if (typeof window !== "undefined") {
    data = secureLocalStorage.getItem("secure-auth");
  }
  const options = {
    headers: {
      Authorization: `Bearer ${data}`,
      " X-Content-Type-Options": "nosniff",
      "Access-Control-Allow-Origin": "*",
    },
  };
  try {
    return await axios
      .get(url, options)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          Toast({ title: "Session Timeout", error: true });
          window.location.replace("/");
          localStorage.removeItem("auth");
          localStorage.removeItem("secure-auth");
          localStorage.removeItem("secure-auth22");
        }
        throw err;
      });
  } catch (err) {
    return err.response;
  }
};

export const postApi = async (url, payload) => {
  let data;
  if (typeof window !== "undefined") {
    data = secureLocalStorage.getItem("secure-auth");
  }
  const options = {
    headers: {
      Authorization: `Bearer ${data}`,
      "Access-Control-Allow-Origin": "*",
      " X-Content-Type-Options": "nosniff",
    },
  };
  const response = await axios
    .post(url, payload, options)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        console.log("err");
      }
    })
    .catch((err) => {
      console.log("error msg: ", err);
      return err.response.data;
    });
  return response;
};

// export const reportPostApi = async (url, payload) => {
//   let data;
//   if (typeof window !== "undefined") {
//     data =  secureLocalStorage.getItem("secure-auth");
//   }
//   const options = {
//     headers: {
//       Authorization: `Bearer ${data}`,
//       "Access-Control-Allow-Origin": "*",
//     },
//   };
//   const response = await axios
//     .post(url, payload, options, {
//       responseType: "blob",
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         return res;
//       } else {
//         console.log("err");
//       }
//     })
//     .catch((err) => {
//       console.log("error msg: ", err.message);
//       return err.message;
//     });
//   return response;
// };

export const putApi = async (url, payload) => {
  let data;
  if (typeof window !== "undefined") {
    data = secureLocalStorage.getItem("secure-auth");
  }
  const options = {
    headers: {
      Authorization: `Bearer ${data}`,
      " X-Content-Type-Options": "nosniff",
      "Access-Control-Allow-Origin": "*",
    },
  };
  const response = await axios
    .put(url, payload, options)
    .then((res) => {
      // if (res.status === 200) {
      //   return res.data;
      // } else {
      //   console.log("err");
      // }
      return res.data;
    })
    .catch((err) => {
      console.log("error msg: ", err.message);
      return err.message;
    });
  return response;
};
export const deleteApi = async (url, payload) => {
  let data;
  if (typeof window !== "undefined") {
    data = secureLocalStorage.getItem("secure-auth");
  }
  const options = {
    headers: {
      Authorization: `Bearer ${data}`,
      " X-Content-Type-Options": "nosniff",
      "Access-Control-Allow-Origin": "*",
    },
  };
  const response = await axios
    .delete(url, options)
    .then((res) => {
      // if (res.status === 200) {
      //   return res.data;
      // } else {
      //   console.log("err");
      // }
      return res.data;
    })
    .catch((err) => {
      console.log("error msg: ", err.message);
      return err.message;
    });
  return response;
};
