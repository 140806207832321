import { memo, useState, useEffect } from "react";
import {
  Arrow,
  Vas,
  Wallet,
  Card as Cards,
  Investment,
  BNPL,
} from "../assets/icons/Dashboard";
import SkeletonCard from "./skeleton/Card";

type Props = {
  title: string;
  data: number | string | null | undefined | string[] | any;
};

const Card = ({ title, data }: Props) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    title && data ? setLoading(false) : setLoading(true);
  }, [title, data]);

  return (
    <>
      {loading ? (
        <SkeletonCard />
      ) : (
        <div className="w-[200px] xl:w-[230px] h-[137px] p-3 xl:p-5 space-y-5 rounded-lg shadow flex flex-col bg-white m-2">
          <div className="flex space-x-3 items-center">
            <span className="bg-[#2CA466] rounded-full p-2 flex items-center justify-center">
              {title === "wallets" && <Wallet />}
              {title === "loans" && <Wallet />}
              {title === "VAS" && <Vas />}
              {title === "Investments" && <Investment />}
              {title === "Cards" && <Cards />}
              {title === "Buy Now Pay later" && <BNPL />}
            </span>
            <h2 className="text-[#2CA466] capitalize font-semibold text-xl">
              {title}
            </h2>
          </div>
          <div className="flex items-center space-x-5">
            <p className="text-2xl font-semibold">{data}</p>
            <span className="flex space-x-1">
              <Arrow />
              <small className="text-[#2CA466] capitalize font-semibold text-xs">
                2.3%
              </small>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Card);
