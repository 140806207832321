import Notify from "../icons/Notify";
import NotifyBell from "../icons/NotifyBell";
import {
  BNPL,
  BNPL2,
  Card,
  Card2,
  Dashboard,
  Dashboard2,
  Investment,
  Investment2,
  Loans,
  Loans2,
  Report,
  Report2,
  User,
  User2,
  Vas,
  Vas2,
  Wallet,
  Wallet2,
} from "../icons/Sidebar";

export const data = [
  {
    id: 1,
    title: "Overview",
    category: [
      {
        id: 1,
        title: "Dashboard",
        icon: <Dashboard />,
        icon2: <Dashboard2 />,
        link: "/dashboard",
      },
      {
        id: 23,
        title: "Manage",
        icon: <User />,
        icon2: <User2 />,
        link: "",
        category: [
          {
            id: 1,
            title: "Admin",
            link: "/admin",
          },
          { id: 2, title: "Customers", link: "/customers" },
          { id: 3, title: "KYC", link: "/kyc" },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Management",
    category: [
      {
        id: 1,
        title: "Wallet",
        icon: <Wallet />,
        icon2: <Wallet2 />,
        link: "/wallet",
      },
      {
        id: 2,
        title: "Loans",
        icon: <Loans />,
        icon2: <Loans2 />,
        link: "",
        category: [
          { id: 1, title: "Loans", link: "/loans" },
          {
            id: 2,
            title: "Health Loans",
            link: "/loans/health",
          },
        ],
      },
      {
        id: 3,
        title: "Investments",
        icon: <Investment />,
        icon2: <Investment2 />,
        link: "/investments",
      },
      {
        id: 4,
        title: "VAS",
        icon: <Vas />,
        icon2: <Vas2 />,
        link: "/Vas",
      },
      {
        id: 5,
        title: "BNPL",
        icon: <BNPL />,
        icon2: <BNPL2 />,
        link: "/BNPL",
      },
      {
        id: 6,
        title: "Cards",
        icon: <Card />,
        icon2: <Card2 />,
        link: "/cards",
      },
      {
        id: 7,
        title: "Support",
        icon: <Report />,
        icon2: <Report2 />,
        link: "/support",
        role: "user",
      },
      {
        id: 8,
        title: "Report",
        icon: <Report />,
        icon2: <Report2 />,
        link: "/report",
        role: "user",
      },
      {
        id: 9,
        title: "FAQ",
        icon: <Report />,
        icon2: <Report2 />,
        link: "/faq",
        role: "user",
      },
      {
        id: 9,
        title: "Notification",
        icon: <NotifyBell />,
        icon2: <Notify />,
        link: "/notification",
        role: "user",
      },
    ],
  },
];
export const data2 = [
  {
    id: 1,
    title: "Overview",
    category: [
      {
        id: 1,
        title: "Dashboard",
        icon: <Dashboard />,
        icon2: <Dashboard2 />,
        link: "/dashboard",
      },
      {
        id: 23,
        title: "Manage",
        icon: <User />,
        icon2: <User2 />,
        link: "",
        category: [
          {
            id: 1,
            title: "Admin",
            link: "/admin",
          },
          { id: 2, title: "Customers", link: "/customers" },
          { id: 3, title: "KYC", link: "/kyc" },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Management",
    category: [
      {
        id: 1,
        title: "Wallet",
        icon: <Wallet />,
        icon2: <Wallet2 />,
        link: "/wallet",
      },
      {
        id: 2,
        title: "Loans",
        icon: <Loans />,
        icon2: <Loans2 />,
        link: "",
        category: [
          { id: 1, title: "Loans", link: "/loans" },
          {
            id: 2,
            title: "Health Loans",
            link: "/loans/health",
          },
        ],
      },
      {
        id: 3,
        title: "Investments",
        icon: <Investment />,
        icon2: <Investment2 />,
        link: "/investments",
      },
      {
        id: 4,
        title: "VAS",
        icon: <Vas />,
        icon2: <Vas2 />,
        link: "/Vas",
      },
      {
        id: 5,
        title: "BNPL",
        icon: <BNPL />,
        icon2: <BNPL2 />,
        link: "/BNPL",
      },
      {
        id: 6,
        title: "Cards",
        icon: <Card />,
        icon2: <Card2 />,
        link: "/cards",
      },
      {
        id: 7,
        title: "Support",
        icon: <Report />,
        icon2: <Report2 />,
        link: "/support",
        role: "user",
      },
      {
        id: 8,
        title: "Report",
        icon: <Report />,
        icon2: <Report2 />,
        link: "/report",
        role: "user",
      },
      {
        id: 9,
        title: "FAQ",
        icon: <Report />,
        icon2: <Report2 />,
        link: "/faq",
        role: "user",
      },
    ],
  },
];

export const selectModuleData = [
  {
    id: 1,
    value: "Loan",
  },
  {
    id: 2,
    value: "Investment",
  },
  {
    id: 3,
    value: "Vas",
  },
  {
    id: 4,
    value: "BuyNow",
  },
  {
    id: 5,
    value: "Wallet",
  },
];
export const selectTenorData = [
  {
    id: 1,
    value: "Daily",
  },
  {
    id: 2,
    value: "Weekly",
  },
  {
    id: 3,
    value: "Monthly",
  },
  {
    id: 4,
    value: "Annually",
  },
];
export const selectVasStatusData = [
  {
    id: 1,
    value: "Pending",
  },
  {
    id: 2,
    value: "Processed",
  },
];
export const selectStatusData = [
  {
    id: 1,
    value: "Pending",
  },
  {
    id: 2,
    value: "Approved",
  },
];
export const selectInvestmentTypeData = [
  {
    id: 1,
    value: "Target Savings",
  },
  {
    id: 2,
    value: "Kiddies Swag",
  },
  {
    id: 3,
    value: "Fast Credit Investment Note",
  },
];
export const selectInvestmentDurationData = [
  {
    id: 1,
    value: "3 Months",
  },
  {
    id: 2,
    value: "6 Months",
  },
  {
    id: 3,
    value: "12 Months",
  },
  {
    id: 4,
    value: "18 Months",
  },
];
export const selectVasTypeData = [
  {
    id: 1,
    value: "Airtime",
  },
  {
    id: 2,
    value: "Data",
  },
  {
    id: 3,
    value: "Bill Payment",
  },
];
export const selectRoleData = [
  {
    id: 1,
    value: "Super Admin",
  },
  {
    id: 2,
    value: "Admin",
  },
  {
    id: 3,
    value: "User",
  },
];

export const info: any = [
  {
    id: 1,
    name: "tosin",
    body: [
      {
        title: "nnsnsk est esse",
        body: " nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "qui est esse",
        body: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "qui est =--esse",
        body: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
    ],
  },
  {
    id: 133,
    name: "david",

    body: [
      {
        title: "quiest esse",
        body: "esti neque nisi nulla",
        phone: "019125",
        website: "anasta",
        street: "Hoeger Mall",
        suite: "Apt. [www]692",
        city: "Soutsdwc,wh Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "est esse",
        body: "est rerum tes voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "esse",
        body: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate  qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
    ],
  },
  {
    id: 121,
    name: "emma",

    body: [
      {
        title: "t esse",
        body: "est rereque nisi nulla",
        phone: "010-693 x09125",
        website: "anastanet",
        street: "Hoe Mall",
        suite: "A692",
        city: " Elvis",
        zipcode: "53957",
      },
      {
        title: "quie",
        body: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptat",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "qui  esse",
        body: "nditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010- x09125",
        website: ".net",
        street: " Mall",
        suite: "Apt. 692",
        city: "South",
        zipcode: "53-4257",
      },
    ],
  },
  {
    id: 12,
    name: "tosin",
    body: [
      {
        title: "kiddo est esse",
        body: " nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "qui est esse",
        body: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "qui est esse",
        body: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
    ],
  },
  {
    id: 33,
    name: "david ouaa",

    body: [
      {
        title: "tosin esse",
        body: "esti neque nisi nulla",
        phone: "019125",
        website: "anasta",
        street: "Hoeger Mall",
        suite: "Apt. [www]692",
        city: "Soutsdwc,wh Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "est esse",
        body: "est rerum tes voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "esse",
        body: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate  qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
    ],
  },
  {
    id: 133,
    name: "david",

    body: [
      {
        title: "marry esse",
        body: "marry neque nisi nulla",
        phone: "019125",
        website: "anasta",
        street: "Hoeger Mall",
        suite: "Apt. [www]692",
        city: "Soutsdwc,wh Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "est esse",
        body: "est rerum tes voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
      {
        title: "esse",
        body: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate  qui neque nisi nulla",
        phone: "010-692-6593 x09125",
        website: "anastasia.net",
        street: "Hoeger Mall",
        suite: "Apt. 692",
        city: "South Elvis",
        zipcode: "53919-4257",
      },
    ],
  },
];
