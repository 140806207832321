import { memo, useMemo, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { BackIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import { usePushNotification } from "../hooks/usePushNotification";

export const Notify = memo(({ data }: any) => {
  const { handleClick, setEdit, setEditData, setOpen } = useStateContext();
  const [info2, setInfo] = useState(data);

  const back = () => {
    setEditData(null);
    handleClick("");
    setEdit(false);
    setOpen(false);
  };

  const {
    inputFile,
    handleChange,
    result,
    selected,
    setSelected,
    submitEdit,
    loading,
    submit,
  } = usePushNotification(info2);
  useMemo(() => {
    setInfo(data);
  }, [data]);
  return (
    <>
      <div
        className="flex items-center space-x-4 font-semibold cursor-pointer my-10"
        onClick={back}
      >
        <BackIcon />
        <p className="text-[#1C1D21]">Back to Notification</p>
      </div>
      <section className="flex flex-col space-y-7 items-center justify-center">
        <div className="flex flex-col space-y-3">
          <label className="capitalize">title</label>
          <input
            style={{
              borderBottom: inputFile.title ? "2px solid #029247" : "none",
              background: inputFile.title ? "#F2FCF5" : "#FFFFFF",
            }}
            value={inputFile.title}
            className="bg-[#FFFFFF] p-4 rounded outline-none border-none w-[500px]"
            onChange={handleChange}
            name="title"
            placeholder="title..."
          />
        </div>
        <div className="flex flex-col space-y-3">
          <label className="capitalize">Select Category</label>
          <select
            style={{
              borderBottom: inputFile.category ? "2px solid #029247" : "none",
              background: inputFile.category ? "#F2FCF5" : "#FFFFFF",
            }}
            className="bg-[#FFFFFF] p-4 rounded outline-none border-none w-[500px]"
            value={inputFile.category}
            onChange={handleChange}
            name="category"
          >
            <option value="">choose category</option>
            <option value="0">General</option>
            <option value="1">Loans</option>
            <option value="2">Investments</option>
            <option value="4">Work Anniversary</option>
            <option value="3">Birthday</option>
          </select>
        </div>
        {inputFile.category && inputFile.category !== "0" && (
          <div className="flex flex-col space-y-3">
            <label className="capitalize">Select Customer</label>

            <MultiSelect
              options={result}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
              className="bg-[#FFFFFF] p-4 rounded outline-none border-none w-[500px]"
            />
          </div>
        )}

        <div className="flex flex-col space-y-3">
          <label className="capitalize">Message</label>
          <textarea
            style={{
              borderBottom: inputFile.message ? "2px solid #029247" : "none",
              background: inputFile.message ? "#F2FCF5" : "#FFFFFF",
            }}
            value={inputFile.message}
            className="bg-[#FFFFFF] p-4 rounded outline-none border-none h-[200px] w-full  sm:w-[500px] resize-none"
            onChange={handleChange}
            name="message"
            placeholder="Message..."
          />
        </div>

        {info2?.title ? (
          <div className="flex items-center justify-center">
            <button
              style={{
                background:
                  inputFile.title && inputFile.message ? "#029247" : "#BABABB",
              }}
              onClick={submitEdit}
              className="my-5 sm:my-0 bg-[#029247] rounded-full w-max sm:w-[327px] py-4 px-2 flex items-center justify-center text-white font-semibold text-sm "
              disabled={inputFile.title && inputFile.message ? false : true}
              type="button"
            >
              {loading ? "Submitting..." : "Edit Message"}
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <button
              style={{
                background:
                  inputFile.title && inputFile.message ? "#029247" : "#BABABB",
              }}
              onClick={submit}
              className="my-5 sm:my-0 bg-[#029247] rounded-full w-max sm:w-[327px] py-4 px-2 flex items-center justify-center text-white font-semibold text-sm "
              disabled={inputFile.title && inputFile.message ? false : true}
              type="button"
            >
              {loading ? "Submitting..." : "Send Message"}
            </button>
          </div>
        )}
      </section>
    </>
  );
});
