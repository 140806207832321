import { memo } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { LoanDetails2 } from "../typings";
import LoanDet from "./LoanDet";

type Props = {
  data: LoanDetails2 | null;
};

const EditLoanDetails = ({ data }: Props) => {
  const { handleClick } = useStateContext();

  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7  w-[600px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Loan Application Details
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <div className="h-[400px] overflow-scroll w-full">
          <LoanDet data={data} />
        </div>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={handleClick}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button type="submit" className="btn">
            Click to Save
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default memo(EditLoanDetails);
