import { useState, memo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import { useStateContext } from "../../context/context";

interface Body {
  title: string;
  body: string;
  phone: string;
  website: string;
  street: string;
  suite: string;
  city: string;
  zipcode: string;
}

interface Data {
  Username?: string;
  Active?: string;
  userid?: string;
  userId?: string | number;
  id?: number;
  rrr?: string;
  body?: Body[];
  transactionId?: string;
}

interface Props {
  title: string | JSX.Element;
  subtitle: string[];
  data: Data;
}

const Dropdown = ({ title, subtitle, data }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const userid: string = useAppSelector((state) => state.user.user);
  const { setEdit, setEditData, setTicketId, setTitle, setOpen, handleClick } =
    useStateContext();

  const handleBtn = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item: string) => {
    setAnchorEl(null);

    //  setTableData(data);
    if (item === "view details") {
      setEdit(true);
      setEditData(data);
    }
    if (item === "enable user") {
      setOpen(true);
      setTitle("enable user");
      setEditData(data);
    }
    if (item === "disable user") {
      setTitle("disable user");
      setOpen(true);
      setEditData(data);
    }
    if (item === "view wallet details") {
      setEdit(true);
      setEditData(data);
    }
    if (item === "view loan details") {
      setEdit(true);
      setEditData(data);
    }
    if (item === "add FAQ") {
      setOpen(true);
    }
    if (item === "edit FAQ") {
      setEdit(true);
      setEditData(data);
    }
    if (item === "view investment details") {
      setEdit(true);
      setEditData(data);
    }
    if (item === "view transactions") {
      setEdit(true);
      setEditData(data);
    }
    if (item === "validate transaction") {
      setOpen(true);
      setEditData(data);
      setTitle("validate transaction");
    }
    if (item === "complete transaction") {
      setOpen(true);
      setEditData(data);
      setTitle("complete transaction");
    }
    if (item === "view issue") {
      setEdit(true);
      setEditData(data);
      setTicketId(data.id);
    }
    if (item === "edit notification") {
      setEdit(true);
      setEditData(data);
    }
    if (item === "delete notification") {
      setEditData(data);
      handleClick("deleteNotification");
    }
    if (item === "view") {
      setEdit(true);
      setEditData(data);
      setTicketId(data.id);
    }
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleBtn}
        className="!bg-transparent !text-xs !text-black"
        endIcon={<KeyboardArrowDownIcon />}
      >
        {title}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {subtitle.map((item) => (
          <MenuItem
            key={item}
            className="capitalize text-xs lg:text-base"
            onClick={() => handleClose(item)}
            disableRipple
          >
            {item}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default memo(Dropdown);

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
