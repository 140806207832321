import { memo } from "react";

type Data = {
  value?: string;
  id: number;
  name?: string;
};
type Props = {
  handleChange: (e: any) => void;
  label: string;
  data: Data[];
  name: string;
};

const SelectForm = ({ label, data, name, handleChange }: Props) => {
  const pathname = window.location.pathname.replace("/", "");
  return (
    <div className="flex flex-col">
      <label htmlFor={label} className="font-semibold capitalize ">
        {label}
      </label>
      <select
        name={name}
        onChange={handleChange}
        className="p-3 bg-[#F6F6F6] w-full outline-none border-none"
        placeholder="select from"
      >
        <option value=""></option>
        {data.map((item) => (
          <option
            className="capitalize"
            key={item.id}
            value={pathname === "report" ? item.value : item.id}
          >
            {item.name ? item.name : item.value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default memo(SelectForm);
