import { memo, useState } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { deleteApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {};

const DeleteNotification = (props: Props) => {
  const { handleClick, setEditData, editData } = useStateContext();
  const [loading, setLoading] = useState(false);

  const clearData = () => {
    setEditData(null);
    handleClick();
  };

  const submit = (e: any) => {
    setLoading(true);
    e.preventDefault();
    deleteApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/admin-notifications/${editData.id}`
    )
      .then((res) => {
        setLoading(false);
        Toast({ title: res.responseMessage, error: false });
        clearData();
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Delete Notification
          </h4>
          <p onClick={clearData} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          Are you sure you want to delete this notification?
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={clearData}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button onClick={submit} type="submit" className="btn bg-[#FF3B30]">
            {loading ? "Deleting..." : "Delete"}
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default memo(DeleteNotification);
