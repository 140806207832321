import { memo, useState, useEffect } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { CustomerData, Customer } from "../typings";
import { addThousandSeparator } from "../utils";
import { getApi } from "../utils/services";

type Props = {
  data: Customer;
};

const CustomerDetail = ({ data }: Props) => {
  const { handleClick } = useStateContext();
  const [customerdata, setCustomerData] = useState<CustomerData | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const fetchPosts = async () => {
      getApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/customers/${data?.id}`
      )
        .then((res: any) => {
          setCustomerData(res.responseData);
          setLoading(false);
        })
        .catch((err: any) => {
          console.log("err", err);
          setLoading(false);
        });
    };

    fetchPosts();
  }, [data?.id]);
  return (
    <ModalCard>
      {loading ? (
        <div className=" flex w-full items-center justify-center ">
          <img
            className="h-16 w-16"
            src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif"
            alt=""
          />
        </div>
      ) : (
        <form className="flex justify-center  flex-col space-y-7 bg-white rounded-xl p-5 md:p-12  w-[90%] lg:w-fit md:w-full">
          <div className="flex items-center">
            <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
              Customer Details
            </h4>
            <p onClick={handleClick} className="cursor-pointer ml-auto">
              <CloseIcon />
            </p>
          </div>
          <div className="mx-auto lg:justify-center lg:w-max flex flex-col lg:flex-row lg:space-x-20 w-full overflow-y-scroll h-[300px] lg:h-[430px] ">
            <section className="my-3 flex flex-col space-y-4 divide-y-2">
              <div
                className=" flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">First Name</h2>
                <p className="text-[#1F2024]">{data?.firstName} </p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Last Name</h2>
                <p className="text-[#1F2024]">{data?.lastName}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">middle Name</h2>
                <p className="text-[#1F2024]">{data?.middleName}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Email Address</h2>
                <p className="text-[#1F2024]">{data?.email}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Phone Number</h2>
                <p className="text-[#1F2024]">
                  {data?.phoneNumber?.replace("234", "0") ||
                    customerdata?.phone?.replace("234", "0")}
                </p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Date of Birth</h2>
                <p className="text-[#1F2024]">{data?.dob}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Residence Adress</h2>

                <p className="text-[#1F2024]">
                  {customerdata?.residencialAddress}
                </p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">State of Residence</h2>

                <p className="text-[#1F2024]">
                  {customerdata?.stateOfResidence}
                </p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">LGA of Residence</h2>

                <p className="text-[#1F2024]">{customerdata?.lgaOfOrigin}</p>
              </div>
              <div
                className="flex items-center space-x-20 
                lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Gender</h2>
                <p className="text-[#1F2024]">{data?.gender}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Marital Status</h2>

                <p className="text-[#1F2024]">{customerdata?.maritalStatus}</p>
              </div>
            </section>
            <section className="my-3 flex flex-col space-y-4 divide-y-2">
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 py-2 "
              >
                <h2 className="text-[#98989A]">Employer name</h2>
                <p className="text-[#1F2024]">{customerdata?.employerName} </p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Employee Status</h2>

                <p className="text-[#1F2024]">{customerdata?.employeeStatus}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Job Tile</h2>

                <p className="text-[#1F2024]">{customerdata?.jobTitle}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Salary Amount</h2>

                <p className="text-[#1F2024]">
                  {addThousandSeparator(customerdata?.salary ?? 0)}
                </p>
              </div>

              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Hire Date</h2>

                <p className="text-[#1F2024]">
                  {customerdata?.hireDate &&
                    new Date(customerdata?.hireDate).toDateString()}
                </p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Staff Id</h2>
                <p className="text-[#1F2024]">{data?.staffId}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Bank Acc</h2>

                <p className="text-[#1F2024]">{customerdata?.bankAccount}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">BVN</h2>

                <p className="text-[#1F2024]">{data?.bvn}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Wallet Account Number</h2>

                <p className="text-[#1F2024]">
                  {data?.accountNumber || customerdata?.accountNumber}
                </p>
              </div>

              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Bank Name</h2>

                <p className="text-[#1F2024]">{customerdata?.bankName}</p>
              </div>
              <div
                className="flex items-center space-x-20 
            lg:space-x-5 justify-between py-2 "
              >
                <h2 className="text-[#98989A]">Verification Status</h2>
                <p className="text-[#1F2024]">
                  {data?.verified !== false ? "Verified" : "Not Verified"}
                </p>
              </div>
            </section>
          </div>
          <div className="flex items-center justify-center flex-col space-y-4 md:flex-row md:space-x-6">
            <button
              type="submit"
              className="btn text-[#BD0A00] bg-transparent border-[#BD0A00] border-2 mt-4"
            >
              Delete Profile
            </button>
            <button type="submit" className="btn">
              Click to Save
            </button>
          </div>
        </form>
      )}
    </ModalCard>
  );
};

export default memo(CustomerDetail);
