import { memo } from "react";
import {
  AuthSetting,
  BankStateMent,
  DeleteAuth,
  DeleteCustomer,
  ForceSignOut,
  ProcessLoan,
  ResetPassword,
  ViewStatement,
} from ".";
import {
  AccountIcon,
  AuthIcon,
  DeleteAuthIcon,
  DeleteUserIcon,
  PasswordCheckIcon,
  SettingsIcon,
} from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import { Customer } from "../typings";

type Props = {
  data: Customer;
};

const AccountManagement = ({ data }: Props) => {
  const { handleClick, open, title } = useStateContext();

  return (
    <>
      {open && title === "upload" ? <BankStateMent data={data} /> : null}
      {open && title === "deleteUser" ? <DeleteCustomer /> : null}
      {open && title === "resetpassword" ? <ResetPassword /> : null}
      {open && title === "authsetting" ? <AuthSetting /> : null}
      {open && title === "signout" ? <ForceSignOut data={data} /> : null}
      {open && title === "deleteAuth" ? <DeleteAuth /> : null}
      {open && title === "viewStatement" ? <ViewStatement /> : null}
      {open && title === "processloan" ? (
        <ProcessLoan userid={data.userId} />
      ) : null}
      <BigCard>
        <section className="flex items-center justify-between">
          <h3 className="text-[#1C1D21] font-bold text-xl my-5">
            Account Management
          </h3>
        </section>
        <section className=" flex flex-col space-y-7 md:flex-row md:divide-x-4 md:space-x-4 p-3  cursor-pointer">
          <article className="flex flex-col space-y-8">
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("upload")}
            >
              <AccountIcon />
              <p className="text-[#4C4D50]">Upload Bank Statement</p>
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("authsetting")}
            >
              <AuthIcon />
              <p className="text-[#4C4D50]">Create Authentication Method</p>
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("viewStatement")}
            >
              <AccountIcon />
              <p className="text-[#4C4D50]">View Statement</p>
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("processloan")}
            >
              <AccountIcon />
              <p className="text-[#4C4D50]">Process Loan</p>
            </div>
          </article>
          <article className="flex flex-col space-y-8 md:pl-4 md:!-mt-2 ">
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("resetpassword")}
            >
              <PasswordCheckIcon />
              <p className="text-[#4C4D50]">Reset Password </p>
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("deleteAuth")}
            >
              <DeleteAuthIcon />
              <p className="text-[#4C4D50]">Delete Authentication Method</p>
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("signout")}
            >
              <SettingsIcon />
              <p className="text-[#4C4D50]">Force Sign-out</p>
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("deleteUser")}
            >
              <DeleteUserIcon />
              <p className="text-[#4C4D50]">Delete Account</p>
            </div>
          </article>
        </section>
      </BigCard>
    </>
  );
};

export default memo(AccountManagement);
