import { memo } from "react";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import { ViewKYC, RejectKYC } from "./";
import ViewBNPL from "./ViewBNPL";
import RejectBNPL from "./RejectBNPL";

type Props = {
  data: any;
};

const ApproveBNPL = ({ data }: Props) => {
  const { handleClick, open, title } = useStateContext();
  return (
    <>
      {open && title === "approveBNPL" ? <ViewBNPL data={data} /> : null}
      {open && title === "deleteBNPL" ? <RejectBNPL data={data} /> : null}
      <BigCard>
        <h3 className="text-[#1C1D21] font-bold text-xl my-5">
     Buy Now Pay Later (BNPL) Request
        </h3>

        <div className="flex items-center justify-between flex-row-reverse ">
          <button
            onClick={() => handleClick("deleteBNPL")}
            type="button"
            className="btn text-[#BD0A00] text-xs md:py-3 md:px-6 bg-transparent border-[#BD0A00] border-2 font-semibold"
          >
            Reject Request
          </button>
          <button
            type="button"
            onClick={() => handleClick("approveBNPL")}
            className="btn text-xs md:py-3 md:px-6  "
          >
            Approve Request
          </button>
        </div>
      </BigCard>
    </>
  );
};

export default memo(ApproveBNPL);
