import { memo } from "react";
import {
  AccountIcon,
  AuthIcon,
  PasswordCheckIcon,
  SettingsIcon,
} from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import { LoanDetails2 } from "../typings";
import {
  LoanStatus,
  DeleteLoanRequest,
  EditLoanDetails,
  ApproveLoanRequest,
} from "./";

type Props = {
  data: LoanDetails2 | null;
};

const LoanManagement = ({ data }: Props) => {
  const { handleClick, open, title } = useStateContext();

  return (
    <>
      {open && title === "LoanStatus" ? <LoanStatus data={data} /> : null}
      {open && title === "DeleteLoanRequest" ? <DeleteLoanRequest /> : null}
      {open && title === "EditLoanDetails" ? (
        <EditLoanDetails data={data} />
      ) : null}
      {open && title === "ApproveLoanRequest" ? <ApproveLoanRequest /> : null}
      <BigCard>
        <section className="flex items-center justify-between">
          <h3 className="text-[#1C1D21] font-bold text-xl my-5">Manage Loan</h3>
        </section>
        <section className=" flex divide-x-4 space-x-4 p-3  cursor-pointer">
          <article className="flex flex-col space-y-8">
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("LoanStatus")}
            >
              <AccountIcon />
              <p className="text-[#4C4D50]">Loan Status</p>
            </div>

            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("EditLoanDetails")}
            >
              <PasswordCheckIcon />
              <p className="text-[#4C4D50]">Edit Loan Details </p>
            </div>
          </article>
          <article className="flex flex-col space-y-8 pl-4">
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("DeleteLoanRequest")}
            >
              <SettingsIcon />
              <p className="text-[#4C4D50]">Delete Loan Request</p>
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("ApproveLoanRequest")}
            >
              <AuthIcon />
              <p className="text-[#4C4D50]">Approve Loan Request</p>
            </div>
          </article>
        </section>
      </BigCard>
    </>
  );
};

export default memo(LoanManagement);
