import { useEffect, useState, memo } from "react";
import { VerifiedIcon } from "../assets/icons/ManageUsers";
import SkeletonImage from "../components/skeleton/Image";
import { Customer } from "../typings";
import { getApi } from "../utils/services";

type Props = {
  userid?: string;
  status?: string | null | undefined;
};

const VerifiedCard = ({ userid, status }: Props) => {
  const pathname = window.location.pathname.replace("/", "");
  const [data, setData] = useState<Customer | null | undefined>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getApi(`${process.env.REACT_APP_BASE_URL_ARY}/Admin/customers/${userid}`)
      .then((res: any) => {
        setData(res.responseData);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("err", err);
        setLoading(false);
      });
  }, [userid]);

  return (
    <>
      {loading ? (
        <SkeletonImage />
      ) : (
        <>
          <div
            className={`min-w-[280px] md:w-[500px] h-[153px] rounded-lg  flex p-3 md:p-5 flex-col md:flex-row items-center ${
              data?.verified === false ? " bg-yellow-50" : "bg-[#CCF2D5]"
            }`}
          >
            <section className="flex items-center space-x-4">
              <div
                className={`w-[110px] h-[110px] border-4 flex items-center justify-center rounded-full relative ${
                  data?.verified === false
                    ? "border-yellow-700 "
                    : "border-green-700 "
                }`}
              >
                {data?.verified === false ? (
                  <div className="absolute top-0 -left-2">
                    <VerifiedIcon />
                  </div>
                ) : null}
                <img
                  src={data?.avatar}
                  className="h-24 w-24 rounded-full object-cover"
                  alt="user_profile"
                  loading="lazy"
                />
              </div>
              <div className="flex flex-col">
                <span className="flex items-center space-x-3">
                  <h2
                    className={` font-bold text-xl ${
                      data?.verified === false
                        ? "text-yellow-700"
                        : "text-green-700"
                    }`}
                  >
                    {data?.firstName}
                  </h2>
                </span>

                <p
                  className={` ${
                    data?.verified === false
                      ? "text-yellow-700"
                      : "text-green-700"
                  }`}
                >
                  {data?.lastName}
                </p>
              </div>
            </section>
            {data?.verified === false ? (
              <div className="ml-auto flex flex-col space-y-10">
                <p className="text-yellow-700">Account Not Verified</p>
                {pathname === "loans" ? (
                  <div className="flex items-center justify-center space-x-6 -mb-20 font-semibold">
                    {status && status.toLowerCase() === "apr" ? (
                      <span className=" btn bg-[#029247]">Approved</span>
                    ) : (
                      <span className=" btn  bg-[#376BDB]">Pending</span>
                    )}
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="ml-auto flex flex-col space-y-10">
                <p className="text-[#029247]">Account Verified</p>
                {pathname === "loans" ? (
                  <div className="flex items-center justify-center space-x-6 -mb-20 font-semibold">
                    {status && status.toLowerCase() === "apr" ? (
                      <span className=" btn bg-[#029247]">Approved</span>
                    ) : (
                      <span className=" btn  bg-[#376BDB]">Pending</span>
                    )}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default memo(VerifiedCard);
