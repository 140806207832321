import { memo } from "react";
import { BackIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import VerifiedCard from "../shared/VerifiedCard";
import { VasDetail as detail } from "../typings";
import { VasManagement, VasDetail, RecentTransaction } from "./";

type Props = {
  data: detail;
};

const VasDetails = ({ data }: Props) => {
  const { setEdit, setEditData, openMenu } = useStateContext();

  const back = () => {
    setEdit(false);
    setEditData(null);
  };
  return (
    <>
      <div
        className="flex items-center space-x-4 font-semibold cursor-pointer my-10"
        onClick={back}
      >
        <BackIcon />
        <p className="text-[#1C1D21]">Back to VAS</p>
      </div>
      <div className="flex w-full  flex-col space-y-10">
        <section className="flex">
          <div className="">
            <VerifiedCard status={data?.status} userid={data?.userId} />
          </div>
        </section>
        <section
          className={`flex ${
            openMenu
              ? " flex-col lg:flex-row lg:!space-x-20"
              : "flex-col xl:flex-row xl:!space-x-20"
          }`}
        >
          <div>
            <VasDetail data={data} />
          </div>
          <div className="flex flex-col space-y-10 !-ml-2 md:ml-0">
            <VasManagement data={data} />
            <RecentTransaction userid={data?.userId} />
          </div>
        </section>
      </div>
    </>
  );
};

export default memo(VasDetails);
