import { memo, useEffect, useState } from "react";
import classNames from "classnames";
import BigCard from "../shared/BigCard";
import { addThousandSeparator } from "../utils";
import { getApi } from "../utils/services";

type Props = {
  userid: string;
};

const RecentTransaction = ({ userid }: Props) => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      await getApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/transactions/${userid}?Days=60&Type=1&Status=1`
      )
        .then((res) => {
          setLoading(false);
          setPosts(res.responseData.items);
        })
        .catch((err: any) => {
          console.log("err", err);
          setLoading(false);
        });
    };

    fetchPosts();
  }, [userid]);

  return (
    <BigCard>
      <h3 className="text-[#1C1D21] font-bold text-xl my-5">
        Recent Transactions{" "}
      </h3>
      <section className="w-full h-[200px] overflow-scroll flex flex-col ">
        {loading ? (
          "Loading..."
        ) : posts?.length > 0 ? (
          posts.map((res: any, index) => (
            <div key={index} className="flex items-center justify-between">
              <div className="flex flex-col m-4">
                <h3 className="text-[#1F2024] font-semibold capitalize">
                  {res?.type}{" "}
                  {res?.narration &&
                  res?.narration?.charAt(0)?.toLowerCase() === "d"
                    ? "DATA"
                    : null}
                  {res?.narration &&
                  res?.narration?.charAt(0)?.toLowerCase() === "a"
                    ? "AIRTIME"
                    : null}
                  {res?.narration &&
                  res?.narration?.charAt(0)?.toLowerCase() === "e"
                    ? "UTILITY"
                    : null}
                  <small className="text-gray-500 ml-1 text-[10px]">
                    ({res?.tranStatus})
                  </small>
                </h3>
                <p className="text-[10px]">{res?.narration}</p>
                <small className="text-[#98989A] text-xs">
                  {res?.dateCreated &&
                    new Date(res?.dateCreated)?.toDateString()}
                </small>
              </div>
              <h3
                className={classNames(
                  "font-semibold capitalize flex items-center",
                  {
                    "text-[#34C759]":
                      (res?.tranType1 && res?.tranType1 === "TRF IN") ||
                      res?.sendStatus?.toLowerCase() === "successful",
                    "text-[#FF3B30]":
                      res?.tranType1 && res?.tranType1 !== "TRF IN",
                  }
                )}
              >
                <p
                  className={classNames("font-semibold capitalize mr-1 ", {
                    "text-[#34C759]":
                      (res?.tranType1 && res?.tranType1 === "TRF IN") ||
                      res?.sendStatus?.toLowerCase() === "successful",
                    "text-[#FF3B30]":
                      res?.tranType1 && res?.tranType1 !== "TRF IN",
                  })}
                >
                  &#8358;
                </p>{" "}
                {addThousandSeparator(
                  (Number(res?.amount)?.toFixed(2) as string) ?? 0
                )}
              </h3>
            </div>
          ))
        ) : (
          <p className="text-[#98989A] text-xl capitalize">no result</p>
        )}
      </section>
    </BigCard>
  );
};

export default memo(RecentTransaction);
