import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTypewriter } from "react-simple-typewriter";
import { SearchField } from ".";
import { MenuIcon } from "../assets/icons/Dashboard";
import NotifyBell from "../assets/icons/NotifyBell";
import { useStateContext } from "../context/context";
import { useAppSelector } from "../redux/hook";
import { User } from "../typings";
import Notification from "./Notification";

// type Props = {};

export default function Header() {
  const [inputText, setInputText] = useState("");
  const userid: User = useAppSelector((state) => state.user.user);
  const { newMessage, setOpenMenu, handleClick, title, open } =
    useStateContext();

  const navigate = useNavigate();

  const menu = () => setOpenMenu((prev: boolean) => !prev);

  const [text] = useTypewriter({
    words: [`${userid?.firstName}`],
    loop: true,
    delaySpeed: 2000,
  });

  useEffect(() => {
    userid === null && navigate("/");
  }, [userid, navigate]);

  return (
    <header className="h-[80px] w-full bg-[#FFFFFF] flex items-center justify-between px-8">
      {open && title === "support" ? <Notification /> : null}
      <div className="md:w-[220px] lg:w-[200px] xl:w-[280px] ">
        <h1 className="font-bold text-sm xl:text-xl capitalize">
          welcome, {text}
          <h3 className="bg-green-100 py-1 text-xs capitalize font-semibold px-2 text-[#027A3B] rounded-full w-max">
            {userid && userid?.role}👮
          </h3>
        </h1>
      </div>

      <div className="hidden md:flex items-center space-x-2 xl:space-x-4 ">
        <SearchField
          inputText={inputText}
          setInputText={setInputText}
          disabled={false}
        />
        <div
          onClick={() => handleClick("support")}
          className={`cursor-pointer bg-[#F6F6F6] rounded-full p-2 xl:p-3 flex items-center justify-center ${
            newMessage && "animate-ping bg-green-500"
          }`}
        >
          <NotifyBell />
        </div>
      </div>
      <div
        className="flex items-center space-x-2 cursor-pointer"
        onClick={menu}
      >
        <MenuIcon />
        <p className="text-[#2CA466] font-semibold">Menu</p>
      </div>
    </header>
  );
}
