type Props = {};

export const MessageIcon = (props: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V15.5C22.75 19.15 20.65 21.25 17 21.25ZM7 4.25C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H17C19.86 19.75 21.25 18.36 21.25 15.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7Z"
        fill="#BABABB"
      />
      <path
        d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z"
        fill="#BABABB"
      />
    </svg>
  );
};
export const GalleryIcon = (props: Props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 30.3337H12C4.76002 30.3337 1.66669 27.2403 1.66669 20.0003V12.0003C1.66669 4.76033 4.76002 1.66699 12 1.66699H20C27.24 1.66699 30.3334 4.76033 30.3334 12.0003V20.0003C30.3334 27.2403 27.24 30.3337 20 30.3337ZM12 3.66699C5.85335 3.66699 3.66669 5.85366 3.66669 12.0003V20.0003C3.66669 26.147 5.85335 28.3337 12 28.3337H20C26.1467 28.3337 28.3334 26.147 28.3334 20.0003V12.0003C28.3334 5.85366 26.1467 3.66699 20 3.66699H12Z"
        fill="#292D32"
      />
      <path
        d="M12 14.3333C9.97331 14.3333 8.33331 12.6933 8.33331 10.6667C8.33331 8.64 9.97331 7 12 7C14.0266 7 15.6666 8.64 15.6666 10.6667C15.6666 12.6933 14.0266 14.3333 12 14.3333ZM12 9C11.08 9 10.3333 9.74667 10.3333 10.6667C10.3333 11.5867 11.08 12.3333 12 12.3333C12.92 12.3333 13.6666 11.5867 13.6666 10.6667C13.6666 9.74667 12.92 9 12 9Z"
        fill="#292D32"
      />
      <path
        d="M3.55998 26.2671C3.23998 26.2671 2.91998 26.1071 2.73331 25.8271C2.42665 25.3737 2.54665 24.7471 3.01331 24.4404L9.58665 20.0271C11.0266 19.0537 13.0133 19.1737 14.32 20.2804L14.76 20.6671C15.4266 21.2404 16.56 21.2404 17.2133 20.6671L22.76 15.9071C24.1733 14.6937 26.4 14.6937 27.8266 15.9071L30 17.7737C30.4133 18.1337 30.4666 18.7604 30.1066 19.1871C29.7466 19.6004 29.12 19.6537 28.6933 19.2937L26.52 17.4271C25.8533 16.8537 24.72 16.8537 24.0533 17.4271L18.5066 22.1871C17.0933 23.4004 14.8666 23.4004 13.44 22.1871L13 21.8004C12.3866 21.2804 11.3733 21.2271 10.6933 21.6937L4.11998 26.1071C3.94665 26.2137 3.74665 26.2671 3.55998 26.2671Z"
        fill="#292D32"
      />
    </svg>
  );
};
export const SendIcon = (props: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.07 8.50965L9.51002 4.22965C3.76002 1.34965 1.40002 3.70965 4.28002 9.45965L5.15002 11.1996C5.40002 11.7096 5.40002 12.2996 5.15002 12.8096L4.28002 14.5396C1.40002 20.2896 3.75002 22.6496 9.51002 19.7696L18.07 15.4896C21.91 13.5696 21.91 10.4296 18.07 8.50965ZM14.84 12.7496H9.44002C9.03002 12.7496 8.69002 12.4096 8.69002 11.9996C8.69002 11.5896 9.03002 11.2496 9.44002 11.2496H14.84C15.25 11.2496 15.59 11.5896 15.59 11.9996C15.59 12.4096 15.25 12.7496 14.84 12.7496Z"
        fill="#029247"
      />
    </svg>
  );
};
