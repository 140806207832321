export const BNPL = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.19 17.75H7.53999C6.54999 17.75 5.59999 17.33 4.92999 16.6C4.25999 15.87 3.92 14.89 4 13.9L4.83 3.94C4.86 3.63 4.74999 3.33001 4.53999 3.10001C4.32999 2.87001 4.04 2.75 3.73 2.75H2C1.59 2.75 1.25 2.41 1.25 2C1.25 1.59 1.59 1.25 2 1.25H3.74001C4.47001 1.25 5.15999 1.56 5.64999 2.09C5.91999 2.39 6.12 2.74 6.23 3.13H18.72C19.73 3.13 20.66 3.53 21.34 4.25C22.01 4.98 22.35 5.93 22.27 6.94L21.73 14.44C21.62 16.27 20.02 17.75 18.19 17.75ZM6.28 4.62L5.5 14.02C5.45 14.6 5.64 15.15 6.03 15.58C6.42 16.01 6.95999 16.24 7.53999 16.24H18.19C19.23 16.24 20.17 15.36 20.25 14.32L20.79 6.82001C20.83 6.23001 20.64 5.67001 20.25 5.26001C19.86 4.84001 19.32 4.60999 18.73 4.60999H6.28V4.62Z"
        fill="#1C1D21"
      />
      <path
        d="M16.25 22.75C15.15 22.75 14.25 21.85 14.25 20.75C14.25 19.65 15.15 18.75 16.25 18.75C17.35 18.75 18.25 19.65 18.25 20.75C18.25 21.85 17.35 22.75 16.25 22.75ZM16.25 20.25C15.97 20.25 15.75 20.47 15.75 20.75C15.75 21.03 15.97 21.25 16.25 21.25C16.53 21.25 16.75 21.03 16.75 20.75C16.75 20.47 16.53 20.25 16.25 20.25Z"
        fill="#1C1D21"
      />
      <path
        d="M8.25 22.75C7.15 22.75 6.25 21.85 6.25 20.75C6.25 19.65 7.15 18.75 8.25 18.75C9.35 18.75 10.25 19.65 10.25 20.75C10.25 21.85 9.35 22.75 8.25 22.75ZM8.25 20.25C7.97 20.25 7.75 20.47 7.75 20.75C7.75 21.03 7.97 21.25 8.25 21.25C8.53 21.25 8.75 21.03 8.75 20.75C8.75 20.47 8.53 20.25 8.25 20.25Z"
        fill="#1C1D21"
      />
      <path
        d="M21 8.75H9C8.59 8.75 8.25 8.41 8.25 8C8.25 7.59 8.59 7.25 9 7.25H21C21.41 7.25 21.75 7.59 21.75 8C21.75 8.41 21.41 8.75 21 8.75Z"
        fill="#1C1D21"
      />
    </svg>
  );
};
export const BNPL2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 22.5C17.2165 22.5 18 21.7165 18 20.75C18 19.7835 17.2165 19 16.25 19C15.2835 19 14.5 19.7835 14.5 20.75C14.5 21.7165 15.2835 22.5 16.25 22.5Z"
        fill="#029247"
      />
      <path
        d="M8.25 22.5C9.2165 22.5 10 21.7165 10 20.75C10 19.7835 9.2165 19 8.25 19C7.2835 19 6.5 19.7835 6.5 20.75C6.5 21.7165 7.2835 22.5 8.25 22.5Z"
        fill="#029247"
      />
      <path
        d="M4.84 3.94L4.64 6.39C4.6 6.86 4.97 7.25 5.44 7.25H20.75C21.17 7.25 21.52 6.93 21.55 6.51C21.68 4.74 20.33 3.3 18.56 3.3H6.27C6.17 2.86 5.97 2.44 5.66 2.09C5.16 1.56 4.46 1.25 3.74 1.25H2C1.59 1.25 1.25 1.59 1.25 2C1.25 2.41 1.59 2.75 2 2.75H3.74C4.05 2.75 4.34 2.88 4.55 3.1C4.76 3.33 4.86 3.63 4.84 3.94Z"
        fill="#029247"
      />
      <path
        d="M20.5101 8.75H5.17005C4.75005 8.75 4.41005 9.07 4.37005 9.48L4.01005 13.83C3.87005 15.54 5.21005 17 6.92005 17H18.0401C19.5401 17 20.8601 15.77 20.9701 14.27L21.3001 9.6C21.3401 9.14 20.9801 8.75 20.5101 8.75Z"
        fill="#029247"
      />
    </svg>
  );
};

export const Card = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 9.25H2C1.59 9.25 1.25 8.91 1.25 8.5C1.25 8.09 1.59 7.75 2 7.75H22C22.41 7.75 22.75 8.09 22.75 8.5C22.75 8.91 22.41 9.25 22 9.25Z"
        fill="#1C1D21"
      />
      <path
        d="M8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25Z"
        fill="#1C1D21"
      />
      <path
        d="M14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
        fill="#1C1D21"
      />
      <path
        d="M17.56 21.25H6.44C2.46 21.25 1.25 20.05 1.25 16.11V7.89C1.25 3.95 2.46 2.75 6.44 2.75H17.55C21.53 2.75 22.74 3.95 22.74 7.89V16.1C22.75 20.05 21.54 21.25 17.56 21.25ZM6.44 4.25C3.3 4.25 2.75 4.79 2.75 7.89V16.1C2.75 19.2 3.3 19.74 6.44 19.74H17.55C20.69 19.74 21.24 19.2 21.24 16.1V7.89C21.24 4.79 20.69 4.25 17.55 4.25H6.44Z"
        fill="#1C1D21"
      />
    </svg>
  );
};

export const Card2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 7.5499C22 8.2099 21.46 8.7499 20.8 8.7499H3.2C2.54 8.7499 2 8.2099 2 7.5499V7.5399C2 5.2499 3.85 3.3999 6.14 3.3999H17.85C20.14 3.3999 22 5.2599 22 7.5499Z"
        fill="#029247"
      />
      <path
        d="M2 11.45V16.46C2 18.75 3.85 20.6 6.14 20.6H17.85C20.14 20.6 22 18.74 22 16.45V11.45C22 10.79 21.46 10.25 20.8 10.25H3.2C2.54 10.25 2 10.79 2 11.45ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25ZM14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
        fill="#029247"
      />
    </svg>
  );
};

export const User = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.15957 11.62C9.12957 11.62 9.10957 11.62 9.07957 11.62C9.02957 11.61 8.95957 11.61 8.89957 11.62C5.99957 11.53 3.80957 9.25 3.80957 6.44C3.80957 3.58 6.13957 1.25 8.99957 1.25C11.8596 1.25 14.1896 3.58 14.1896 6.44C14.1796 9.25 11.9796 11.53 9.18957 11.62C9.17957 11.62 9.16957 11.62 9.15957 11.62ZM8.99957 2.75C6.96957 2.75 5.30957 4.41 5.30957 6.44C5.30957 8.44 6.86957 10.05 8.85957 10.12C8.91957 10.11 9.04957 10.11 9.17957 10.12C11.1396 10.03 12.6796 8.42 12.6896 6.44C12.6896 4.41 11.0296 2.75 8.99957 2.75Z"
        fill="#1C1D21"
      />
      <path
        d="M16.5396 11.75C16.5096 11.75 16.4796 11.75 16.4496 11.74C16.0396 11.78 15.6196 11.49 15.5796 11.08C15.5396 10.67 15.7896 10.3 16.1996 10.25C16.3196 10.24 16.4496 10.24 16.5596 10.24C18.0196 10.16 19.1596 8.96 19.1596 7.49C19.1596 5.97 17.9296 4.74 16.4096 4.74C15.9996 4.75 15.6596 4.41 15.6596 4C15.6596 3.59 15.9996 3.25 16.4096 3.25C18.7496 3.25 20.6596 5.16 20.6596 7.5C20.6596 9.8 18.8596 11.66 16.5696 11.75C16.5596 11.75 16.5496 11.75 16.5396 11.75Z"
        fill="#1C1D21"
      />
      <path
        d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z"
        fill="#1C1D21"
      />
      <path
        d="M18.3397 20.75C17.9897 20.75 17.6797 20.51 17.6097 20.15C17.5297 19.74 17.7897 19.35 18.1897 19.26C18.8197 19.13 19.3997 18.88 19.8497 18.53C20.4197 18.1 20.7297 17.56 20.7297 16.99C20.7297 16.42 20.4197 15.88 19.8597 15.46C19.4197 15.12 18.8697 14.88 18.2197 14.73C17.8197 14.64 17.5597 14.24 17.6497 13.83C17.7397 13.43 18.1397 13.17 18.5497 13.26C19.4097 13.45 20.1597 13.79 20.7697 14.26C21.6997 14.96 22.2297 15.95 22.2297 16.99C22.2297 18.03 21.6897 19.02 20.7597 19.73C20.1397 20.21 19.3597 20.56 18.4997 20.73C18.4397 20.75 18.3897 20.75 18.3397 20.75Z"
        fill="#1C1D21"
      />
    </svg>
  );
};

export const User2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
        fill="#029247"
      />
      <path
        d="M14.08 14.15C11.29 12.29 6.73996 12.29 3.92996 14.15C2.65996 15 1.95996 16.15 1.95996 17.38C1.95996 18.61 2.65996 19.75 3.91996 20.59C5.31996 21.53 7.15996 22 8.99996 22C10.84 22 12.68 21.53 14.08 20.59C15.34 19.74 16.04 18.6 16.04 17.36C16.03 16.13 15.34 14.99 14.08 14.15Z"
        fill="#029247"
      />
      <path
        d="M19.9901 7.33998C20.1501 9.27998 18.7701 10.98 16.8601 11.21C16.8501 11.21 16.8501 11.21 16.8401 11.21H16.8101C16.7501 11.21 16.6901 11.21 16.6401 11.23C15.6701 11.28 14.7801 10.97 14.1101 10.4C15.1401 9.47998 15.7301 8.09998 15.6101 6.59998C15.5401 5.78998 15.2601 5.04998 14.8401 4.41998C15.2201 4.22998 15.6601 4.10998 16.1101 4.06998C18.0701 3.89998 19.8201 5.35998 19.9901 7.33998Z"
        fill="#029247"
      />
      <path
        d="M21.99 16.59C21.91 17.56 21.29 18.4 20.25 18.97C19.25 19.52 17.99 19.78 16.74 19.75C17.46 19.1 17.88 18.29 17.96 17.43C18.06 16.19 17.47 15 16.29 14.05C15.62 13.52 14.84 13.1 13.99 12.79C16.2 12.15 18.98 12.58 20.69 13.96C21.61 14.7 22.08 15.63 21.99 16.59Z"
        fill="#029247"
      />
    </svg>
  );
};

export const Vas = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 22.25C11.1799 22.25 10.3599 21.99 9.6799 21.48L4.46991 17.58C3.49991 16.85 2.91992 15.69 2.91992 14.48V1.76001H21.0799V14.48C21.0799 15.69 20.4999 16.85 19.5299 17.58L14.3199 21.48C13.6399 21.99 12.8199 22.25 11.9999 22.25ZM4.41992 3.25002V14.47C4.41992 15.21 4.7799 15.92 5.3699 16.37L10.5799 20.27C11.4199 20.9 12.5899 20.9 13.4299 20.27L18.6399 16.37C19.2299 15.92 19.5899 15.21 19.5899 14.47V3.25002H4.41992Z"
        fill="#1C1D21"
      />
      <path
        d="M22 3.25H2C1.59 3.25 1.25 2.91 1.25 2.5C1.25 2.09 1.59 1.75 2 1.75H22C22.41 1.75 22.75 2.09 22.75 2.5C22.75 2.91 22.41 3.25 22 3.25Z"
        fill="#1C1D21"
      />
      <path
        d="M16 8.75H8C7.59 8.75 7.25 8.41 7.25 8C7.25 7.59 7.59 7.25 8 7.25H16C16.41 7.25 16.75 7.59 16.75 8C16.75 8.41 16.41 8.75 16 8.75Z"
        fill="#1C1D21"
      />
      <path
        d="M16 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H16C16.41 12.25 16.75 12.59 16.75 13C16.75 13.41 16.41 13.75 16 13.75Z"
        fill="#1C1D21"
      />
    </svg>
  );
};

export const Vas2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 3.25H2C1.59 3.25 1.25 2.91 1.25 2.5C1.25 2.09 1.59 1.75 2 1.75H22C22.41 1.75 22.75 2.09 22.75 2.5C22.75 2.91 22.41 3.25 22 3.25Z"
        fill="#029247"
      />
      <path
        d="M3.66992 2.5V14.47C3.66992 15.45 4.12992 16.38 4.91992 16.97L10.1299 20.87C11.2399 21.7 12.7699 21.7 13.8799 20.87L19.0899 16.97C19.8799 16.38 20.3399 15.45 20.3399 14.47V2.5H3.66992ZM15.9999 13.75H7.99992C7.58992 13.75 7.24992 13.41 7.24992 13C7.24992 12.59 7.58992 12.25 7.99992 12.25H15.9999C16.4099 12.25 16.7499 12.59 16.7499 13C16.7499 13.41 16.4099 13.75 15.9999 13.75ZM15.9999 8.75H7.99992C7.58992 8.75 7.24992 8.41 7.24992 8C7.24992 7.59 7.58992 7.25 7.99992 7.25H15.9999C16.4099 7.25 16.7499 7.59 16.7499 8C16.7499 8.41 16.4099 8.75 15.9999 8.75Z"
        fill="#029247"
      />
    </svg>
  );
};

export const Wallet = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.19994 10.4601C5.00994 10.4601 4.80994 10.3801 4.66994 10.2401C4.45994 10.0201 4.38994 9.70012 4.50994 9.42012L6.36994 4.98012C6.40994 4.89012 6.42994 4.83012 6.45994 4.78012C7.93994 1.37012 9.82994 0.540121 13.1699 1.82012C13.3599 1.89012 13.5099 2.04012 13.5899 2.23012C13.6699 2.42012 13.6699 2.63012 13.5899 2.82012L10.6599 9.62012C10.5399 9.90012 10.2699 10.0701 9.96994 10.0701H7.11994C6.54994 10.0701 6.00994 10.1801 5.48994 10.4001C5.39994 10.4401 5.29994 10.4601 5.19994 10.4601ZM10.6099 2.75012C9.36994 2.75012 8.60994 3.56012 7.81994 5.40012C7.80994 5.43012 7.78994 5.46012 7.77994 5.49012L6.46994 8.60012C6.68994 8.58012 6.89994 8.57012 7.11994 8.57012H9.46994L11.8799 2.97012C11.4099 2.82012 10.9899 2.75012 10.6099 2.75012Z"
        fill="#1C1D21"
      />
      <path
        d="M18.29 10.27C18.22 10.27 18.14 10.26 18.07 10.24C17.69 10.13 17.29 10.07 16.87 10.07H9.96998C9.71998 10.07 9.47998 9.93999 9.33998 9.72999C9.20998 9.51999 9.17998 9.24999 9.27998 9.01999L12.18 2.28999C12.33 1.92999 12.77 1.67999 13.14 1.80999C13.26 1.84999 13.37 1.89999 13.49 1.94999L15.85 2.93999C17.23 3.50999 18.15 4.10999 18.75 4.82999C18.87 4.96999 18.97 5.11999 19.07 5.27999C19.18 5.44999 19.28 5.64999 19.35 5.85999C19.38 5.92999 19.43 6.05999 19.46 6.19999C19.74 7.14999 19.6 8.30999 19 9.80999C18.87 10.09 18.59 10.27 18.29 10.27ZM11.11 8.56999H16.88C17.2 8.56999 17.51 8.59999 17.82 8.64999C18.1 7.77999 18.16 7.10999 18 6.56999C17.98 6.47999 17.96 6.43999 17.95 6.39999C17.89 6.23999 17.85 6.14999 17.8 6.06999C17.73 5.95999 17.68 5.86999 17.6 5.77999C17.17 5.25999 16.41 4.77999 15.27 4.30999L13.3 3.48999L11.11 8.56999Z"
        fill="#1C1D21"
      />
      <path
        d="M15.9 22.7501H8.1C7.82 22.7501 7.56 22.7301 7.3 22.7001C3.79 22.4701 1.79 20.4601 1.55 16.9101C1.52 16.6901 1.5 16.4201 1.5 16.1501V14.2001C1.5 11.9501 2.84 9.92007 4.91 9.02007C5.62 8.72007 6.36 8.57007 7.13 8.57007H16.89C17.46 8.57007 18.01 8.65007 18.52 8.81007C20.87 9.52007 22.52 11.7401 22.52 14.2001V16.1501C22.52 16.3701 22.51 16.5801 22.5 16.7801C22.28 20.6901 20 22.7501 15.9 22.7501ZM7.12 10.0701C6.55 10.0701 6.01 10.1801 5.49 10.4001C3.97 11.0601 2.99 12.5501 2.99 14.2001V16.1501C2.99 16.3601 3.01 16.5701 3.03 16.7701C3.22 19.6201 4.62 21.0201 7.43 21.2101C7.68 21.2401 7.88 21.2601 8.09 21.2601H15.89C19.19 21.2601 20.81 19.8101 20.97 16.7101C20.98 16.5301 20.99 16.3501 20.99 16.1501V14.2001C20.99 12.3901 19.78 10.7701 18.06 10.2401C17.68 10.1301 17.28 10.0701 16.86 10.0701H7.12Z"
        fill="#1C1D21"
      />
      <path
        d="M2.23999 14.95C1.82999 14.95 1.48999 14.61 1.48999 14.2V11.27C1.48999 8.12005 3.71999 5.40005 6.79999 4.80005C7.06999 4.75005 7.34999 4.85004 7.52999 5.06004C7.69999 5.27004 7.74999 5.57005 7.63999 5.82005L5.88999 10C5.80999 10.18 5.66999 10.32 5.49999 10.4C3.97999 11.06 2.99999 12.55 2.99999 14.2C2.98999 14.61 2.65999 14.95 2.23999 14.95ZM5.59999 6.82005C4.31999 7.54005 3.38999 8.80005 3.09999 10.27C3.53999 9.82005 4.04999 9.44004 4.62999 9.16004L5.59999 6.82005Z"
        fill="#1C1D21"
      />
      <path
        d="M21.7599 14.95C21.3499 14.95 21.0099 14.61 21.0099 14.2C21.0099 12.39 19.7999 10.77 18.0799 10.24C17.8799 10.18 17.7099 10.04 17.6199 9.85002C17.5299 9.66002 17.5199 9.44002 17.5999 9.25002C18.0699 8.08002 18.1899 7.23002 17.9999 6.57002C17.9799 6.48002 17.9599 6.44002 17.9499 6.40002C17.8199 6.11002 17.8899 5.77002 18.1199 5.55002C18.3499 5.33002 18.6999 5.28002 18.9799 5.43002C21.1599 6.57002 22.5099 8.81002 22.5099 11.27V14.2C22.5099 14.61 22.1699 14.95 21.7599 14.95ZM19.2499 9.09002C19.8799 9.38002 20.4399 9.79002 20.9099 10.28C20.7199 9.30002 20.2499 8.41002 19.5599 7.71002C19.5099 8.13002 19.4099 8.59002 19.2499 9.09002Z"
        fill="#1C1D21"
      />
    </svg>
  );
};
export const Wallet2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.94 2.21008L9.52995 7.82008H7.11995C6.71995 7.82008 6.32995 7.85007 5.94995 7.93007L6.94995 5.53008L6.98995 5.44008L7.04995 5.28008C7.07995 5.21008 7.09995 5.15007 7.12995 5.10008C8.28995 2.41007 9.58995 1.57008 11.94 2.21008Z"
        fill="#029247"
      />
      <path
        d="M18.7301 8.09002L18.7101 8.08002C18.1101 7.91002 17.5001 7.82002 16.8801 7.82002H10.6201L12.8701 2.59002L12.9001 2.52002C13.0401 2.57002 13.1901 2.64002 13.3401 2.69002L15.5501 3.62002C16.7801 4.13002 17.6401 4.66002 18.1701 5.30002C18.2601 5.42002 18.3401 5.53002 18.4201 5.66002C18.5101 5.80002 18.5801 5.94002 18.6201 6.09002C18.6601 6.18002 18.6901 6.26002 18.7101 6.35002C18.8601 6.86002 18.8701 7.44002 18.7301 8.09002Z"
        fill="#029247"
      />
      <path
        d="M12.52 17.6601H12.77C13.07 17.6601 13.32 17.3901 13.32 17.0601C13.32 16.6401 13.2 16.5801 12.94 16.4801L12.52 16.3301V17.6601Z"
        fill="#029247"
      />
      <path
        d="M18.29 9.52007C17.84 9.39007 17.37 9.32007 16.88 9.32007H7.11999C6.43999 9.32007 5.79999 9.45007 5.19999 9.71007C3.45999 10.4601 2.23999 12.1901 2.23999 14.2001V16.1501C2.23999 16.3901 2.25999 16.6201 2.28999 16.8601C2.50999 20.0401 4.20999 21.7401 7.38999 21.9501C7.61999 21.9801 7.84999 22.0001 8.09999 22.0001H15.9C19.6 22.0001 21.55 20.2401 21.74 16.7401C21.75 16.5501 21.76 16.3501 21.76 16.1501V14.2001C21.76 11.9901 20.29 10.1301 18.29 9.52007ZM13.28 15.5001C13.74 15.6601 14.36 16.0001 14.36 17.0601C14.36 17.9701 13.65 18.7001 12.77 18.7001H12.52V18.9201C12.52 19.2101 12.29 19.4401 12 19.4401C11.71 19.4401 11.48 19.2101 11.48 18.9201V18.7001H11.39C10.43 18.7001 9.63999 17.8901 9.63999 16.8901C9.63999 16.6001 9.86999 16.3701 10.16 16.3701C10.45 16.3701 10.68 16.6001 10.68 16.8901C10.68 17.3101 11 17.6601 11.39 17.6601H11.48V15.9701L10.72 15.7001C10.26 15.5401 9.63999 15.2001 9.63999 14.1401C9.63999 13.2301 10.35 12.5001 11.23 12.5001H11.48V12.2801C11.48 11.9901 11.71 11.7601 12 11.7601C12.29 11.7601 12.52 11.9901 12.52 12.2801V12.5001H12.61C13.57 12.5001 14.36 13.3101 14.36 14.3101C14.36 14.6001 14.13 14.8301 13.84 14.8301C13.55 14.8301 13.32 14.6001 13.32 14.3101C13.32 13.8901 13 13.5401 12.61 13.5401H12.52V15.2301L13.28 15.5001Z"
        fill="#029247"
      />
      <path
        d="M10.6799 14.14C10.6799 14.56 10.7999 14.62 11.0599 14.72L11.4799 14.87V13.54H11.2299C10.9199 13.54 10.6799 13.81 10.6799 14.14Z"
        fill="#029247"
      />
    </svg>
  );
};

export const Loans = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.34 18.1849V18.8992L22.0112 18.6547L23.5 18.1122V19.3487L12.1562 23.4716L12.1551 23.472C12.0531 23.5093 11.9439 23.5093 11.8419 23.472L11.8409 23.4716L0.5 19.3458V18.1131L1.98908 18.6547L2.66 18.8988V18.1849V2.03987C2.66 1.18811 3.34811 0.5 4.2 0.5H19.8C20.6519 0.5 21.34 1.18812 21.34 2.03987V18.1849ZM11.8291 22.2335L12 22.2957L12.1709 22.2335L19.8509 19.4407L20.18 19.321V18.9708V2.15987V1.65987H19.68H4.32H3.82V2.15987V18.9708V19.321L4.14912 19.4407L11.8291 22.2335Z"
        fill="#1C1D21"
        stroke="#1C1D21"
      />
      <path
        d="M8.50098 15.5461V7.83273C8.50093 7.6688 8.55533 7.5095 8.65564 7.37984C8.75595 7.25018 8.89648 7.15752 9.05517 7.11639C9.21386 7.07527 9.38171 7.08802 9.53237 7.15264C9.68302 7.21726 9.80795 7.33009 9.88752 7.47342L14.1599 15.1642C14.2395 15.3076 14.3644 15.4204 14.515 15.485C14.6657 15.5496 14.8336 15.5624 14.9922 15.5213C15.1509 15.4801 15.2915 15.3875 15.3918 15.2578C15.4921 15.1282 15.5465 14.9688 15.5464 14.8049V7.09155"
        stroke="#1C1D21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.09155 9.9104H16.9552"
        stroke="#1C1D21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.09155 12.7295H16.9552"
        stroke="#1C1D21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Loans2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.34 18.1849V18.8992L22.0112 18.6547L23.5 18.1122V19.3487L12.1562 23.4716L12.1551 23.472C12.0531 23.5093 11.9439 23.5093 11.8419 23.472L11.8409 23.4716L0.5 19.3458V18.1131L1.98908 18.6547L2.66 18.8988V18.1849V2.03987C2.66 1.18811 3.34811 0.5 4.2 0.5H19.8C20.6519 0.5 21.34 1.18812 21.34 2.03987V18.1849ZM11.8291 22.2335L12 22.2957L12.1709 22.2335L19.8509 19.4407L20.18 19.321V18.9708V2.15987V1.65987H19.68H4.32H3.82V2.15987V18.9708V19.321L4.14912 19.4407L11.8291 22.2335Z"
        fill="#029247"
        stroke="#029247"
      />
      <path
        d="M8.50098 15.5463V7.83298C8.50093 7.66905 8.55533 7.50974 8.65564 7.38009C8.75595 7.25043 8.89648 7.15776 9.05517 7.11664C9.21386 7.07551 9.38171 7.08826 9.53237 7.15289C9.68302 7.21751 9.80795 7.33034 9.88752 7.47366L14.1599 15.1645C14.2395 15.3078 14.3644 15.4206 14.515 15.4853C14.6657 15.5499 14.8336 15.5626 14.9922 15.5215C15.1509 15.4804 15.2915 15.3877 15.3918 15.2581C15.4921 15.1284 15.5465 14.9691 15.5464 14.8052V7.0918"
        stroke="#029247"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.09155 9.91064H16.9552"
        stroke="#029247"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.09155 12.7295H16.9552"
        stroke="#029247"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Investment = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill="#1C1D21"
      />
      <path
        d="M7.33011 15.24C7.17011 15.24 7.01011 15.19 6.87011 15.08C6.54011 14.83 6.48011 14.36 6.73011 14.03L9.11011 10.94C9.40011 10.57 9.81011 10.33 10.2801 10.27C10.7401 10.21 11.2101 10.34 11.5801 10.63L13.4101 12.07C13.4801 12.13 13.5501 12.13 13.6001 12.12C13.6401 12.12 13.7101 12.1 13.7701 12.02L16.0801 9.04001C16.3301 8.71001 16.8101 8.65001 17.1301 8.91001C17.4601 9.16001 17.5201 9.63001 17.2601 9.96001L14.9501 12.94C14.6601 13.31 14.2501 13.55 13.7801 13.6C13.3101 13.66 12.8501 13.53 12.4801 13.24L10.6501 11.8C10.5801 11.74 10.5001 11.74 10.4601 11.75C10.4201 11.75 10.3501 11.77 10.2901 11.85L7.91011 14.94C7.78011 15.14 7.56011 15.24 7.33011 15.24Z"
        fill="#1C1D21"
      />
    </svg>
  );
};

export const Investment2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17.26 9.96L14.95 12.94C14.66 13.31 14.25 13.55 13.78 13.6C13.31 13.66 12.85 13.53 12.48 13.24L10.65 11.8C10.58 11.74 10.5 11.74 10.46 11.75C10.42 11.75 10.35 11.77 10.29 11.85L7.91 14.94C7.76 15.13 7.54 15.23 7.32 15.23C7.16 15.23 7 15.18 6.86 15.07C6.53 14.82 6.47 14.35 6.72 14.02L9.1 10.93C9.39 10.56 9.8 10.32 10.27 10.26C10.73 10.2 11.2 10.33 11.57 10.62L13.4 12.06C13.47 12.12 13.54 12.12 13.59 12.11C13.63 12.11 13.7 12.09 13.76 12.01L16.07 9.03C16.32 8.7 16.8 8.64 17.12 8.9C17.45 9.17 17.51 9.64 17.26 9.96Z"
        fill="#029247"
      />
    </svg>
  );
};

export const Dashboard = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.77 11.25H15.73C13.72 11.25 12.75 10.36 12.75 8.52V3.98C12.75 2.14 13.73 1.25 15.73 1.25H19.77C21.78 1.25 22.75 2.14 22.75 3.98V8.51C22.75 10.36 21.77 11.25 19.77 11.25ZM15.73 2.75C14.39 2.75 14.25 3.13 14.25 3.98V8.51C14.25 9.37 14.39 9.74 15.73 9.74H19.77C21.11 9.74 21.25 9.36 21.25 8.51V3.98C21.25 3.12 21.11 2.75 19.77 2.75H15.73Z"
        fill="#1C1D21"
      />
      <path
        d="M19.77 22.75H15.73C13.72 22.75 12.75 21.77 12.75 19.77V15.73C12.75 13.72 13.73 12.75 15.73 12.75H19.77C21.78 12.75 22.75 13.73 22.75 15.73V19.77C22.75 21.77 21.77 22.75 19.77 22.75ZM15.73 14.25C14.55 14.25 14.25 14.55 14.25 15.73V19.77C14.25 20.95 14.55 21.25 15.73 21.25H19.77C20.95 21.25 21.25 20.95 21.25 19.77V15.73C21.25 14.55 20.95 14.25 19.77 14.25H15.73Z"
        fill="#1C1D21"
      />
      <path
        d="M8.27 11.25H4.23C2.22 11.25 1.25 10.36 1.25 8.52V3.98C1.25 2.14 2.23 1.25 4.23 1.25H8.27C10.28 1.25 11.25 2.14 11.25 3.98V8.51C11.25 10.36 10.27 11.25 8.27 11.25ZM4.23 2.75C2.89 2.75 2.75 3.13 2.75 3.98V8.51C2.75 9.37 2.89 9.74 4.23 9.74H8.27C9.61 9.74 9.75 9.36 9.75 8.51V3.98C9.75 3.12 9.61 2.75 8.27 2.75H4.23Z"
        fill="#1C1D21"
      />
      <path
        d="M8.27 22.75H4.23C2.22 22.75 1.25 21.77 1.25 19.77V15.73C1.25 13.72 2.23 12.75 4.23 12.75H8.27C10.28 12.75 11.25 13.73 11.25 15.73V19.77C11.25 21.77 10.27 22.75 8.27 22.75ZM4.23 14.25C3.05 14.25 2.75 14.55 2.75 15.73V19.77C2.75 20.95 3.05 21.25 4.23 21.25H8.27C9.45 21.25 9.75 20.95 9.75 19.77V15.73C9.75 14.55 9.45 14.25 8.27 14.25H4.23Z"
        fill="#1C1D21"
      />
    </svg>
  );
};

export const Report = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V15.5C22.75 19.15 20.65 21.25 17 21.25ZM7 4.25C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H17C19.86 19.75 21.25 18.36 21.25 15.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7Z"
        fill="#1C1D21"
      />
      <path
        d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z"
        fill="#1C1D21"
      />
    </svg>
  );
};

export const Report2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z"
        fill="#029247"
      />
    </svg>
  );
};

export const Dashboard2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"
        fill="#029247"
      />
      <path
        d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z"
        fill="#029247"
      />
      <path
        d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
        fill="#029247"
      />
      <path
        d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
        fill="#029247"
      />
    </svg>
  );
};
