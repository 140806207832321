import { memo, useEffect, useState, useMemo } from "react";
import { BackIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import Card from "../shared/Card";
import VerifiedCard from "../shared/VerifiedCard";
import { CustomerWalletDetails } from "../typings";
import { getApi } from "../utils/services";
import {
  RecentTransaction,
  WalletDetail,
  WalletEntries,
  WalletManagement,
} from "./";

type Props = {
  data: CustomerWalletDetails;
};

const WalletDetails = ({ data }: Props) => {
  const { handleClick, title, setEdit, setEditData, openMenu } =
    useStateContext();
  const [credit, setCredit] = useState("");
  const [info, setInfo] = useState(data);
  const [debit, setDebit] = useState("");

  const back = () => {
    setEditData(null);
    handleClick("");
    setEdit(false);
  };

  useMemo(() => {
    setInfo(data);
  }, [data]);

  useEffect(() => {
    const fetchCreditDebit = async () => {
      getApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/transactions/${data.userId}?Days=30&Type=1&Status=1`
      )
        .then((res: any) => {
          const cr = res.responseData?.items.map(
            (item: any) => item.tranType1 === "TRF IN"
          );
          const dr = res.responseData?.items.map(
            (item: any) => item?.tranType1 === "TRF OUT"
          );
          setCredit(cr.reduce((prev: any, curr: any) => prev + curr, 0));
          setDebit(dr.reduce((prev: any, curr: any) => prev + curr, 0));
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    };

    fetchCreditDebit();
  }, [data.userId]);

  return (
    <>
      <div
        className="flex items-center space-x-4 font-semibold cursor-pointer my-10"
        onClick={back}
      >
        <BackIcon />
        <p className="text-[#1C1D21]">Back to wallet</p>
      </div>
      {title === "ViewWalletEntries" ? (
        <WalletEntries data={data} />
      ) : (
        <div className="flex w-full  flex-col">
          <section
            className={`flex ${
              openMenu
                ? "flex-col lg:flex-row space-x-12 lg:items-center"
                : "flex-col xl:flex-row"
            }`}
          >
            <div className="xl:mt-3">
              <VerifiedCard userid={data?.userId} />
            </div>
            <section className="flex !-ml-3 md:items-center flex-col md:flex-row md:space-x-5 md:!-ml-2 lg:ml-0">
              <Card
                title="Total Credit"
                data={credit ? credit : "0"}
                link={""}
              />
              <Card title="Total Debit" data={debit ? debit : "0"} link={""} />
            </section>
          </section>
          <section
            className={`flex ${
              openMenu
                ? " flex-col lg:flex-row lg:!space-x-20"
                : "flex-col  xl:flex-row xl:!space-x-20"
            }`}
          >
            <div className="!-ml-2 md:ml-0">
              <WalletDetail data={info} />
            </div>
            <div className="!-ml-3 md:ml-0">
              <WalletManagement data={info} />
              <RecentTransaction userid={data?.userId} />
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default memo(WalletDetails);
