type Props = {};

const NairaIcon = (props: Props) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4255 0V14H11.4192L4.18174 4.52V14H1.76299V0H3.76924L11.0067 9.48V0H13.4255Z"
        fill="#2EAE4E"
      />
      <rect y="3.99902" width="15" height="2" fill="#2EAE4E" />
      <rect y="8.00098" width="15" height="2" fill="#2EAE4E" />
    </svg>
  );
};
export default NairaIcon;

export const FlagIcon = (props: Props) => {
  return (
    <svg
      width="36"
      height="19"
      viewBox="0 0 36 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="36" height="18" fill="white" />
      <rect y="0.5" width="12" height="18" fill="#027A3B" />
      <rect x="24" y="0.5" width="12" height="18" fill="#027A3B" />
    </svg>
  );
};
