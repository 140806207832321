// import { useState } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";

type Props = {
  data: any;
};

const ViewKYC = ({ data }: Props) => {
  const { handleClick, setTitle } = useStateContext();
  // const [loading, setLoading] = useState(false);
  const loading = false;
  const cancel = () => {
    setTitle("");
    handleClick();
  };
  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Know your customer (KYC)
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          Are you sure you want to approve this account document?
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={cancel}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button type="submit" className="btn">
            {loading ? "processing..." : "Approve"}
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default ViewKYC;
