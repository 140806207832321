import { memo, useEffect, useState } from "react";
import { BackIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import Card from "../shared/Card";
import VerifiedCard from "../shared/VerifiedCard";
import { LoanDetails2 } from "../typings";
import { getApi } from "../utils/services";
import { LoanDetail, LoanHistory, LoanManagement } from "./";

type Props = {
  data: LoanDetails2;
};

const LoanDetails = ({ data }: Props) => {
  const { setEdit, setEditData, openMenu } = useStateContext();
  const [loading, setLoading] = useState(false);

  const back = () => {
    setEdit(false);
    setEditData(null);
  };

  useEffect(() => {
    setLoading(true);
    getApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/Loan/GetLoanById/${data.userid}`
    )
      .then(() => {
        setLoading(false);
        // setUserId(res.responseData.userid);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log("err", err);
      });
  }, [data.userid]);
  return (
    <>
      <div
        className="flex items-center space-x-4 font-semibold cursor-pointer my-10"
        onClick={back}
      >
        <BackIcon />
        <p className="text-[#1C1D21]">Back to loans</p>
      </div>
      <div className="flex w-full flex-col">
        <section
          className={`flex ${
            openMenu
              ? " md:!-ml-[15em] lg:!ml-0 flex-col lg:flex-row items-center"
              : "flex-col  xl:flex-row"
          }`}
        >
          <div className="">
            <VerifiedCard status={data.loan_status} userid={data.userid} />
          </div>
          <section className="flex md:items-center flex-col md:flex-row md:space-x-5">
            <Card title="Total Credit" data="00" link={""} />
            <Card title="Total Debit" data="00" link={""} />
          </section>
        </section>
        <section
          className={`flex flex-col ${
            openMenu
              ? "lg:flex-row lg:!space-x-20"
              : "flex-col xl:flex-row xl:!space-x-20"
          }`}
        >
          <div>{loading ? "loading..." : <LoanDetail data={data} />}</div>
          <div>
            <LoanManagement data={data} />
            <LoanHistory userid={data.userid} />
          </div>
        </section>
      </div>
    </>
  );
};

export default memo(LoanDetails);
