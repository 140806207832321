type Props = {
  children: any;
};

const KYCCard = ({ children }: Props) => {
  return (
    <>
      <div className="w-[280px] md:w-[400px] h-[275px] bg-[#CCF2D5] rounded-lg flex items-center justify-center flex-col space-y-10 m-4">
        {children}
      </div>
    </>
  );
};

export default KYCCard;
