import { memo, useEffect, useState } from "react";
import BigCard from "../shared/BigCard";
import { getApi } from "../utils/services";
import SkeletonCard from "./skeleton/Card";

type Props = {};

const Help = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      getApi(`${process.env.REACT_APP_BASE_URL_ARY}/Ticket/GetOpenTickets`)
        .then((res) => {
          setLoading(false);
          setPosts(
            res.responseData.sort(
              (objA: any, objB: any) =>
                Number(new Date(objB.dateCreated)) -
                Number(new Date(objA.dateCreated))
            )
          );
        })
        .catch((err: any) => {
          console.log("err", err);
          setLoading(false);
        });
      // console.log('res', res.data.filter(item => item.title.includes(inputText)))
    };

    fetchPosts();
  }, []);
  return (
    <>
      {loading ? (
        <SkeletonCard />
      ) : (
        <BigCard>
          <h3 className="text-[#1C1D21] font-bold text-xl my-5">
            Help & Support{" "}
          </h3>
          <h3 className="text-[#029247] font-bold text-xl my-5">
            Complaints Logs{" "}
          </h3>
          <section className="w-full h-[200px] overflow-y-scroll overflow-x-hidden flex flex-col ">
            {loading ? (
              "Loading..."
            ) : posts?.length > 0 ? (
              posts.map((res: any) => (
                <div key={res.id} className="flex flex-col m-4">
                  <small className="text-[#98989A] text-xs">
                    {new Date(res.dateCreated).toDateString()}
                  </small>
                  <h3 className="text-[#1F2024] font-semibold capitalize">
                    {res.title}:
                  </h3>
                  <p className="text-[#696A6C]">{res.description}</p>
                </div>
              ))
            ) : (
              <p className="text-[#98989A] text-xl">no result</p>
            )}
          </section>
        </BigCard>
      )}
    </>
  );
};

export default memo(Help);
