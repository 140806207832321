import { memo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserIcon } from "../assets/icons/ManageUsers";
import SkeletonCard from "../components/skeleton/Card";

type Props = {
  title: string;
  data: number | string | null | undefined;
  link: string;
};

const Card = ({ title, data, link }: Props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleRouting = () => {
    if (link) navigate(`/${link}`);
  };

  useEffect(() => {
    title ? setLoading(false) : setLoading(true);
  }, [title]);

  return (
    <>
      {loading ? (
        <SkeletonCard />
      ) : (
        <div
          onClick={handleRouting}
          className="bg-white w-[200px] h-[154px] flex flex-col space-y-8 shadow rounded-xl p-5 m-3 cursor-pointer"
        >
          <div className="flex items-center space-x-4">
            <span className="bg-[#029247] rounded-full p-2 flex items-center justify-center">
              <UserIcon />
            </span>
            <h3 className=" font-semibold capitalize text-[#1F2024]">
              {title}
            </h3>
          </div>
          <h1 className="text-2xl font-semibold">{data}</h1>
        </div>
      )}
    </>
  );
};

export default memo(Card);
