import { useEffect, useState, memo } from "react";
import BigCard from "../shared/BigCard";
import { VasDetail as detail } from "../typings";
import { addThousandSeparator } from "../utils";
import { getApi } from "../utils/services";

type Props = {
  data: detail;
};

const VasDetail = ({ data }: Props) => {
  const [balance, setBalance] = useState("");
  useEffect(() => {
    const fetchPosts = async () => {
      getApi(
        `${process.env.REACT_APP_BASE_URL_ARY}/Admin/customers/${data.userId}`
      )
        .then((res: any) => {
          setBalance(res.responseData?.balance);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    };

    fetchPosts();
  }, [data?.userId]);

  const billType = data?.narration ?? "";
  return (
    <BigCard>
      <section className="flex items-center justify-between">
        <h3 className="text-[#1C1D21] font-bold text-xl">
          {billType?.charAt(0)?.toLowerCase() === "d" ? "DATA" : null}
          {billType?.charAt(0)?.toLowerCase() === "a" ? "AIRTIME" : null}
          {billType?.charAt(0)?.toLowerCase() === "e" ? "UTILITY" : null}{" "}
          Purchcase
        </h3>
      </section>
      <section className="my-3 flex flex-col space-y-4 divide-y-2">
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Amount</h2>
          <p className="text-[#1C1D21]">{data?.amount} </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Type</h2>
          <p className="text-[#1C1D21]">
            {billType && billType?.charAt(0)?.toLowerCase() === "d"
              ? "DATA"
              : null}
            {billType && billType?.charAt(0)?.toLowerCase() === "a"
              ? "AIRTIME"
              : null}
            {billType && billType?.charAt(0)?.toLowerCase() === "e"
              ? "UTILITY"
              : null}{" "}
          </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Recipient</h2>
          <p className="text-[#1C1D21]">{data?.crPhone}</p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Date</h2>
          <p className="text-[#1C1D21]">
            {new Date(data?.dateCreated)?.toDateString()}{" "}
          </p>
        </div>
        <div className="flex items-center justify-between py-2 ">
          <h2 className="text-[#4C4D50]">Status</h2>
          {data?.tranStatus &&
          data?.tranStatus?.toLowerCase() === "successful" ? (
            <p className="text-white  bg-[#029247] rounded-full p-2 w-max">
              {data?.tranStatus && data?.tranStatus}{" "}
            </p>
          ) : (
            <p className="text-white  bg-[#E88200] rounded-3xl py-2 px-4 w-max">
              {data?.tranStatus && data?.tranStatus}{" "}
            </p>
          )}
        </div>

        <div>
          <div className="bg-[#01612F] w-full h-0.5 mb-4" />

          <aside className="flex items-center justify-between">
            <h2 className="text-[#014924] font-semibold">Wallet Balance</h2>
            <p className="text-[#696A6C]">Naira Wallet</p>
          </aside>
          <h1 className="text-[#01612F] text-4xl font-semibold flex items-center">
            <p className="mr-1 text-[#01612F]">&#8358;</p>{" "}
            {addThousandSeparator(
              parseFloat(balance?.toString()?.replace(/,/g, ""))?.toFixed(2) ??
                0
            )}
          </h1>
        </div>
      </section>
    </BigCard>
  );
};

export default memo(VasDetail);
