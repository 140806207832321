import { createContext, useContext, useState } from "react";

const StateContext = createContext();

// const initialState = {
//   userProfile: false,
// };

export const ContextProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newTicketId, setNewTicketId] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [ticketData, setTicketData] = useState(null);
  const [closedTicket, setClosedTicket] = useState(false);
  const [editData, setEditData] = useState(null);
  const [title, setTitle] = useState("");
  const handleClick = (name) => {
    setOpen((prev) => !prev);
    setTitle(name);
  };
  const date = new Date().toDateString();

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        open,
        ticketId,
        setTicketId,
        handleClick,
        closedTicket,
        openMenu,
        setOpenMenu,
        setClosedTicket,
        title,
        newMessage,
        setNewMessage,
        newTicketId,
        setNewTicketId,
        setTitle,
        date,
        setOpen,
        auth,
        setTicketData,
        ticketData,
        editData,
        edit,
        setEditData,
        setEdit,
        setAuth,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
