import { memo, useState, useEffect } from "react";
import SkeletonCard from "../components/skeleton/Card";

type Props = {
  children: any;
};

const BigCard = ({ children }: Props) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    children ? setLoading(false) : setLoading(true);
  }, [children]);
  return (
    <>
      {loading ? (
        <SkeletonCard />
      ) : (
        <div className="w-[280px] md:w-[400px] m-2 h-max bg-white rounded-xl p-5">
          {children}
        </div>
      )}
    </>
  );
};

export default memo(BigCard);
