import { useState, useMemo } from "react";
import { Toast } from "../components/Toast";
import { Customer } from "../typings";
import { postApi, putApi } from "../utils/services";
import { useCustomerQuery } from "./useCustomerQuery";

export const usePushNotification = (info2: any) => {
  const { info } = useCustomerQuery("Admin/GetAllUsers", "", "");
  const [loading, setLoading] = useState(false);
  const [selectData, setSelectData] = useState(info.data);
  const [inputFile, setInputFile] = useState({
    title: "" || info2?.title,
    message: "" || info2?.body,
    category: "" || info2?.category,
  });
  const [selected, setSelected] = useState([]);

  const [result, setResult] = useState([]);
  const idPayload = selected.map((id: any) => id?.value);

  const handleChange = (e: any) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };

  // looping
  useMemo(() => {
    const results: any = [];
    setSelectData(info.data);
    selectData?.length > 0 &&
      selectData?.forEach((employee: Customer) => {
        results.push({
          label: `${employee.firstName} ${employee.lastName}`,
          value: `${employee.id}`,
        });
      });
    setResult(results);
  }, [info?.data, selectData]);

  const submit = () => {
    setLoading(true);
    const payload = {
      title: inputFile.title,
      body: inputFile.message,
      category: inputFile.category,
      customers: idPayload,
      sendToCustomers: true,
    };
    postApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/admin-notifications`,
      payload
    )
      .then((res) => {
        setLoading(false);
        Toast({ title: res.responseMessage, error: false });
        setInputFile({
          title: "",
          message: "",
          category: "",
        });
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };
  const submitEdit = () => {
    setLoading(true);
    const payload = {
      title: inputFile.title,
      body: inputFile.message,
      category: inputFile.category,
      customers: idPayload,
      sendToCustomers: true,
    };

    putApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/admin-notifications/${info2.id}`,
      payload
    )
      .then((res) => {
        setLoading(false);
        Toast({ title: res.responseMessage, error: false });
        setInputFile({
          title: "",
          message: "",
          category: "",
        });
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  return {
    inputFile,
    handleChange,
    result,
    selected,
    setSelected,
    info,
    submitEdit,
    loading,
    submit,
  };
};
