import Search from "../assets/icons/Search";

type Props = {
  inputText: string;
  setInputText: (e: string) => void;
  disabled: boolean;
};

export function SearchField({ inputText, setInputText, disabled }: Props) {
  return (
    <div className="w-[200px] md:w-[330px] xl:w-[350px] p-2 h-[47px] flex items-center space-x-3 pl-5 bg-[#F6F6F6] rounded-full">
      <Search />
      <input
        className="outline-none border-none p-3 text-[#BABABB] bg-transparent w-full"
        type="text"
        placeholder="Search here"
        value={inputText}
        disabled={disabled}
        onChange={(e: any) => setInputText(e.target.value)}
      />
    </div>
  );
}
