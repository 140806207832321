import { useState, memo } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { BNPLDetail } from "../typings";
import { getApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {
  data: BNPLDetail;
};

const Validate = ({ data }: Props) => {
  const { handleClick, setTitle } = useStateContext();
  const [loading, setLoading] = useState(false);

  const enable = (e: any) => {
    e.preventDefault();
    setLoading(true);
    getApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/Cart/GetAllProductionTransactionByTransId/${data?.id}`
    )
      .then((res) => {
        Toast({
          title: ` ${res.responseMessage}`,
          error: false,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const cancel = () => {
    setTitle("");
    handleClick();
  };

  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Validate Transaction
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          Are you sure you want to validate this transaction?
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={cancel}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button onClick={enable} type="submit" className="btn">
            {loading ? "processing..." : "validate"}
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default memo(Validate);
