import { memo, useState, useEffect } from "react";
import BigCard from "../shared/BigCard";
import { addThousandSeparator } from "../utils";
import { getApi } from "../utils/services";
import ApproveBNPL from "./ApproveBNPL";
import KYCCard from "./KYCCard";

type Props = {
  data: any;
};

const BNPLDetails = ({ data }: Props) => {
  const [balance, setBalance] = useState("");
  const [address, setAddress] = useState("");
  // const [uploads, setUploads] = useState([]);
  // useEffect(() => {
  //   getApi(
  //     `${process.env.REACT_APP_BASE_URL_ARY}/User/GetWalletUserByUserId/${data?.userId}`
  //   )
  //     .then((res: AxiosResponse) => {
  //       setBalance(res.data?.responseData?.balance);
  //     })
  //     .catch((err: any) => {
  //       console.log("err", err);
  //     });
  // }, [data.userId]);

  useEffect(() => {
    getApi(`
${process.env.REACT_APP_BASE_URL_ARY}/DeliveryLocation/GetAllDeliveryLocations`)
      .then((res) => {
        const result = res.responseData.find(
          (item: any) => item.id === data.storeLocationId
        );

        setAddress(result?.address);
      })
      .catch((err) => console.log("err", err));
  }, []);

  return (
    <div>
      <section className="flex flex-col space-y-4 items-center justify-center sm:flex-row sm:items-start sm:space-x-4 ">
        <BigCard>
          <section className="flex items-center justify-between">
            <h3 className="text-[#1C1D21] text-sm font-semibold font-bold text-xl">
              {" "}
              Customer Details
            </h3>
            {/* <p>view all </p> */}
          </section>
          <section className="my-3 flex flex-col space-y-4 divide-y-2">
            <div className="flex items-center justify-between py-2  ">
              <h2 className="text-[#4C4D50]">Name:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold capitalize">
                {" "}
                {data?.name}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50] ">BVN:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold  capitalize">
                {data?.bvn}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Bank Name:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold">
                {data?.bankName}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Monthly Income:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold ">
                &#8358;{" "}
                {addThousandSeparator((data?.monthlyIncome as string) ?? 0)}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Email Address:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold ">
                {data?.email}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Phone Number:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold ">
                {data?.phoneNumber}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Account Number:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold ">
                {data?.accountNumber}
              </p>
            </div>

            {data?.userId && (
              <div>
                <div className="bg-[#01612F] w-full h-0.5 mb-4" />

                <aside className="flex items-center justify-between">
                  <h2 className="text-[#014924] font-semibold">
                    Wallet Balance
                  </h2>
                  <p className="text-[#696A6C]">Naira Wallet</p>
                </aside>
                <h1 className="text-[#01612F] text-4xl font-semibold">
                  &#8358;{addThousandSeparator((balance as string) ?? 0)}
                </h1>
              </div>
            )}
          </section>
        </BigCard>
        <BigCard>
          <section className="flex items-center justify-between">
            <h3 className="text-[#1C1D21] font-bold text-xl">
              {" "}
              Purchase Details
            </h3>
          </section>
          <section className="my-3 flex flex-col space-y-4 divide-y-2">
            <div className="flex items-center justify-between py-2  ">
              <h2 className="text-[#4C4D50]">Price</h2>
              <p className="text-[#1C1D21] text-sm font-semibold ">
                &#8358; {addThousandSeparator((data?.price as string) ?? 0)}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50] ">Product Name</h2>
              <p className="text-[#1C1D21] text-sm font-semibold  capitalize">
                {data?.productName?.slice(0, 80).toLowerCase()}...
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Loan Type</h2>
              <p className="text-[#1C1D21] text-sm font-semibold ">
                {data?.loanType}
              </p>
            </div>

            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Tenor(months)</h2>
              <p className="text-[#1C1D21] text-sm font-semibold ">
                {data?.tenorInMonths}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Quantity</h2>
              <p className="text-[#1C1D21] text-sm font-semibold">
                {data?.quantity}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Pickup:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold">
                {data?.deliveryMethod ?? "N/A"}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Vendor:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold">
                {data?.vendorName}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Delivery Address:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold">
                {" "}
                {data?.deliveryAddress ?? "N/A"}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Delivery State:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold">
                {" "}
                {data?.deliveryState ?? "N/A"}
              </p>
            </div>
            <div className="flex items-center justify-between py-2 ">
              <h2 className="text-[#4C4D50]">Pickup Address:</h2>
              <p className="text-[#1C1D21] text-sm font-semibold">
                {address ?? "N/A"}
              </p>
            </div>

            {data?.userId && (
              <div>
                <div className="bg-[#01612F] w-full h-0.5 mb-4" />

                <aside className="flex items-center justify-between">
                  <h2 className="text-[#014924] font-semibold">
                    Wallet Balance
                  </h2>
                  <p className="text-[#696A6C]">Naira Wallet</p>
                </aside>
                <h1 className="text-[#01612F] text-4xl font-semibold">
                  &#8358;{addThousandSeparator((balance as string) ?? 0)}
                </h1>
              </div>
            )}
          </section>
        </BigCard>
      </section>

      <section className="flex flex-col items-center space-y-6  sm:flex-row sm:items-start sm:space-x-4 ">
        <KYCCard>
          <>
            <h2 className=" font-semibold">Work ID</h2>
            <div
              className={
                data.fileCat === "Avarta"
                  ? "w-[110px] h-[110px] border-4 flex  items-center justify-center rounded-full relative border-green-700"
                  : " w-[120px] h-[120px] object-contain"
              }
            >
              <img
                src={data.workId}
                className={
                  data.fileCat === "Avarta"
                    ? "h-24 w-24 rounded-full object-cover"
                    : ""
                }
                alt="user_data"
                loading="lazy"
              />
            </div>
            <a
              href={data.workId}
              className="underline text-[#014924] font-bold capitalize cursor-pointer"
              download
            >
              download image
            </a>
          </>
        </KYCCard>
        <KYCCard>
          <>
            <h2 className=" font-semibold">Valid ID</h2>
            <div
              className={
                data.fileCat === "Avarta"
                  ? "w-[110px] h-[110px] border-4 flex  items-center justify-center rounded-full relative border-green-700"
                  : " w-[120px] h-[120px] object-contain"
              }
            >
              <img
                src={data.validId}
                className={
                  data.fileCat === "Avarta"
                    ? "h-24 w-24 rounded-full object-cover"
                    : ""
                }
                alt="user_data"
                loading="lazy"
              />
            </div>
            <a
              href={data.validId}
              className="underline text-[#014924] font-bold capitalize cursor-pointer"
              download
            >
              download image
            </a>
          </>
        </KYCCard>
      </section>
      <section className="w-full flex items-center flex-col justify-center sm:flex-row  sm:space-x-5 sm:justify-start">
        <div className="w-full sm:w-1/2">
          <ApproveBNPL data={data} />
        </div>
        {data?.status === "Approved" && (
          <div className="w-full sm:w-1/2 bg-[#029247] p-6 rounded-lg flex items-center justify-center text-white ">
            Buy Now Pay Later (BNPL) Request Approved by{" "}
            {data?.approvedBy &&
              data?.approvedBy?.substring(0, data?.approvedBy.length - 18)}
          </div>
        )}
        {data?.status === "Rejected" && (
          <div className="w-full sm:w-1/2 bg-[#BD0B01] p-6 rounded-lg flex items-center justify-center text-white ">
            Buy Now Pay Later (BNPL) Request Rejected by{" "}
            {data?.approvedBy &&
              data?.approvedBy?.substring(0, data?.approvedBy.length - 18)}
          </div>
        )}
      </section>
    </div>
  );
};

export default memo(BNPLDetails);
