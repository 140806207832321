import { memo } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";

type Props = {
  data: any;
};

const LoanStatus = ({ data }: Props) => {
  const { handleClick } = useStateContext();
  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Loan Status
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          This status is currently under{" "}
          {data.status === "PENDING" ? (
            <span className="text-[#376BDB] font-semibold">{data.status}</span>
          ) : (
            <span className="text-[#029247] font-semibold">APPROVED</span>
          )}
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={handleClick}
            type="button"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button type="button" onClick={handleClick} className="btn">
            Go back
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default memo(LoanStatus);
