import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Toast } from "../components/Toast";
import { Customer } from "../typings";
import { getApi } from "../utils/services";

export const useCustomerQuery = (
  url: string,
  search: string,
  searchType: string
) => {
  const getData = getApi(`${process.env.REACT_APP_BASE_URL_ARY}/${url}`).then(
    (res) =>
      res.responseData.sort(
        (objA: any, objB: any) =>
          Number(new Date(objB.dateCreated)) -
          Number(new Date(objA.dateCreated))
      )
  );

  const info: any = useQuery({
    queryKey: ["customer"],
    queryFn: () => getData,
    refetchOnReconnect: true,
    retry: 5,
    retryDelay: 1000,
    staleTime: 5000,
    refetchOnMount: true,
    refetchInterval: 120000, // 2 minutes
    refetchIntervalInBackground: true,
    onSuccess(data) {
      // console.log("data", data);
      Toast({ title: "page refreshed", error: false });
    },
    onError: (error) => console.error(error),
  });

  const verified = info?.data?.filter(
    (res: Customer) => res.verified !== false
  );
  const nonVerified = info?.data?.filter(
    (res: Customer) => res.verified === false
  );

  const customerSearch = useMemo(
    () =>
      search
        ? info?.data?.filter(
            (item: any) =>
              item?.firstName
                .toLowerCase()
                .includes(search.toLowerCase().trim()) ||
              item?.lastName
                .toLowerCase()
                .includes(search.toLowerCase().trim()) ||
              item?.phoneNumber?.includes(search?.replace("0", "234").trim())
          )
        : info?.data,
    [search, info?.data]
  );

  const customerType = useMemo(
    () =>
      searchType
        ? info?.data?.filter(
            (res: any) => res?.type && res?.type.toLowerCase() === searchType
          )
        : info?.data,
    [searchType, info?.data]
  );

  const overAll = search
    ? customerSearch
    : searchType
    ? customerType
    : info?.data;

  return { info, verified, nonVerified, customerSearch, customerType, overAll };
};
