import { memo } from "react";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import TicketChat from "./ticketChat/ticketChat";

type Props = {};

const ChatMessage = (props: Props) => {
  const { editData } = useStateContext();

  return (
    <BigCard>
      <div className="flex items-center space-x-2">
        <h3 className="text-[#98989A]"> Complaint Title:</h3>
        <h1 className="text-[#1C1D21] font-bold text-xl">{editData.title}</h1>
      </div>
      <small className="text-[#1F2024]">
        {new Date(editData.dateCreated).toDateString()}
      </small>

      <TicketChat />
    </BigCard>
  );
};

export default memo(ChatMessage);
