import { useEffect, useState, memo } from "react";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { BNPLDetail, VendorList } from "../typings";
import { postApi } from "../utils/services";
import { Toast } from "./Toast";

type Props = {
  data: BNPLDetail;
  vendorList: VendorList[];
};

const CompleteTransaction = ({ data, vendorList }: Props) => {
  const { handleClick, setTitle } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<VendorList | null | undefined>(null);

  const enable = (e: any) => {
    e.preventDefault();
    const payload = {
      transactionId: data.id,
      vendorId: data.vendorId,
      productId: data.productId,
    };
    setLoading(true);
    postApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/Cart/ConfirmTransaction/`,
      payload
    )
      .then((res) => {
        setLoading(false);
        Toast({
          title: res.responseMessage,
          error: false,
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const cancel = () => {
    setTitle("");
    handleClick();
  };

  useEffect(() => {
    const result = vendorList?.find((item) => item.vendorName === data.vendor);
    setInfo(result);
  }, [setInfo, vendorList, data.vendor]);

  return (
    <ModalCard>
      <div className="flex justify-center  flex-col space-y-7 w-[500px] p-16 bg-white rounded-xl ">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Complete Transaction
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <p className="text-[#4C4D50] text-center">
          Are you sure you want to complete this transaction?
        </p>
        <div className="flex items-center justify-center space-x-6">
          <button
            onClick={cancel}
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2 font-semibold"
          >
            cancel
          </button>
          <button onClick={enable} type="submit" className="btn">
            {loading ? "processing..." : "Complete"}
          </button>
        </div>
      </div>
    </ModalCard>
  );
};

export default memo(CompleteTransaction);
