import { memo } from "react";
import { PersonIcon } from "../assets/icons/Card";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";

type Props = {};

const CardHolder = (props: Props) => {
  const { handleClick } = useStateContext();

  return (
    <BigCard>
      <section className="flex items-center justify-between">
        <h3 className="text-[#1C1D21] font-bold text-xl">Card Holders</h3>
        <button
          onClick={() => handleClick("customerDetail")}
          className="btn bg-transparent border border-[#029247] text-[#029247] font-semibold text-xs py-2 px-4"
        >
          Show All
        </button>
      </section>
      <section className="my-3 flex flex-col space-y-4 divide-y-2">
        <div className="flex items-center space-x-4 ">
          <PersonIcon />
          <h2 className="text-[#4C4D50]">First Name</h2>
        </div>
        <div className="flex items-center space-x-4 ">
          <PersonIcon />
          <h2 className="text-[#4C4D50]">Last Name</h2>
        </div>
        <div className="flex items-center space-x-4 ">
          <PersonIcon />
          <h2 className="text-[#4C4D50]">Email Address</h2>
        </div>
        <div className="flex items-center space-x-4 ">
          <PersonIcon />
          <h2 className="text-[#4C4D50]">Phone Number</h2>
        </div>
      </section>
    </BigCard>
  );
};

export default memo(CardHolder);
