import { memo, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { CloseIcon } from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import ModalCard from "../shared/Modal";
import { getApi } from "../utils/services";
import LoanDet from "./LoanDet";

type Props = {
  userid: string;
};

const ProcessLoan = ({ userid }: Props) => {
  const [loading, setLoading] = useState(false);
  const { handleClick } = useStateContext();
  const [data, setData] = useState(null);
  useEffect(() => {
    setLoading(true);
    getApi(
      `${process.env.REACT_APP_BASE_URL_ARY}/Loan/GetLoanByUserId/${userid}`
    )
      .then((res: AxiosResponse) => {
        setLoading(false);
        setData(res.data.responseData.loans[0]);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log("err", err);
      });
  }, [userid]);
  return (
    <ModalCard>
      <form className="flex justify-center items-center  flex-col space-y-7 bg-white rounded-xl p-12">
        <div className="flex items-center">
          <h4 className="ml-auto text-xl text-[#4C4D50] font-semibold">
            Process Loan
          </h4>
          <p onClick={handleClick} className="cursor-pointer ml-auto">
            <CloseIcon />
          </p>
        </div>
        <div className="w-[80%]  md:w-full h-[300px] lg:h-[400px] overflow-scroll">
          {loading ? "loading..." : <LoanDet data={data} />}
        </div>
        <div className="flex items-center justify-center space-x-6">
          <button
            type="submit"
            className="btn text-[#1C1D21] bg-transparent border-[#1C1D21] border-2"
            onClick={handleClick}
          >
            Cancel
          </button>
          <button type="submit" className="btn">
            Approve
          </button>
        </div>
      </form>
    </ModalCard>
  );
};

export default memo(ProcessLoan);
