import { useState, memo } from "react";
import axios from "axios";
import DateForm from "../shared/DateForm";
import { Toast } from "./Toast";

type InputFile = {
  duration?: string;
  module?: string;
  status?: string;
  type?: string;
  endDate?: string;
  startDate?: string;
  tenor?: string;
};

type Props = {
  inputFile: InputFile;
  setInputFile: (e: any) => void;
};

const BNPLReport = ({ inputFile, setInputFile }: Props) => {
  const handleChange = (e: any) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };
  const [file, setFile] = useState("");
  const [generate, setGenerate] = useState(false);
  const [url, setUrl] = useState("");

  const handleDownload = (e: any) => {
    setGenerate(true);
    e.preventDefault();
    const payload = {
      startDate: inputFile.startDate,
      endDate: inputFile.endDate,
    };
    let data;
    if (typeof window !== "undefined") {
      data = localStorage.getItem("auth");
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_ARY}/Product/GenerateBNPLTransactionReport`,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${data}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: payload,
    })
      .then((res) => {
        setGenerate(false);
        setFile(res.data);
        const blob = new Blob([res.data]);
        const fileDownloadUrl = URL.createObjectURL(blob);
        setUrl(fileDownloadUrl);
      })
      .catch((err: any) => {
        Toast({
          title: `Please fill all fields`,
          error: true,
        });
        setGenerate(false);
        console.log("err", err);
      });
  };
  return (
    <div>
      <DateForm
        name="startDate"
        label="start date"
        handleChange={handleChange}
      />
      <DateForm name="endDate" label="end date" handleChange={handleChange} />
      <div className="flex  justify-center my-5">
        {file ? (
          <a download="buynow-Report.xlsx" href={url} className="btn">
            download now
          </a>
        ) : (
          <button
            disabled={inputFile.startDate === "" && inputFile.endDate === ""}
            className="btn"
            onClick={handleDownload}
          >
            {generate ? "generating..." : "Click to Download"}
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(BNPLReport);
