import { memo } from "react";
import {
  AccountIcon,
  AuthIcon,
  PasswordCheckIcon,
  SettingsIcon,
} from "../assets/icons/ManageUsers";
import { useStateContext } from "../context/context";
import BigCard from "../shared/BigCard";
import { CustomerWalletDetails } from "../typings";
import { DisableWallet, ResetWalletPin, WalletAccess } from "./";

type Props = {
  data: CustomerWalletDetails;
};

const WalletManagement = ({ data }: Props) => {
  const { handleClick, open, title } = useStateContext();

  return (
    <>
      {open && title === "DisableWallet" ? <DisableWallet data={data} /> : null}
      {open && title === "GrantWalletAccess" ? (
        <WalletAccess data={data} />
      ) : null}
      {open && title === "ResetWalletPin" ? (
        <ResetWalletPin data={data} />
      ) : null}
      <BigCard>
        <section className="flex items-center justify-between">
          <h3 className="text-[#1C1D21] font-bold text-xl my-5">
            Manage Wallet
          </h3>
        </section>
        <section className="flex flex-col space-y-5 md:flex-row md:divide-x-4 lg:space-x-4 p-3 cursor-pointer">
          <article className="flex flex-col space-y-8">
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("DisableWallet")}
            >
              <AccountIcon />
              <p className="text-[#4C4D50]">Disable Wallet</p>
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("ViewWalletEntries")}
            >
              <AuthIcon />
              <p className="text-[#4C4D50]">View Wallet Entries</p>
            </div>
          </article>
          <article className="flex flex-col space-y-8 md:pl-4 md:!-mt-2">
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("GrantWalletAccess")}
            >
              <SettingsIcon />
              <p className="text-[#4C4D50]">Grant Wallet Access</p>
            </div>
            <div
              className="flex items-center space-x-3"
              onClick={() => handleClick("ResetWalletPin")}
            >
              <PasswordCheckIcon />
              <p className="text-[#4C4D50]">Reset Wallet Pin </p>
            </div>
          </article>
        </section>
      </BigCard>
    </>
  );
};

export default memo(WalletManagement);
